import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../../configs/axiosClientConfig";
import URLConstant from "src/app/constants/urlConstant";
import SocialQRPreview from '../qrCode/SocialQRPreview';
import DataConstant from "src/app/constants/dataConstant";

const SocialPreviewUrl = () => {

    const { id } = useParams();
    const [key,setKey] = useState(Math.random());
    const [details, setDetails] = useState({
        logo: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: ''
        },
        BackGroundImage: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: ''
        },
        BusinessCardImage: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: ''
        },
        id: '',
        Title: '',
        Description: '',
        YoutubeVideo: '',
        SocialLinks: [],
        AboutUs: '',
        Privacy: '',
        ButtonColor: '#00FFFF',
        TextColor: '#0F0F0F',
        CSSTemplate: "",
        CustomCSS: '',
        ShareThisPage: true,
        code: '',
        TemplateId: 0,
        url: ''
    });
    const fetchData = () => {
        axiosClient
            .get(`${URLConstant.QRCode.GetQRCodeById}?QRCodeId=${id}`)
            .then((response) => {
                if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
                    setSocialData(response.data);
                }
            }).catch((error) => {
                console.log(error);
            });
    };
    const setSocialData = (SocialData) => {
        var tempDetails = details;
        tempDetails.id = SocialData.id,
            tempDetails.Title = SocialData.name;
        tempDetails.Description = SocialData.description;
        if (SocialData.logo && SocialData.logo != '') {
            tempDetails.logo.selectedImageUrl = URLConstant.Images.UserSocialRootPath + "/" + SocialData.id + "/" + SocialData.logo;
            tempDetails.logo.selectedFileName = SocialData.logo;
        }


        if (SocialData.bgImage && SocialData.bgImage != '') {
            tempDetails.BackGroundImage.selectedImageUrl = URLConstant.Images.UserSocialRootPath + "/" + SocialData.id + "/" + SocialData.bgImage;
            tempDetails.BackGroundImage.selectedFileName = SocialData.bgImage;
        }
        if (SocialData.favicon && SocialData.favicon != '') {
            tempDetails.BusinessCardImage.selectedImageUrl = URLConstant.Images.UserSocialRootPath + "/" + SocialData.id + "/" + SocialData.favicon;
            tempDetails.BusinessCardImage.selectedFileName = SocialData.favicon;
            UpdateFaviconIcon(tempDetails.BusinessCardImage.selectedImageUrl);
        }

        tempDetails.YoutubeVideo = SocialData.youtubelink,
            tempDetails.SocialLinks = SocialData.socialLinks ? JSON.parse(SocialData.socialLinks) : {},
            tempDetails.AboutUs = SocialData.aboutUs,
            tempDetails.Privacy = SocialData.privacy,
            tempDetails.ButtonColor = SocialData.buttoncolor,
            tempDetails.TextColor = SocialData.textcolor,
            tempDetails.CSSTemplate = SocialData.templateId,
            tempDetails.CustomCSS = SocialData.customcss,
            tempDetails.ShareThisPage = SocialData.sharepage,
            tempDetails.code = SocialData.code,
            tempDetails.url = SocialData.url,

        setDetails(tempDetails);
        
        setKey(Math.random());
    }

    const UpdateFaviconIcon = (iconLink) => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = iconLink;
    }
    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <>
            <div className="cm-web-preview-wrapper">
                <SocialQRPreview key={key} data={details} isPreview = {false}/>
            </div>
        </>
    );
};

export default SocialPreviewUrl;
