import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import Chip from '@mui/material/Chip';
import DataConstant from 'src/app/constants/dataConstant';

function ModernPricingCard(props) {
  const navigate = useNavigate();
  const { period,quaterId,yearlyId, yearlyTitle,quaterlyTitle, subtitle, yearlyPrice, quaterlyPrice, buttonTitle, isPopular, details, isBtnDisable, isActive } = props;
  return (
    <Paper
      className={clsx(
        'flex-col max-w-sm md:max-w-none p-24 sm:py-48 sm:px-40 relative',
        isPopular && 'ring-2 ring-primary'
      )}
    >
      {isPopular && (
        <div className="absolute inset-x-0 -top-16 flex items-center justify-center">
          <Chip
            label="POPULAR"
            color="primary"
            className="flex items-center h-32 px-32 rounded-full font-medium text-center leading-none"
          />
        </div>
      )}

      <Typography className="text-4xl font-bold tracking-tight leading-tight">
        {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) && quaterlyTitle}
        {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Year) && yearlyTitle}
      </Typography>

      <Typography className="mt-8 text-lg font-medium tracking-tight" color="text.secondary">
        {subtitle}
      </Typography>

      <Typography className='my-16 h-8'>
        {isActive && <Chip className='text-base' label="Current Active" color="success"/>}
      </Typography>

      <Divider className="w-32 h-4 my-40 rounded bg-accent" />

      <div className="flex items-baseline whitespace-nowrap">
        <Typography className="mr-8 text-2xl">USD</Typography>
        <Typography className="text-6xl font-semibold leading-tight tracking-tight">
          {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) && quaterlyPrice}
          {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Year) && yearlyPrice}
        </Typography>
      </div>

      <Typography className="flex flex-col mt-8" color="text.secondary">
        {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) && (
          <>
            <span>{DataConstant.PlanDetail.BilledQuaterly}</span>
            <span>
              <b>{yearlyPrice}</b> {DataConstant.PlanDetail.BilledYearly}
            </span>
          </>
        )}
        {_.toLower(period) === _.toLower(DataConstant.PlanDetail.Year) && (
          <>
            <span>{DataConstant.PlanDetail.BilledYearly}</span>
            <span>
              <b>{quaterlyPrice}</b> {DataConstant.PlanDetail.BilledQuaterly}
            </span>
          </>
        )}
      </Typography>
      <div className='mt-40 cursor-not-allowed'>
      <Button
        className="w-full"
        size="large"
        variant={isPopular ? 'contained' : 'outlined'}
        color={isPopular ? 'primary' : 'inherit'}
        disabled={isBtnDisable}
        onClick={() => navigate(`/Subscription/Checkout/${_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) ? quaterId :  yearlyId }` ,{state : {packageName:_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) ? quaterlyTitle : yearlyTitle , packagePrice:_.toLower(period) === _.toLower(DataConstant.PlanDetail.Qauter) ? quaterlyPrice : yearlyPrice }} )}     
      >
        {buttonTitle}
      </Button>
      </div>
      {details}
    </Paper>
  );
}

ModernPricingCard.defaultProps = {
  period: '',
  title: '',
  subtitle: '',
  yearlyPrice: '',
  quaterlyPrice: '',
  buttonTitle: '',
  isPopular: false,
  details: '',
};

export default ModernPricingCard;
