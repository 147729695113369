/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import history from "@history";
import _ from "@lodash";
import { setInitialSettings } from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import settingsConfig from "app/configs/settingsConfig";
import jwtService from "../auth/services/jwtService";

export const setUser = createAsyncThunk(
  "user/setUser",
  async (user, { dispatch, getState }) => {
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    if (user.loginRedirectUrl) {
      settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
    }
    return user;
  }
);

export const updateUserSettings = createAsyncThunk(
  "user/updateSettings",
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  "user/updateShortucts",
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: "/",
  });

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: "User data saved with api" }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  role: [], // guest
  data: {
    displayName: "",
    photoURL: "",
    email: "",
    shortcuts: ["apps.calendar", "apps.mailbox", "apps.contacts", "apps.tasks"],
    accessToken: "",
    isAuthenticated: false,
    userid: "",
    UserRole: "",
    CompanyProfile: "",
    CompanyLogo: "",
  },
  InvoiceInfo: {
    InvoiceType: "",
    Status: "",
    ExpiredDate: "",
    CreatedDate: "",
    PackageStatus: "",
    RemainingDays: null,
  },
  profilePic: {
    UserProfileImage:""
  },
  companyProfileData:{
    CompanyProfile:""
  }
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    login: (state, { payload }) => {
      state.data.accessToken = payload.accessToken;
      //  state.data.name = payload.name;
      //  state.data.whitelabel = payload.whitelabel;  
      state.data.CompanyLogo = payload.CompanyLogo;
      state.data.displayName = payload.displayName;
      state.data.email = payload.userName;
      state.data.isAuthenticated = payload.isAuthenticated;
      state.data.userid = payload.userid;
      state.data.UserRole = payload.UserRole;
    },
    profilePic: (state, { payload }) => {
      state.profilePic.UserProfileImage = payload.UserProfileImage;
    },
    companyProfileData: (state, { payload }) => {
      state.companyProfileData.CompanyProfile = payload.CompanyProfile;
    },
    InvoicePackageInfo: (state, { payload }) => {
      state.InvoiceInfo.InvoiceType = payload.InvoiceType;
      state.InvoiceInfo.Status = payload.Status;
      state.InvoiceInfo.ExpiredDate = payload.ExpiredDate;
      state.InvoiceInfo.CreatedDate = payload.CreatedDate;
      state.InvoiceInfo.PackageStatus = payload.PackageStatus;
      state.InvoiceInfo.RemainingDays = payload.RemainingDays;
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const { userLoggedOut, login, companyProfileData, InvoicePackageInfo, profilePic } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
