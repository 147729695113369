import GridComponent from "src/app/components/gridComponent";
import { DeleteOutlined } from "@ant-design/icons";
import _, { isEqual } from "lodash";
import { Tooltip, Button, Modal, Tag, notification } from "antd";
import PageHeader from "../../components/pageHeader";
import useDebounce from "src/@fuse/hooks/useDebounce";
import dataConstant from "src/app/constants/dataConstant";
import messageConstant from "src/app/constants/messageConstant";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import axiosClient from "../../configs/axiosClientConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import DataConstant from "src/app/constants/dataConstant";
import { Switch } from "@mui/material";

const UserPaymentProfile = () => {
  const [searchText, setSearchText] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [orderList, setOrderList] = useState({
    count: 0,
    data: [],
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const debouncedSearch = useDebounce(searchText, 1000);

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  const onDefaultChange = (e, record) => {

    const defaultPaymentProfileRequest = {
      Id: record.id,
      UserPaymentProfileId: record.userPaymentProfileID,
      IsDefault: e.target.checked
    }
    axiosClient
      .post(
        URLConstant.UserPaymentProfile.SetDefaultPaymentProfile,
        defaultPaymentProfileRequest
      )
      .then((response) => {
        if (response && _.toLower(response?.status) === _.toLower(dataConstant.API_STATUS.SUCCESS)) {
          notification.success({
            message: DataConstant.Save,
            description: response?.message,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
        }
        else if (_.toLower(response?.status) === _.toLower(dataConstant.API_STATUS.WARNING)) {
          notification.warning({
            message: DataConstant.Warning,
            description: response?.message,
          });
        }
        else {
          notification.error({
            message: DataConstant.error,
            description: response?.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "code",
      sorter: false,
      width: 50,
      dataType: "StringContains",
      render: (record) => (
        <span>
          <Tooltip placement="bottom" title={dataConstant.Delete}>
            <Button
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => confirmDeleteUser([record.id])}
            />
          </Tooltip>
        </span>
      ),
    },
    {
      title: "Default",
      dataIndex: "",
      key: "isDefault",
      sorter: false,
      width: '10%',
      //dataType: "",
      render: (record) =>
        <Switch
          checked={record?.isDefault}
          onChange={(e) => onDefaultChange(e, record)}
          style={record?.isDefault ? { color: 'rgb(6 85 175)' } : {}}
          disabled={record?.isDefault}
        />
    },
    {
      title: "Card last four digits",
      dataIndex: "",
      key: "cardLastFourDigits",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render: (record) =>
        record.cardLastFourDigits && (
          <span>{record.cardLastFourDigits.slice(-4)}</span>
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render: (status, _) => (
        <>
          <Tag style={{ color: "green", backgroundColor: "white" }}>
            {status}
          </Tag>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  useEffect(() => {
    if (debouncedSearch != undefined) {
      setTableRequestParam({
        ...tableRequestParam,
        pageNumber: 1,
        SearchText: debouncedSearch,
      });
      setPageNumber(1);
    }
  }, [debouncedSearch]);

  const Refresh = () => {
    setSearchText("");
  };

  const confirmDeleteUser = (Id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        deleteUser(Id);
      },
    });
  };

  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    if (requestObject && !isEqual(requestObject, tableRequestParam)) {
      setTableRequestParam(requestObject);
      if (
        isEqual(
          requestObject,
          JSON.parse(JSON.stringify(dataConstant.tableRequest))
        )
      ) {
        setRefreshTable(Math.random());
      }
      setSearchText(requestObject.SearchText);
    } else if (
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(dataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
    } else {
      setRefreshTable(Math.random());
      fetchData(tableRequestParam);
    }
  };

  const fetchData = async (requestObject) => {
    axiosClient
      .post(
        URLConstant.UserPaymentProfile.GetUserPaymentProfileList,
        requestObject
      )
      .then((response) => {
        setOrderList({
          data: response.data.data.list,
          count: response.data.data.totalCount,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const deleteUser = (Id) => {
    const deletePaymentRequest = {
      Ids: Id,
      IsProduction: false,
    };
    axiosClient
      .post(URLConstant.UserPaymentProfile.DeleteUserPaymentProfile, deletePaymentRequest)
      .then((response) => {
        if (response) {
          notification.success({
            message: dataConstant.Delete,
            description: messageConstant.delete,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
        } else if (response.status === dataConstant.API_STATUS.FAILED) {
          notification.error({
            message: dataConstant.User,
            description: messageConstant.error,
          });
        }
      });
  };

  const createUserPaymentProfile = (data) => {
    const HostedCardDetails = {
      cardNumber: data.encryptedCardData.cardNumber,
      expDate: data.encryptedCardData.expDate,
      token: data.opaqueData.dataValue,
    };
    const PaymentDetails = {
      Token: HostedCardDetails.token,
      CardNumber: HostedCardDetails.cardNumber.replace(/[^\d.]/g, ""),
      FirstName: loginDetail?.profile?.name,
      LastName: "---",
      IsDefault: false,
    };
    const paymentRequest = {
      UserProfileId: "",
      PaymentDetails: PaymentDetails,
      IsProduction: false,
      UserId: loginDetail?.profile?.userid,
    };

    axiosClient
      .post(
        URLConstant.UserPaymentProfile.CreateUserPaymentProfile,
        paymentRequest
      )
      .then((response) => {
        if (response && response.data.status === dataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: messageConstant.Save,
            description: messageConstant.CardAdded,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
        }
        else if (response.data.data.message == "server.validation.exists") {
          notification.error({
            message: messageConstant.error,
            description: messageConstant.AlreadyExists,
          });
        }
        else {
          notification.error({
            message: response.data.data.message,
            description: messageConstant.TryAgain,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (IsLoading) {
    return <FuseLoading />;
  }

  const authData = {
    apiLoginID: process.env.REACT_APP_PAYMENT_APILOGINID,
    clientKey: process.env.REACT_APP_PAYMENT_CLIENTKEY,
  };
  const billingAddress = {
    show: false,
    required: false,
  }

  const goToAddCard = () => {
    //history.push(`${returnToken('paymentpage')}`);
    window.location.href = "paymentpage";
  };
  return (
    <>
      <div className="px-16">
        <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8 py-16 md:py-32 px-12 md:px-24 cm-userpayment-head">
          <PageHeader
            title="Payment methods"
            showSearch
            description="UserPaymentProfile"
            searchText={setSearchText}
            showAddButton
            onClick={goToAddCard}
          />
          {/* <HostedForm
            environment={process.env.REACT_APP_PAYMENT_TESTMODE}
            authData={authData}
            onSubmit={createUserPaymentProfile}
            billingAddressOptions={billingAddress}
            formButtonText="Submit"
            buttonText={<><FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon> Add</>}
            formHeaderText="Card Information"
            buttonClassName="cm-hosted-btn" /> */}
        </div>

        <GridComponent
          key={refreshTable}
          rowKey="id"
          columns={columns}
          dataSource={orderList.data}
          getGridData={getRequestObj}
          tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
          totalCount={orderList.count}
          scroll={{ x: "max-content", y: "55vh" }}
          refresh={Refresh}
          SearchText={searchText}
          pageNumber={pageNumber}
          deleteCallbackMethod={confirmDeleteUser}
          enableRowSelection
        />
      </div>
    </>
  );
};

export default UserPaymentProfile;
