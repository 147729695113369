import _, { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import GridComponent from "src/app/components/gridComponent";
import useDebounce from "src/@fuse/hooks/useDebounce";
import { Modal, notification } from "antd";
import { Tooltip, IconButton, Button } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "../../components/pageHeader";
import dataConstant from "src/app/constants/dataConstant";
import messageConstant from "src/app/constants/messageConstant";
import axiosClient from "../../configs/axiosClientConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";

const User = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [userList, setUserList] = useState({
    count: 0,
    data: [],
  });
  const [searchText, setSearchText] = useState("");
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "code",
      sorter: false,
      width: 100,
      dataType: "StringContains",
      render: (record) => (
        <span>
          <Tooltip title={dataConstant.Edit}>
            <IconButton onClick={() => EditUser(record.userId, record.subscriptionId)}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={dataConstant.Delete}>
            <IconButton onClick={() => confirmDeleteUser([record.userId])}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    // {
    //   title: "Role",
    //   dataIndex: "userRole",
    //   key: "userRole",
    //   sorter: true,
    //   width: 80,
    //   dataType: "StringContains",
    // },
    {
      title: "Full name",
      dataIndex: "fullName",
      key: "fullName",
      sorter: true,
      width: 100,
      dataType: "StringContains",
    },
    // {
    //   title: "User name",
    //   dataIndex: "userName",
    //   key: "userName",
    //   sorter: true,
    //   width: 100,
    //   dataType: "StringContains",
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: true,
      width: 100,
      dataType: "StringContains",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      sorter: false,
      width: 60,
      dataType: "StringContains",
      render: (record) => (
        <span>
          <Button
            variant="contained"
            color="primary"
            className="btn-small"
            onClick={() => inviteUser(record.userId, record.subscriptionId)}
            disabled={record.invitationAccepted}
          >
            Invite
          </Button>
        </span>
      ),
    },
  ];
  const debouncedSearch = useDebounce(searchText, 1000);

  useEffect(() => {
    fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  useEffect(() => {
    if (debouncedSearch != undefined) {
      setTableRequestParam({
        ...tableRequestParam,
        pageNumber: 1,
        SearchText: debouncedSearch,
      });
      setPageNumber(1);
    }
  }, [debouncedSearch]);

  const redirectToAddCountry = () => {
    navigate(`/manage`);
  };

  const Refresh = () => {
    setSearchText("");
  };

  const EditUser = (id, subscriptionId) => {
    navigate(`/manage/${id}/${subscriptionId}`);
  };

  const confirmDeleteUser = (Id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        deleteUser(Id);
      },
    });
  };

  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    if (requestObject && !isEqual(requestObject, tableRequestParam)) {
      setTableRequestParam(requestObject);
      if (
        isEqual(
          requestObject,
          JSON.parse(JSON.stringify(dataConstant.tableRequest))
        )
      ) {
        setRefreshTable(Math.random());
      }
      if (!requestObject.SearchText) {
      }
      setSearchText(requestObject.SearchText);
    } else if (
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(dataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
      if (!requestObject.SearchText) {
      }
    } else {
      setRefreshTable(Math.random());
      fetchData(tableRequestParam);
    }
  };

  const fetchData = (requestObject) => {
    axiosClient
      .post(URLConstant.Employee.GetUsersList, requestObject)
      .then((response) => {
        setUserList({
          data: response.data.list,
          count: response.data.totalCount,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteUser = (Id) => {
    axiosClient.post(URLConstant.Employee.DeleteUser, Id).then((response) => {
      if (response) {
        notification.success({
          message: dataConstant.Delete,
          description: messageConstant.delete,
        });
        getRequestObj(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
      } else if (response.status === dataConstant.API_STATUS.FAILED) {
        notification.error({
          message: dataConstant.error,
          description: messageConstant.delete,
        });
      }
    });
  };

  const inviteUser = (userId, subscriptionId) => {
    setIsLoading(true);
    axiosClient
      .post(`${URLConstant.Employee.SendInvitation}/${userId}/${subscriptionId}`)
      .then((response) => {
        setIsLoading(false);
        if(response.status === dataConstant.API_STATUS.SUCCESS){
          notification.success({
            message: 'Invitation',
            description: response.message,
          });
        }
        else{
          notification.error({
            message: dataConstant.error,
            description: response.message,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  if (IsLoading) {
    return <FuseLoading />;
  }
  return (
    <FusePageSimple
      header={
        <PageHeader
          title="User"
          showSearch
          description="User"
          searchText={setSearchText}
          showAddButton
          onClick={redirectToAddCountry}
        />
      }
      content={
        <GridComponent
          key={refreshTable}
          rowKey="userId"
          columns={columns}
          dataSource={userList.data}
          getGridData={getRequestObj}
          tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
          totalCount={userList.count}
          scroll={{ x: "max-content", y: "40vh" }}
          refresh={Refresh}
          SearchText={searchText}
          pageNumber={pageNumber}
          deleteCallbackMethod={confirmDeleteUser}
          enableRowSelection
        />
      }
    />
  );
};

export default User;
