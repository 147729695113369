import { useEffect } from "react";
import { completeLogin } from "src/app/auth/oidcConfig";
import { useNavigate } from "react-router-dom";
import store from "app/store/index";
import { login } from "src/app/store/userSlice";
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import axiosClient from "app/configs/axiosClientConfig";
import URLConstant from "src/app/constants/urlConstant";

function LoginResponsePage() {
  const navigate = useNavigate();

  useEffect(() => {
    completeLogin()
      .then(
        (response) => {
          if (response) {
            const userLoginRole = localStorage.getItem("userLoginRole") != null? JSON.parse(localStorage.getItem("userLoginRole")) : null;
            if(userLoginRole){
              const role = userLoginRole.userRole;
              const subscription = userLoginRole.userSubscription;
              const company = userLoginRole.company;
              if(response.profile?.userid === userLoginRole?.userId)
              {
                if(response.profile?.UserRole && role) response.profile.UserRole = role;
                if(response.profile?.subscription && subscription)
                { 
                  response.profile.subscription = subscription;
                  response.profile.whitelabel = company;
                }
              }
            }
            else{
              axiosClient
                .get(`${URLConstant.Employee.GetUserLastLogin}/${response.profile?.userid}`, true)
                .then((response) => {
                  if (response && response.data && response.data.redirectUrl && response.data.objLogin) {
                    const url = response.data.redirectUrl;
                    const userId = response.data.objLogin.userId;
                    const userRole = response.data.objLogin.userRole;
                    const subscription = response.data.objLogin.subscriptionId;
                    const company = response.data.whitelabel;
                    window.location.href = `${url}switchresponse/${userRole}/${userId}/${subscription}/${company}`
                    window.location.reload;
                  }
                });
            }
            
            localStorage.setItem("loginDetails", JSON.stringify(response));
            store.dispatch(
              login({
                accessToken: response?.access_token,
                displayName: response?.profile?.name,
                isAuthenticated: true,
                email: response?.profile?.email,
                whitelabel: response?.profile?.whitelabel,
                userid: response?.profile?.userid,
                UserRole: response?.profile?.UserRole,
                CompanyLogo:response?.profile?.CompanyLogo,
              })
            );
            
            if(localStorage.getItem("pathname") == '/' || localStorage.getItem("pathname") == null)
            {
              navigate('/dashboard')
            }
            else{
            navigate(localStorage.getItem("pathname"));
            }

          } else {
            console.log("Failed to login with token.");
          }
        },
        (err) => {
          console.log("Failed to login with token.");
          console.log(err);
        }
      )
      .catch((err) => {
        console.log("Failed to login with token.");
        console.log(err);
      });
  });

  return (
    <FuseSplashScreen />
  )
}

export default LoginResponsePage;
