import {  Grid } from '@mui/material';
import { Tooltip } from 'antd';
import React from 'react';
import { useEffect } from 'react';

const QrChartDetail = (props) => {

  const data = props.data;

  useEffect(() => {

  }, [])

  return (
    <div className='px-4'>
        <Grid container justifyContent="space-around" alignItems="center" className='my-12'>
            {data?.map((item, index) => {
                return (
                    <Grid key={index} item xs={12} md={1} className='p-8 m-2 rounded-xl' style={{backgroundColor:"#f0f0f0", minHeight:"100px", minWidth:"150px"}}>
                        <p className='font-semibold text-center text-sm pb-2'>{item.label}</p>
                        {item?.detail?.map((val, i) => {
                            return (
                                <div key={i} className='flex justify-between'>
                                    <Tooltip title={val?.name}>
                                    <span className='font-semibold text-xs'>{val?.name?.length > 16 ? val?.name?.slice(0, 16) + '...' : val?.name}</span>
                                    </Tooltip>
                                    <span className='font-semibold text-xs'>{val?.value}</span>
                                </div>
                            )
                        })}
                    </Grid>
                )
            })}
        </Grid>
    </div>
  )
};

export default QrChartDetail;
