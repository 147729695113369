import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import routes from "app/configs/routesConfig";
import axiosClient from "app/configs/axiosClientConfig";
import MessageConstant from "src/app/constants/messageConstant";
import URLConstant from "src/app/constants/urlConstant";
import DataConstant from "src/app/constants/dataConstant";
import {
  Button,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";

const WhatsappPage = (props) => {
  const { data } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [urlState, setURLState] = useState("Static");
  const [shorthandUrl, setShorthandUrl] = useState("");
  const user = useSelector(selectUser);
  var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (data) {
      setURLState(data.subTypes ?? "Static");
      setShorthandUrl(data.code);
      setValue("name", data.name);
      setValue("whatsapp", data.mobileNo);
      setValue("message", data.message);
      setValue("code", data.code);
      setValue("countryCode",data.countryCode)
  
    } else {
      GetShortHandUrl();
    }
  }, [data]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setURLState(value);
  };

  const redirectToQRCode = () => {
    navigate(`/qrcodes`);
  };

  const GetShortHandUrl = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetShortHandUrl}`)
      .then((response) => {
        if (response) {
          setShorthandUrl(response.data.qrCode);
          setValue("code", response.data.qrCode);
        }
      });
  };

  const isMatchWithRoute = (value) => {
    if (!value) return false;
    const allPaths = routes.map((route) => route.path);

    const isMatch = allPaths.some((route) => {
      const segments = route.split("/");
      const initial = segments[0] || segments[1];
      if (
        value?.toLowerCase() === initial?.toLowerCase() ||
        value?.toLowerCase() === segments[1]?.toLowerCase()
      ) {
        return true;
      }
    });
    return isMatch;
  };

  const onFinish = (whatsappDetails) => {
    const code = whatsappDetails?.code;

    if (isMatchWithRoute(code)) {
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.CodeRestricted,
      });
      return;
    }
    const modelToSubmit = {
      id: id ? id : "",
      Name: whatsappDetails?.name.trim(),
      Type: DataConstant.qrcodeType.whatsapp,
      SubTypes: urlState,
      Url: "",
      CountryCode: whatsappDetails?.countryCode,
      code: urlState === "Dynamic" ? whatsappDetails?.code : null,
      SubscriptionCurrentPaymentType: subscriptionPlanType,
      MobileNo: whatsappDetails?.whatsapp,
      Message: whatsappDetails?.message ? whatsappDetails.message : null,
    };
    const requestURL = id
      ? URLConstant.QRCode.UpdateUrlQRCode
      : URLConstant.QRCode.AddQRCodes;
    axiosClient.post(requestURL, modelToSubmit).then((response) => {
      if (
        response &&
        _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)
      ) {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        redirectToQRCode();
      } else if (
        response &&
        _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)
      ) {
        notification.warning({
          message: DataConstant.Warning,
          description: response?.message,
        });
      } else {
        notification.error({
          message: DataConstant.error,
          description: response?.message,
        });
      }
    });
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onFinish)}>
        <div className="px-16">
          <FormLabel className="mt-16 ">Name</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="name"
            name="name"
            className="w-full my-8"
            placeholder="QRcode name"
            {...register("name", {
              required: "Please enter name",
              maxLength: {
                value: 60,
                message: MessageConstant.EnterMaximum60Char,
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <FormLabel className="mt-16">
            WhatsApp Number (provide number with country code)
          </FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <div className="flex my-8">
            <div className="col-md-1 mr-4">
              <TextField
                id="country-code"
                name="countryCode"
                type="tel"
                className="w-full "
                placeholder="eg. 44"
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
                  if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                {...register("countryCode", {
                  required: MessageConstant.CountryCodeRequired,
                  pattern: {
                    value: /^\d+$/,
                    message: MessageConstant.EnterValidCountryCode,
                  },
                  minLength: {
                    value: 1,
                    message: MessageConstant.EnterValidCountryCode,
                  },
                  maxLength: {
                    value: 5,
                    message: MessageConstant.EnterValidCountryCode,
                  },
                })}
                error={!!errors.countryCode}
                helperText={
                  errors.countryCode
                    ? errors.countryCode.message ||
                      MessageConstant.EnterValidCountryCode
                    : ""
                }
              />
            </div>
            <div className="col-md-11 w-full">
              <TextField
                id="whatsapp"
                name="whatsapp"
                type="tel"
                className="w-full"
                placeholder="1234567890"
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
                  if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                {...register("whatsapp", {
                  required: MessageConstant.PhonenumberRequired,
                  pattern: {
                    value: /^(?!(\d)\1+$)[\d]+$/,
                    message: MessageConstant.EnterValidPhonenumber,
                  },
                  minLength: {
                    value: 7,
                    message: MessageConstant.EnterValidPhonenumber,
                  },
                  maxLength: {
                    value: 15,
                    message: MessageConstant.EnterValidPhonenumber,
                  },
                })}
                error={!!errors.whatsapp}
                helperText={
                  errors.whatsapp
                    ? errors.whatsapp.message ||
                      MessageConstant.EnterValidPhonenumber
                    : ""
                }
              />
            </div>
          </div>
          <FormLabel className="mt-16 ">Message (optional)</FormLabel>
          <TextField
            id="message"
            name="message"
            className="w-full my-8"
            placeholder="Message"
            multiline
            rows={2}
            maxRows={4}
            {...register("message")}
          />
          {urlState === "Dynamic" && (
            <>
              <FormLabel className="mt-16 ">Shorthand URL</FormLabel>
              <span className="text-[#ff0000] text-lg-start"> *</span>
              <div className="flex">
                <TextField
                  value={URLConstant.OriginUrl}
                  maxLength="50"
                  className="w-1/4 my-8"
                  disabled
                />
                <TextField
                  id="shorthandUrl"
                  name="shorthandUrl"
                  maxLength="50"
                  className="w-3/4 my-8"
                  placeholder="URL code"
                  {...register("code", {
                    required: MessageConstant.EnterCode,
                    pattern: {
                      value: DataConstant.urlCodeRegex,
                      message: DataConstant.urlCodeMessage,
                    },
                  })}
                  error={!!errors.code}
                  helperText={errors.code?.message}
                  disabled={data != null && data != ""}
                />
              </div>
              <div>
                <label className="url-preview">
                  <span>Preview:</span>
                  <br /> {URLConstant.OriginUrl}/{watch("code")}
                </label>
              </div>
            </>
          )}
          <FormLabel className="mt-16 ">QR code</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <RadioGroup
            key={urlState}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={urlState ?? "Static"}
            name="subType"
            onChange={handleInputChange}
          >
            <FormControlLabel
              name="subType"
              value="Dynamic"
              control={<Radio />}
              label="Dynamic"
              disabled={data != null && data != ""}
            />
            <FormControlLabel
              name="subType"
              value="Static"
              control={<Radio />}
              label="Static"
              disabled={data != null && data != ""}
            />
          </RadioGroup>
          <Typography
            variant="body2"
            component="p"
            className="inline-block align-bottom py-10"
          >
            Note : Static QR code will not track your QR code scan.
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="my-8 flex"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default WhatsappPage;
