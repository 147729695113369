import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import AppContext from 'app/AppContext';
import {
  generateSettings,
  selectFuseCurrentSettings,
  selectFuseDefaultSettings,
  setSettings,
} from 'app/store/fuse/settingsSlice';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import { alpha } from '@mui/material/styles';
import { selectUser } from 'app/store/userSlice';
import { setNavigation } from 'app/store/fuse/navigationSlice';
import DataConstant from 'src/app/constants/dataConstant';
import moment from 'moment';
import FuseLoading from '../FuseLoading';


const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        backgroundColor: `${theme.palette.background.default}!important`,
        color: `${theme.palette.text.primary}!important`,
        '--black': theme.palette.common.black,
        '--white': theme.palette.common.white,
        '--primary-lighter': theme.palette.primary.lighter,
        '--primary-light': theme.palette.primary.light,
        '--primary-main': theme.palette.primary.main,
        '--primary-dark': theme.palette.primary.dark,
        '--primary-contrastText': theme.palette.primary.contrastText,
        '--secondary-light': theme.palette.secondary.light,
        '--secondary-main': theme.palette.secondary.main,
        '--secondary-dark': theme.palette.secondary.dark,
        '--secondary-contrastText': theme.palette.secondary.contrastText,
        '--error-light': theme.palette.error.light,
        '--error-main': theme.palette.error.main,
        '--error-dark': theme.palette.error.dark,
        '--background-paper': theme.palette.background.paper,
        '--background-default': theme.palette.background.default,
        '--divider': theme.palette.divider,
        '--primary-color': theme.palette.text.primary,
        '--secondary-color': theme.palette.text.secondary,
        '--disabled-color': theme.palette.text.disabled,
        '--status-danger': theme.status.danger,
      },
      body: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      /*  'code:not([class*="language-"])': {
        color: theme.palette.secondary.dark,
        backgroundColor:
          theme.palette.mode === 'light' ? 'rgba(255, 255, 255, .9)' : 'rgba(0, 0, 0, .9)',
        padding: '2px 3px',
        borderRadius: 2,
        lineHeight: 1.7,
      }, */
      'table.simple tbody tr th': {
        borderColor: theme.palette.divider,
      },
      'table.simple thead tr th': {
        borderColor: theme.palette.divider,
      },
      'a:not([role=button]):not(.MuiButtonBase-root)': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        '&:hover': {},
      },
      'a.link, a:not([role=button])[target=_blank]': {
        background: alpha(theme.palette.secondary.main, 0.2),
        color: 'inherit',
        borderBottom: `1px solid ${theme.palette.divider}`,
        textDecoration: 'none',
        '&:hover': {
          background: alpha(theme.palette.secondary.main, 0.3),
          textDecoration: 'none',
        },
      },
      '[class^="border"]': {
        borderColor: theme.palette.divider,
      },
      '[class*="border"]': {
        borderColor: theme.palette.divider,
      },
      '[class*="divide-"] > :not([hidden]) ~ :not([hidden])': {
        borderColor: theme.palette.divider,
      },
      hr: {
        borderColor: theme.palette.divider,
      },

      '::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 0 20px ${
          theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
          }`,
      },
      '::-webkit-scrollbar-thumb:active': {
        boxShadow: `inset 0 0 0 20px ${
          theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
          }`,
      },
    })}
  />
);

function FuseLayout(props) {
  const [IsLoading, setIsLoading] = useState(true);
  const { layouts } = props;
  const dispatch = useDispatch();

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  const userDetails = useSelector(selectUser);

  var packageStartDate = userDetails?.InvoiceInfo?.ExpiredDate;
  const isPackageExpired = () => {
    if (!packageStartDate) {
      return true;
    }
    return userDetails?.InvoiceInfo?.PackageStatus === DataConstant.Expired;
    // const currentDate = new Date();
    // return moment(currentDate).format(DataConstant.dateFormats.YYYYMMDD) > moment(new Date(packageStartDate)).format(DataConstant.dateFormats.YYYYMMDD);
  };
  const packageExpired = isPackageExpired();

  const adminNavigationConfig = [
    {
      id: "Dashboard-component",
      title: "Dashboard",
      translate: "Dashboard",
      type: "item",
      icon: "heroicons-outline:home",
      url: "dashboard",

    },
    {
      id: "UpgradePackage-component",
      title: "Upgrade package",
      translate: "UpgradePackage",
      type: "item",
      icon: "heroicons-outline:thumb-up",
      url: "UpgradePackage",
    },
    {
      id: "User-component",
      title: "User",
      translate: "User",
      type: "item",
      icon: "heroicons-outline:users",
      url: "user",
      disabled: packageExpired === true ? true : false,
    },
    {
      id: "QR-component",
      title: "QR code",
      translate: "QRCode",
      type: "item",
      icon: "heroicons-outline:qrcode",
      url: "qrcodes",
      disabled: packageExpired === true ? true : false,
    },
    {
      id: "Subscription-component",
      title: "Subscription",
      translate: "Subscription",
      type: "collapse",
      icon: "heroicons-outline:currency-dollar",
      //disabled: packageExpired === true ? true : false,


      children: [
        // {
        //   id: "Orders-component",
        //   title: "Orders",
        //   type: "item",
        //   url: "Subscription/PaymentSubscription",
        //   end: true,
        //   disabled: packageExpired === true ? true : false,
        // },
        {
          id: "Invoices-component",
          title: "Invoices",
          type: "item",
          url: "Subscription/Invoices",
          end: true,
          //disabled: packageExpired === true ? true : false,
        },
        {
          id: "UserPaymentProfile-component",
          title: "Payment methods",
          type: "item",
          url: "Subscription/UserPaymentProfile",
          end: true,
          //disabled: packageExpired === true ? true : false,
        },
      ],
    },
    {
      id: "Setting-component1",
      title: "Settings",
      translate: "Settings",
      type: "item",
      icon: "heroicons-outline:cog",
      url: "companyProfile",
      hidden: true,
    },
  ];


  const userNavigationConfigs = [
    {
      id: "Dashboard-component",
      title: "Dashboard",
      translate: "Dashboard",
      type: "item",
      icon: "heroicons-outline:home",
      url: "dashboard",
    },
    {
      id: "QR-component",
      title: "QR code",
      translate: "QRCode",
      type: "item",
      icon: "heroicons-outline:qrcode",
      url: "qrcodes",
      disabled: packageExpired === false ? false : true,
    },
  ];

  useEffect(() => {
  if (loginDetail?.profile?.UserRole === "Admin") {
    dispatch(setNavigation(adminNavigationConfig));
    setIsLoading(false);
  }
  else {
    dispatch(setNavigation(userNavigationConfigs));
    setIsLoading(false);
  }
  },[loginDetail])

  const settings = useSelector(selectFuseCurrentSettings);
  const defaultSettings = useSelector(selectFuseDefaultSettings);

  const appContext = useContext(AppContext);
  const { routes } = appContext;

  const location = useLocation();
  const { pathname } = location;

  const isMatchWithRoute = (value) => {
    if(!value) return false;
    value = value.startsWith('/') ? value.substring(1) : value;
    const allPaths = routes.map(route => route.path);
    const isMatch = allPaths.some(path => {
      if(value.toLowerCase() === path.toLowerCase()){
        return true;
      }
    });
    return isMatch;
  }

  if(pathname != '/implicit/callback' && pathname != '/auth/login' && isMatchWithRoute(pathname)){
  localStorage.setItem('pathname',pathname);
  }
  const matchedRoutes = matchRoutes(routes, pathname);
  const matched = matchedRoutes ? matchedRoutes[0] : false;

  const newSettings = useRef(null);

  const shouldAwaitRender = useCallback(() => {
    let _newSettings;
    /**
     * On Path changed
     */
    // if (prevPathname !== pathname) {
    if (matched && matched.route.settings) {
      /**
       * if matched route has settings
       */

      const routeSettings = matched.route.settings;

      _newSettings = generateSettings(defaultSettings, routeSettings);
    } else if (!_.isEqual(newSettings.current, defaultSettings)) {
      /**
       * Reset to default settings on the new path
       */
      _newSettings = _.merge({}, defaultSettings);
    } else {
      _newSettings = newSettings.current;
    }

    if (!_.isEqual(newSettings.current, _newSettings)) {
      newSettings.current = _newSettings;
    }
  }, [defaultSettings, matched]);

  shouldAwaitRender();

  useDeepCompareEffect(() => {
    if (!_.isEqual(newSettings.current, settings)) {
      dispatch(setSettings(newSettings.current));
    }
  }, [dispatch, newSettings.current, settings]);

  // console.warn('::FuseLayout:: rendered');

  const Layout = useMemo(() => layouts[settings.layout.style], [layouts, settings.layout.style]);

  if (IsLoading) {
    return <FuseLoading />;
  }

  return _.isEqual(newSettings.current, settings) ? (
    <>
      {inputGlobalStyles}
      <Layout {...props} />
    </>
  ) : null;
}

export default memo(FuseLayout);
