import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { notification } from "antd";
import axiosClient from "app/configs/axiosClientConfig";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DataConstant from "src/app/constants/dataConstant";
import URLConstant from "src/app/constants/urlConstant";

const TagPopup = (props) => {
  const {
    openTagModal,
    handleTagPopupCancel,
    qrCodeId,
    fetchData,
    tableRequestParam
  } = props;

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isRequiredValidate, setIsRequiredValidate] = useState(true);

  useEffect(() => {
    if (tags.length == 0) {
      setIsRequiredValidate(true);
    } else {
      setIsRequiredValidate(false)
    }
  }, [tags])

  useEffect(() => {
    if (qrCodeId != null & qrCodeId != '' && openTagModal) {
      setValue('txtTag', '');
      setInputValue("");
      setTags([]);
      const model = {
        Id: qrCodeId,
      };
      axiosClient
        .post(URLConstant.QRCode.GetTagsByIdAsync, model)
        .then((response) => {
          if (response && _.toLower(response.status) == "success" && response.data?.tags != null && response.data?.tags != '') {
            const valuesArray = response.data.tags.split(',');
            setTags([]);
            valuesArray?.map((value, index) => {
              if(value?.trim()!= ""){
              setTags((prevValue) => [...prevValue, value])
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [qrCodeId, openTagModal])

  const handleModalClose = () => {
    clearErrors('txtTag');
    handleTagPopupCancel();
  };

  const handleTagChange = (event, newTags) => {
    setTags(newTags);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag !== "" && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    const newTags = tags.filter((tag) => tag !== tagToDelete);
    setTags(newTags);
  };

  const handleSaveTag = (data) => {
    const model = {
      Id: qrCodeId,
      Tags: tags?.filter(x => x.trim() != "").join(),
    };
    if (data.txtTag !== "" && data.txtTag.trim() != "" && !tags.includes(data.txtTag)) {
      if(model.Tags != null && model.Tags != ""){
        model.Tags = model.Tags + ',' + data.txtTag;
      }
      else{
        model.Tags = data.txtTag;
      }
    }
    axiosClient
      .post(URLConstant.QRCode.AddUpdateTag, model)
      .then((response) => {
        fetchData(tableRequestParam);
        notification.success({
          message: DataConstant.Save,
          description: response.message,
        });
        handleModalClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            open={openTagModal}
            onClose={handleModalClose}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle className="flex items-center justify-between p-24 mb-24 border-solid border-b-2">
              <Typography variant="h6"> Tags </Typography>
              <IconButton aria-label="close" onClick={handleModalClose}>
                <CancelOutlinedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="p-24">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(handleSaveTag)}
              >
                <FormLabel>Name</FormLabel>
                <Autocomplete
                  multiple
                  freeSolo
                  options={tags}
                  value={tags}
                  inputValue={inputValue}
                  onChange={handleTagChange}
                  onInputChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((tag, index) => ( tag.trim() != "" &&
                        <Chip
                          key={index}
                          label={tag}
                          onDelete={() => handleTagDelete(tag)}
                          {...getTagProps({ index })}
                        />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="txtTag"
                      name="txtTag"
                      variant="outlined"
                      placeholder="Add tag..."
                      className="w-full"
                      {...register("txtTag")}
                      error={!!errors.txtTag}
                      helperText={errors.txtTag?.message}
                      fullWidth
                    />
                  )}
                />
                <DialogActions className="p-24 border-solid border-t-2">
                  <Button variant="outlined" color="primary"  onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary" 
                  >
                    Save
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

export default TagPopup;
