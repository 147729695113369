import React, { useRef, useEffect } from 'react';

const GoogleMapView = ({ markers }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    // Load the Google Maps JavaScript API script
    if(markers != null && markers.length > 0){
      window.initMap = () => {
        console.log('init..Map');
      };
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_APIKEY}&callback=initMap&v=weekly`;
      script.defer = true;
      //script.async = true;
      script.onload = () => {
        // Initialize the map
        const mapCenter = { lat: markers[0].latitude, lng: markers[0].longitude };
        //const mapCenter = { lat: 23.0776, lng: 72.5297 };
        const map = new window.google.maps.Map(mapRef.current, {
            center: mapCenter,
            zoom: 2,
        });
        //Add markers to the map
        markers.forEach((marker) => {
          new window.google.maps.Marker({
              position: { lat: marker.latitude, lng: marker.longitude },
              map: map,
              suppressMarkers: true,
          });
        });
      };

      document.head.appendChild(script);

      return () => {
        // Clean up the script to avoid memory leaks
        document.head.removeChild(script);
      };
    }
  }, []);

  return markers ? <div ref={mapRef} style={{ height: '400px', width: '100%' }} /> : null;
};

export default GoogleMapView;
