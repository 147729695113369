import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

function OrdersHeader(props) {
  return (
    <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 w-full items-center justify-between py-16 md:py-32 sm:px-12">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="text-24 md:text-32 font-extrabold tracking-tight"
      >
        {props.title}
      </Typography>
      <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
        {props.showSearch && (
          <div>
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            >
              <TextField
                className="items-center rounded-full border-1 shadow-0"
                placeholder="Search here..."
                size="small"
                name="searchtext"
                type="search"
                id="data"
                variant="outlined"
                onChange={(e) => props?.searchText(e.target.value)}
              />
            </motion.div>
          </div>
        )}
        {props.showAddButton && (
          <div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
            >
              <Button
                className=" "
                variant="contained"
                onClick={() => props?.onClick()}
                color="primary"
                startIcon={<FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon>}
              >
                {props.customButtonText ? props.customButtonText : "Add"}
              </Button>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrdersHeader;
