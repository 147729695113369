import { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    FormControl,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    Switch,
    Grid,
    InputLabel,
} from '@mui/material';
import { ExpandMore, FileUpload, HighlightOff, AddCircleOutlineRounded } from '@mui/icons-material';
import axiosClient from 'app/configs/axiosClientConfig';
import { useForm } from 'react-hook-form';
import URLConstant from 'src/app/constants/urlConstant';
import { useNavigate } from 'react-router-dom';
import ColorPickerButton from './ColorPickerExample';
import SocialQRPreview from './SocialQRPreview';
import { notification } from 'antd';
import DataConstant from 'src/app/constants/dataConstant';
import MessageConstant from 'src/app/constants/messageConstant';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import routes from "app/configs/routesConfig";

const SocialQR = (props) => {
    const {
        register,
        setValue,
    } = useForm();
    const user = useSelector(selectUser);
    var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;
    const navigate = useNavigate();
    const [masterKey, setMasterKey] = useState(Math.random());
    const [key, setKey] = useState(Math.random());
    const [previewKey, setPreviewKey] = useState(Math.random());
    const [qrCodeList, setQrCodeList] = useState([]);
    const [isValid, setIsValid] = useState([]);
    const [details, setDetails] = useState({
        logo: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: '',
        },
        BackGroundImage: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: '',
        },
        BusinessCardImage: {
            selectedFile: '',
            selectedFileName: '',
            selectedImageUrl: '',
        },
        id: '',
        Title: '',
        Description: '',
        YoutubeVideo: '',
        SocialLinks: [],
        AboutUs: '',
        Privacy: '',
        ButtonColor: '#00FFFF',
        TextColor: '#0F0F0F',
        CSSTemplate: '',
        CustomCSS: '',
        ShareThisPage: true,
        code: '',
        TemplateId: 0,
        url: '',
    });
    const [urlCodeMessage, setUrlCodeMessage] = useState('');

    const loginDetail =
        localStorage.getItem("loginDetails") != null
            ? JSON.parse(localStorage.getItem("loginDetails"))
            : null;

    const fetchData = async () => {
        await axiosClient
            .get(`${URLConstant.CssTemplates.GetCssTemplateDropDownList}/${loginDetail?.profile?.userid}`)
            .then((response) => {
                if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
                    setQrCodeList(response?.data);
                }
                else {
                    notification.error({
                        message: DataConstant.error,
                        description: response?.message,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const redirectToQRCode = () => {
        navigate(`/qrcodes`);
    };
    const SocialData = props.data || '';
    const setSocialData = () => {
        var tempDetails = details;
        (tempDetails.id = SocialData.id), (tempDetails.Title = SocialData.name);
        tempDetails.Description = SocialData.description;
        if (SocialData.logo && SocialData.logo != '') {
            tempDetails.logo.selectedImageUrl =
                URLConstant.Images.UserSocialRootPath + '/' + SocialData.id + '/' + SocialData.logo;
            tempDetails.logo.selectedFileName = SocialData.logo;
        }

        if (SocialData.bgImage && SocialData.bgImage != '') {
            tempDetails.BackGroundImage.selectedImageUrl =
                URLConstant.Images.UserSocialRootPath + '/' + SocialData.id + '/' + SocialData.bgImage;
            tempDetails.BackGroundImage.selectedFileName = SocialData.bgImage;
        }
        if (SocialData.favicon && SocialData.favicon != '') {
            tempDetails.BusinessCardImage.selectedImageUrl =
                URLConstant.Images.UserSocialRootPath + '/' + SocialData.id + '/' + SocialData.favicon;
            tempDetails.BusinessCardImage.selectedFileName = SocialData.favicon;
        }

        (tempDetails.YoutubeVideo = SocialData.youtubelink),
            (tempDetails.SocialLinks = SocialData.socialLinks ? JSON.parse(SocialData.socialLinks) : {}),
            (tempDetails.AboutUs = SocialData.aboutUs),
            (tempDetails.Privacy = SocialData.privacy),
            (tempDetails.ButtonColor = SocialData.buttoncolor),
            (tempDetails.TextColor = SocialData.textcolor),
            (tempDetails.CSSTemplate = SocialData.templateId),
            (tempDetails.CustomCSS = SocialData.customcss),
            (tempDetails.ShareThisPage = SocialData.sharepage),
            (tempDetails.code = SocialData.code),
            (tempDetails.url = SocialData.url),
            setDetails(tempDetails);
        setKey(Math.random());
        setMasterKey(Math.random());
    };
    useEffect(() => {
        fetchData();
        if (SocialData !== '') setSocialData();
        else GetShortHandUrl();
    }, []);

    const GetShortHandUrl = () => {
        axiosClient.get(`${URLConstant.QRCode.GetShortHandUrl}`).then((response) => {
            if (response) {
                setDetails((Pre) => {
                    return {
                        ...Pre,
                        code: response.data.qrCode,
                    };
                });
            }
        });
    };

    const handleLogoFile = (event) => {
        if (event?.target?.files && event?.target?.files[0]) {
            const selectedFile = event?.target?.files[0];
            if (selectedFile?.size > DataConstant.imageFileSize) {
                // Display an error message or prevent further processing
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.ImageValidationSize,
                });
                return;
            }
            if (selectedFile?.name.toLowerCase().match(DataConstant.imagePattern)) {
                setDetails({
                    ...details,
                    logo: {
                        selectedFile: event.target.files[0],
                        selectedFileName: event.target.files[0] ? event.target.files[0].name : '',
                        selectedImageUrl: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '',
                    },
                });
            }
            else {
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.PdfValidationType,
                });
            }
        }
    };

    const handleBusinessCardImageFile = (event) => {
        if (event?.target?.files && event?.target?.files[0]) {
            const selectedFile = event?.target?.files[0];
            if (selectedFile?.size > DataConstant.imageFileSize) {
                // Display an error message or prevent further processing
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.ImageValidationSize,
                });
                return;
            }
            if (selectedFile?.name.toLowerCase().match(DataConstant.imagePattern)) {
                setDetails({
                    ...details,
                    BusinessCardImage: {
                        selectedFile: event.target.files[0],
                        selectedFileName: event.target.files[0] ? event.target.files[0].name : '',
                        selectedImageUrl: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '',
                    },
                });
            }
            else {
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.PdfValidationType,
                });
            }
        }
    };

    const handleBackgroundImageFile = (event) => {
        if (event?.target?.files && event?.target?.files[0]) {
            const selectedFile = event?.target?.files[0];
            if (selectedFile?.size > DataConstant.imageFileSize) {
                // Display an error message or prevent further processing
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.ImageValidationSize,
                });
                return;
            }
            if (selectedFile?.name.toLowerCase().match(DataConstant.imagePattern)) {
                setDetails({
                    ...details,
                    BackGroundImage: {
                        selectedFile: event.target.files[0],
                        selectedFileName: event.target.files[0] ? event.target.files[0].name : '',
                        selectedImageUrl: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : '',
                    },
                });
            }
            else {
                notification.warning({
                    message: DataConstant.Warning,
                    description: MessageConstant.PdfValidationType,
                });
            }
        }

    };
    const AddSocialMediaHandle = (event, socialName, title, link) => {
        var tempDetails = details;
        var index = tempDetails.SocialLinks.findIndex((x) => x.name === socialName);
        if (index < 0) {
            var newObject = {
                name: socialName,
                imgSrc: event.target.currentSrc,
                title: title,
                link: link,
                titlePlaceholder: title,
                linkPlaceholder: link,
            };
            tempDetails.SocialLinks.push(newObject);
            setDetails(tempDetails);
            setKey(Math.random());
            setPreviewKey(Math.random());
        }
    };
    const removeSocial = (SocialName) => {
        var tempDetails = details;
        var index = tempDetails.SocialLinks.findIndex((x) => x.name === SocialName);
        if (index >= 0) {
            tempDetails.SocialLinks.splice(index, 1);
            setDetails(tempDetails);
            setKey(Math.random());
        }
    };
    const handleOnColorChange = (e) => {
        var tempDetails = details;
        tempDetails.ButtonColor = e;
        setDetails(tempDetails);
        setPreviewKey(Math.random());
    };
    const handleOnTextColorChange = (e) => {
        var tempDetails = details;
        tempDetails.TextColor = e;
        setDetails(tempDetails);
        setPreviewKey(Math.random());
    };
    const changeFiledValue = (e, Name) => {
        var tempDetails = details;
        var index = tempDetails.SocialLinks.findIndex((x) => x.name === Name);
        if (index >= 0) {
            tempDetails.SocialLinks[index].title = e.target.value;
            setDetails(tempDetails);
            setPreviewKey(Math.random());
        }
    };
    const changeLinkValue = (e, Name) => {
        var tempDetails = details;
        var index = tempDetails.SocialLinks.findIndex((x) => x.name === Name);
        if (index >= 0) {
            tempDetails.SocialLinks[index].link = e.target.value;
            setDetails(tempDetails);
            setPreviewKey(Math.random());
        }
    };
    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        setDetails((Pre) => {
            return {
                ...Pre,
                [name]: checked,
            };
        });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails((Pre) => {
            return {
                ...Pre,
                [name]: value,
            };
        });
        // setDropdownKey(Math.random());
    };
    const handleUrlCodeChange = (e) => {
        const { code, value } = e.target;
        const validCodeRegex = DataConstant.urlCodeRegex;
        if(validCodeRegex.test(value)){
            setUrlCodeMessage("");
        }
        else{
            setUrlCodeMessage(DataConstant.urlCodeMessage);
        }
        setDetails((Pre) => {
            return {
                ...Pre,
                code: value,
            };
        });
    }

    const isMatchWithRoute = (value) => {
        if(!value) return false;
        const allPaths = routes.map(route => route.path);
        
        const isMatch = allPaths.some(route => {
          const segments = route.split("/");
          const initial = segments[0] || segments[1];
          if(value?.toLowerCase() === initial?.toLowerCase() || value?.toLowerCase() === segments[1]?.toLowerCase()){
            return true;
          }
        });
        return isMatch;
      }

    const GoToCssTemplate = () => {
        navigate(`/csstemplates`);
    };
    const SubmitDetails = () => {
        if (isValid.length != 0) {
            notification.error({
                message: DataConstant.error,
                description: DataConstant.invalidUrlMessage,
            });
            return;
        }
        
        const formData = new FormData();
        if (details.logo.selectedFile) formData.append('Logo', details.logo.selectedFile);
        if (details.BackGroundImage.selectedFile)
            formData.append('BgImage', details.BackGroundImage.selectedFile);
        if (details.BusinessCardImage.selectedFile)
            formData.append('Favicon', details.BusinessCardImage.selectedFile);
        const modelToSubmit = {
            Id: details.id,
            Name: details?.Title.trim(),
            Type: DataConstant.qrcodeType.landingPageQR,
            Description: details.Description,
            AboutUs: details.AboutUs,
            Privacy: details.Privacy,
            Youtubelink: details.YoutubeVideo,
            Buttoncolor: details.ButtonColor,
            Textcolor: details.TextColor,
            Customcss: details.CustomCSS,
            Sharepage: details.ShareThisPage,
            SocialLinks: JSON.stringify(details.SocialLinks),
            Code: details.code,
            TemplateId: details.CSSTemplate,
            Url: URLConstant.OriginUrl,
            SubscriptionCurrentPaymentType: subscriptionPlanType
        };
        formData.append('model', JSON.stringify(modelToSubmit));
        if (modelToSubmit.Name == '') {
            notification.error({
                message: MessageConstant.error,
                description: DataConstant.titleRequired,
            });
            return;
        }
        if (modelToSubmit.Name.length > 60) {
            notification.error({
                message: MessageConstant.error,
                description: MessageConstant.TitleMaximum60Char,
            });
            return;
        }
        if (modelToSubmit.Youtubelink == '') {
            notification.error({
                message: MessageConstant.error,
                description: DataConstant.youtubeRequired,
            });
            return;
        }
        if (modelToSubmit.AboutUs == '') {
            notification.error({
                message: MessageConstant.error,
                description: DataConstant.aboutUsRequired,
            });
            return;
        } else {
            if (!modelToSubmit.AboutUs.match(DataConstant.urlRegex)) {
                notification.error({
                    message: MessageConstant.error,
                    description: DataConstant.aboutUsLinkInvaliMessage,
                });
                return;
            }
        }
        if (modelToSubmit.Privacy == '') {
            notification.error({
                message: MessageConstant.error,
                description: DataConstant.privacyPolicyRequired,
            });
            return;
        } else {
            if (!modelToSubmit.Privacy.match(DataConstant.urlRegex)) {
                notification.error({
                    message: MessageConstant.error,
                    description: DataConstant.privacyLinkInvaliMessage,
                });
                return;
            }
        }

        const validCodeRegex = DataConstant.urlCodeRegex;
        if(!validCodeRegex.test(details.code)){
            notification.error({
                message: MessageConstant.error,
                description: DataConstant.invalidUrlCode,
            });
            return;
        }

        if(isMatchWithRoute(details.code)){
            notification.warning({
              message: DataConstant.Warning,
              description: MessageConstant.CodeRestricted,
            });
            return;
        }

        axiosClient
            .post(URLConstant.QRCode.AddUpdateSocialQRCode, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)) {
                    notification.success({
                        message: details.id ? DataConstant.Update : DataConstant.Add,
                        description: response.message,
                    });
                    redirectToQRCode();
                } else if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)) {
                    notification.warning({
                        message: DataConstant.Warning,
                        description: response?.message,
                    });
                } else {
                    notification.error({
                        message: DataConstant.error,
                        description: response.message,
                    });
                }
            });
    };

    const CheckLinkValidity = (e) => {
        if (_.toLower(e.target.id) != "call_linkid" && _.toLower(e.target.id) != "mail_linkid" && !DataConstant.urlRegex.test(e.target.value)) {
            notification.error({
                message: DataConstant.error,
                description: DataConstant.invalidUrlMessage,
            });
            if (!isValid.includes(e.target.id)) {
                setIsValid([...isValid, e.target.id]);
            }
        } else {
            if (isValid.includes(e.target.id)) {
                setIsValid(isValid.filter(item=>item !== e.target.id));
            }
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Logo</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Choose an image</Typography>
                            <div className="flex items-center gap-10 my-8">
                                <input
                                    type="file"
                                    name="file1"
                                    accept="image/*"
                                    {...register('Logo')}
                                    id="file-logo-upload"
                                    onChange={handleLogoFile}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-logo-upload">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        endIcon={<FileUpload />}
                                    >
                                        Upload
                                    </Button>
                                </label>
                                <span className="font-bold">{details.logo.selectedFileName}</span>
                            </div>
                            <Typography>Choose a background image for your business card.</Typography>
                            <div className="flex items-center gap-10 my-8">
                                <input
                                    type="file"
                                    name="file2"
                                    accept="image/*"
                                    {...register('backgroundImage')}
                                    id="file-backgroundImage-upload"
                                    onChange={handleBackgroundImageFile}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-backgroundImage-upload">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        endIcon={<FileUpload />}
                                    >
                                        Upload
                                    </Button>
                                </label>
                                <span className="font-bold">{details.BackGroundImage.selectedFileName}</span>
                            </div>
                            <Typography>Choose a favicon for your business card.</Typography>
                            <div className="flex items-center gap-10 my-8">
                                <input
                                    type="file"
                                    name="file3"
                                    accept="image/*"
                                    {...register('businessCard')}
                                    id="file-businessCard-upload"
                                    onChange={handleBusinessCardImageFile}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-businessCard-upload">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        endIcon={<FileUpload />}
                                    >
                                        Upload
                                    </Button>
                                </label>
                                <span className="font-bold">{details.BusinessCardImage.selectedFileName}</span>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Title</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Title <span className="text-[#ff0000] text-lg-start"> *</span></Typography>

                            <TextField
                                id="Title"
                                name="Title"
                                maxLength="50"
                                value={details.Title}
                                className="my-8"
                                onChange={handleInputChange}
                            />
                            <Typography>Description</Typography>
                            <TextField
                                id="Description"
                                name="Description"
                                multiline
                                className="my-8"
                                rows={4}
                                value={details.Description}
                                onChange={handleInputChange}
                            />
                            <Typography>Youtube video<span className="text-[#ff0000] text-lg-start"> *</span></Typography>
                            <TextField
                                id="YoutubeVideo"
                                name="YoutubeVideo"
                                maxLength="50"
                                className="my-8"
                                value={details.YoutubeVideo}
                                onChange={handleInputChange}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Social links</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div key={key}>
                                {details.SocialLinks.map((data, i) => {
                                    return (
                                        <>
                                            <div key={i} className="flex items-start gap-8 mb-8">
                                                <img
                                                    className="max-w-30 mt-4"
                                                    src={data.imgSrc}
                                                    alt={data.name}
                                                    name={data.name}
                                                />
                                                <div>
                                                    <TextField
                                                        id={`${data.name}_Id`}
                                                        name={`${data.name}_Name`}
                                                        maxLength="50"
                                                        value={data.title}
                                                        onChange={(e) => changeFiledValue(e, data.name)}
                                                        placeholder={data.titlePlaceholder}
                                                        className="w-full mb-8"
                                                    />
                                                    <TextField
                                                        id={`${data.name}_LinkId`}
                                                        name={`${data.name}_LinkName`}
                                                        onChange={(e) => changeLinkValue(e, data.name)}
                                                        onBlur={(e) => CheckLinkValidity(e)}
                                                        maxLength="50"
                                                        value={data.link}
                                                        placeholder={data.linkPlaceholder}
                                                        className="w-full my-8"
                                                    />
                                                </div>
                                                <HighlightOff className="mt-4" onClick={() => removeSocial(data.name)} />
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="social-links-wrapper">
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(e, 'Facebook', 'Become a Fan', 'https://www.facebook.com/yourpage')
                                    }
                                    src={require('../../assets/img/facebook.png')}
                                    alt="facebook"
                                    name="facebook"
                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'Instagram', 'Follow us', 'https://www.instagram.com/yourpage')}
                                    src={require('../../assets/img/instagram.png')}
                                    alt="instagram"
                                    name="instagram"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'LinkedIn',
                                            'Connect with us',
                                            'https://www.linkedin.com/company/name'
                                        )
                                    }
                                    src={require('../../assets/img/linkedin.png')}
                                    alt="linkedin"
                                    name="linkedin"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'Pinterest',
                                            'Follow us',
                                            'https://www.pinterest.com/company'
                                        )
                                    }
                                    src={require('../../assets/img/pinterest.png')}
                                    alt="pinterest"
                                    name="pinterest"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'Spotify',
                                            'Listen to us',
                                            'https://www.open.spotify.com/artist/yourid'
                                        )
                                    }
                                    src={require('../../assets/img/spotify.png')}
                                    alt="spotify"
                                    name="spotify"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'Twitter',
                                            'Follow us',
                                            'https://www.twitter.com/Username'
                                        )
                                    }
                                    src={require('../../assets/img/twitter.jpg')}
                                    alt="twitter"
                                    name="twitter"

                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'Snapchat', 'Snap me', 'https://accounts.snapchat.com/')}
                                    src={require('../../assets/img/snapchat.png')}
                                    alt="snapchat"
                                    name="snapchat"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'Flickr',
                                            'Follow us',
                                            'https://www.flickr.com/photos/company'
                                        )
                                    }
                                    src={require('../../assets/img/flickr.png')}
                                    alt="flickr"
                                    name="flickr"
                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'Skype', 'Talk to us', 'https://skype.com/')}
                                    src={require('../../assets/img/skype.png')}
                                    alt="skype"
                                    name="skype"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'Sound Cloud',
                                            'Listen to us',
                                            'https://www.soundcloud.com/Username'
                                        )
                                    }
                                    src={require('../../assets/img/soundcloud.png')}
                                    alt="soundcloud"
                                    name="soundcloud"
                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'Tiktok', 'Our tiktok', 'https://tiktok.com/')}
                                    src={require('../../assets/img/tiktok.png')}
                                    alt="tiktok"
                                    name="tiktok"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'vimeo',
                                            'Subscribe to our channel',
                                            'https://www.vimeo.com/Username'
                                        )
                                    }
                                    src={require('../../assets/img/vimeo.png')}
                                    alt="vimeo"
                                    name="vimeo"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'xing',
                                            'Connect with us',
                                            'https://www.xing.com/companies/name'
                                        )
                                    }
                                    src={require('../../assets/img/xing.png')}
                                    alt="xing"
                                    name="xing"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(
                                            e,
                                            'youtube',
                                            'Subscribe to our channel',
                                            'https://www.youtube.com/user/channel'
                                        )
                                    }
                                    src={require('../../assets/img/youtube.png')}
                                    alt="youtube"
                                    name="youtube"
                                />
                                <img
                                    onClick={(e) =>
                                        AddSocialMediaHandle(e, 'mail', 'Send us a mail', 'yourname@mail.com')
                                    }
                                    src={require('../../assets/img/mail.png')}
                                    alt="mail"
                                    name="mail"
                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'Call', 'Call us', '+555 555 555')}
                                    src={require('../../assets/img/mobile.png')}
                                    alt="mobile"
                                    name="mobile"
                                />
                                <img
                                    onClick={(e) => AddSocialMediaHandle(e, 'web', 'Visit us', 'https://website.com/')}
                                    src={require('../../assets/img/web.png')}
                                    alt="web"
                                    name="web"
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>GDPR</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Link to about us<span className="text-[#ff0000] text-lg-start"> *</span></Typography>
                            <TextField
                                id="AboutUs"
                                name="AboutUs"
                                maxLength="50"
                                className="w-3/4 my-8"
                                value={details?.AboutUs}
                                onChange={handleInputChange}
                            />
                            <Typography>Link to privacy policy<span className="text-[#ff0000] text-lg-start"> *</span></Typography>
                            <TextField
                                id="Privacy"
                                name="Privacy"
                                maxLength="50"
                                className="w-3/4 my-8"
                                value={details?.Privacy}
                                onChange={handleInputChange}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion key={masterKey}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Branding</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Button color</Typography>
                            <ColorPickerButton
                                buttonDefaultColor={details?.ButtonColor}
                                ButtonColorChange={handleOnColorChange}
                            />
                            <Typography>Text color</Typography>
                            <ColorPickerButton
                                buttonDefaultColor={details?.TextColor}
                                ButtonColorChange={handleOnTextColorChange}
                            />
                            {/* <FormControl>
                                <InputLabel id="CssTemplateLableId">CSS template</InputLabel>
                                <Select
                                    labelId="CssTemplateLableId"
                                    id="CssTemplateId"
                                    name="CSSTemplate"
                                    value={details?.CSSTemplate}
                                    label="CSS Template"
                                    onChange={handleInputChange}
                                >
                                    {qrCodeList  && qrCodeList.length > 0  &&
                                        qrCodeList?.map((e, i) => {
                                            return (
                                                <MenuItem key={i} value={e?.id}>
                                                    {e?.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl> */}
                            {/* <Typography>
                                <Button onClick={GoToCssTemplate}>
                                    <AddCircleOutlineRounded />
                                    Add
                                </Button>
                            </Typography> */}
                            {/* <Typography>Custom CSS</Typography>
                            <TextField
                                id="CustomCSS"
                                multiline
                                value={details?.CustomCSS}
                                name="CustomCSS"
                                className="w-3/4 my-8"
                                rows={4}
                                onChange={handleInputChange}
                            /> */}
                            <Typography>Share this page</Typography>
                            <Switch
                                name="ShareThisPage"
                                checked={details?.ShareThisPage}
                                onChange={handleSwitchChange}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Vanity URL</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography> 
                                Vanity URL 
                                <span className="text-[#ff0000] text-lg-start"> *</span>
                            </Typography>
                            
                            <div className="flex items-center">
                                <TextField
                                    value={URLConstant.OriginUrl}
                                    maxLength="50"
                                    className="w-1/4 my-8"
                                    disabled
                                />
                                <TextField
                                    id="shorthandUrl"
                                    name="shorthandUrl"
                                    maxLength="50"
                                    className="w-3/4 my-8"
                                    placeholder="URL code"
                                    value={details?.code}
                                    disabled={SocialData !== ''}
                                    onChange={handleUrlCodeChange}
                                />
                            </div>
                            <div>
                            <span className="text-[#ff0000] text-lg-start">{urlCodeMessage}</span>
                            </div>
                            <div>
                                <label className="url-preview"><span>Preview:</span><br/> {URLConstant.OriginUrl}/{details?.code}</label>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12} sm={"auto"} className='sticky-preview'>
                    <SocialQRPreview data={details} key={previewKey} isPreview = {true} />
                </Grid>
            </Grid>
            <Button variant="contained" color="primary" className="my-8 flex" onClick={SubmitDetails}>
                {details?.id === '' ? 'Submit' : 'Update'}
            </Button>
        </>
    );
};
export default SocialQR;
