import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useMemo, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { selectUser } from "app/store/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FuseSvgIcon from "../FuseSvgIcon";
import URLConstant from "src/app/constants/urlConstant";
import { Avatar, Button } from "@mui/material";
import _ from "lodash";
import DataConstant from "src/app/constants/dataConstant";

function FuseShortcuts(props) {
  const user = useSelector(selectUser);
  const { navigation, shortcuts, onChange } = props;
  const navigate = useNavigate();
  const [expirationDate, setExpirationDate] = useState();
  //const [remainingDays, setRemainingDays] = useState("");
  var remainingDays = user?.InvoiceInfo?.RemainingDays;
  var packageStartDate = user?.InvoiceInfo?.ExpiredDate;
  
  var packageStatus = user?.InvoiceInfo?.PackageStatus;
  var packageType = user?.InvoiceInfo?.InvoiceType;
  const searchInputRef = useRef(null);
  const [addMenu, setAddMenu] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const shortcutItems = shortcuts
    ? shortcuts.map((id) => navigation.find((item) => item.id === id))
    : [];

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  function addMenuClose() {
    setAddMenu(null);
  }
  useEffect(() => {
    // if (
    //   packageStartDate &&
    //   _.toLower(packageStatus) === _.toLower(DataConstant.Active) &&
    //   _.toLower(packageType) === _.toLower(DataConstant.packageType.Free)
    // ) {
    //   const expirationDate = new Date(packageStartDate);
    //   //Calculate remaining days
    //   const timeDiff = expirationDate.getTime() - new Date().getTime();
    //   const remainingDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    //   setRemainingDays(remainingDays);
    //   setExpirationDate(expirationDate);
    // } else if (
    //   packageStartDate &&
    //   _.toLower(packageStatus) === _.toLower(DataConstant.Active) &&
    //   _.toLower(packageType) === _.toLower(DataConstant.packageType.Quarterly)
    // ) {
    //   const expirationDate = new Date(packageStartDate);
    //   const timeDiff = expirationDate.getTime() - new Date().getTime();
    //   const remainingDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    //   setRemainingDays(remainingDays);
    //   setExpirationDate(expirationDate);
    // } else if (
    //   packageStartDate &&
    //   _.toLower(packageStatus) === _.toLower(DataConstant.Active) &&
    //   _.toLower(packageType) === _.toLower(DataConstant.packageType.Year)
    // ) {
    //   const expirationDate = new Date(packageStartDate);
    //   const timeDiff = expirationDate.getTime() - new Date().getTime();
    //   const remainingDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    //   setRemainingDays(remainingDays);
    //   setExpirationDate(expirationDate);
    // }
    //  else {
    //   setRemainingDays(0);
    // }
    const expDate = new Date(packageStartDate);
    setExpirationDate(expDate);
  }, [packageStartDate]);

  // useEffect(() => {
  //   if(_.toLower(packageStatus) === _.toLower(DataConstant.InActive)){
  //     setRemainingDays(-1);
  //   }
  // }, [packageStatus])

  function search(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && navigation) {
      setSearchResults(
        navigation.filter((item) =>
          item.title.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
      return;
    }
    setSearchResults(null);
  }

  function toggleInShortcuts(id) {
    let newShortcuts = [...shortcuts];
    newShortcuts = newShortcuts.includes(id)
      ? newShortcuts.filter((_id) => id !== _id)
      : [...newShortcuts, id];
    onChange(newShortcuts);
  }

  function ShortcutMenuItem({ item, onToggle }) {
    if (!item || !item.id) {
      return null;
    }

    return (
      <Link to={item.url || ""} role="button">
        <MenuItem key={item.id}>
          <ListItemIcon className="min-w-40">
            {item.icon ? (
              <FuseSvgIcon>{item.icon}</FuseSvgIcon>
            ) : (
              <span className="text-20 font-semibold uppercase text-center">
                {item.title[0]}
              </span>
            )}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          <IconButton
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              onToggle(item.id);
            }}
            size="large"
          >
          </IconButton>
        </MenuItem>
      </Link>
    );
  }

  return (
    <>
      <div
        className={clsx(
          "flex flex-1",
          props.variant === "vertical" && "flex-col grow-0 shrink",
          props.className
        )}
      >
        {useMemo(() => {
          const container = {
            show: {
              transition: {
                staggerChildren: 0.1,
              },
            },
          };
          const item = {
            hidden: { opacity: 0, scale: 0.6 },
            show: { opacity: 1, scale: 1 },
          };
          return (
            <motion.div
              variants={container}
              initial="hidden"
              animate="show"
              className={clsx(
                "flex flex-1",
                props.variant === "vertical" && "flex-col"
              )}
            >
              {shortcutItems.map(
                (_item) =>
                  _item && (
                    <Link to={_item.url} key={_item.id} role="button">
                      <Tooltip
                        title={_item.title}
                        placement={
                          props.variant === "horizontal" ? "bottom" : "left"
                        }
                      >
                        <IconButton
                          className="w-40 h-40 p-0"
                          component={motion.div}
                          variants={item}
                          size="large"
                        >
                          {_item.icon ? (
                            <FuseSvgIcon>{_item.icon}</FuseSvgIcon>
                          ) : (
                            <span className="text-20 font-semibold uppercase">
                              {_item.title[0]}
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </Link>
                  )
              )}
              <div className="flex items-center py-5">
                <Avatar
                  className="md:mx-10"
                  // className="w-full max-w-48 mr-5 "
                  src={
                    user.companyProfileData.CompanyProfile && user.companyProfileData.CompanyProfile !== ""
                      ? URLConstant.Images.UserCompanyLogoPath + loginDetail?.profile?.subscription + "/" + user?.companyProfileData?.CompanyProfile
                      : require('../../../app/assets/img/no-img.png')
                  }
                  alt="My logo"
                />
                <>
                  {loginDetail !== null && loginDetail !== "" && loginDetail !== undefined && remainingDays !== undefined && remainingDays !== "" ? (
                    _.toLower(packageStatus) === _.toLower(DataConstant.Active) && remainingDays >= 0 && _.toLower(loginDetail?.profile?.UserRole) == _.toLower(DataConstant.userRole.Admin) ? (
                      <>
                        <div className="flex flex-col p-2">
                          <p className="font-bold text-[#ff0000] p-2" >
                            {remainingDays <= 7 ? remainingDays == 0 ? 'Your current package will expire today' : `Your current package will expire in ${remainingDays} days` : ''}
                          </p>
                          {remainingDays <= 3 && remainingDays > 0 && (
                            <p className="font-bold p-2">
                              Renewal date: <span className="font-semibold p-2">{expirationDate?.toDateString()} </span>
                            </p>
                          )}
                        </div>
                        {remainingDays <= 3 && remainingDays >= 0 && (<Button
                          style={{ marginLeft: "10px", fontSize: 'smaller' }}
                          color="primary"
                          variant="contained"
                          onClick={() => navigate(`/UpgradePackage`)}
                        >
                          UPGRADE PACKAGE 
                        </Button>
                        )}
                      </>

                    ) : null
                  ) : null /* If remainingDays is not defined or an empty string, don't display anything */}
                </>
                {_.toLower(loginDetail?.profile?.UserRole) === _.toLower(DataConstant.userRole.Admin) && (remainingDays == -1 || remainingDays < 0) &&
                  <>
                    <p className="font-bold text-[#ff0000]">
                      Package has expired!
                      <span />
                      <Button
                        style={{ marginLeft: "20px", fontSize: 'smaller' }}
                        color="primary"
                        variant="contained"
                        onClick={() => navigate(`/UpgradePackage`)}
                      >
                        UPGRADE PACKAGE
                      </Button>
                    </p>
                  </>}
                {/* {remainingDays <= 3 && remainingDays > 0 && (
                  <>
                    <p className="font-bold">
                      Renewal date: {expirationDate.toDateString()} <span />
                      <Button
                        style={{ marginLeft: "10px" }}
                        color="primary"
                        variant="contained"
                        onClick={() => navigate(`/UpgradePackage`)}
                      >
                        UPGRADE PACKAGE
                      </Button>
                    </p>
                  </>
                )} */}
              </div>
            </motion.div>
          );
        }, [addMenu, props.variant, shortcutItems])}

        <Menu
          id="add-menu"
          anchorEl={addMenu}
          open={Boolean(addMenu)}
          onClose={addMenuClose}
          classes={{
            paper: "min-w-256",
          }}
          TransitionProps={{
            onEntered: () => {
              searchInputRef.current.focus();
            },
            onExited: () => {
              setSearchText("");
            },
          }}
        >
          <div className="p-16 pt-8">
            <Input
              inputRef={searchInputRef}
              value={searchText}
              onChange={search}
              placeholder="Search for an app or page"
              className=""
              fullWidth
              inputProps={{
                "aria-label": "Search",
              }}
              disableUnderline
            />
          </div>

          <Divider />

          {searchText.length !== 0 &&
            searchResults &&
            searchResults.map((_item) => (
              <ShortcutMenuItem
                key={_item.id}
                item={_item}
                onToggle={() => toggleInShortcuts(_item.id)}
              />
            ))}

          {searchText.length !== 0 && searchResults.length === 0 && (
            <Typography color="text.secondary" className="p-16 pb-8">
              No results..
            </Typography>
          )}

          {searchText.length === 0 &&
            shortcutItems.map(
              (_item) =>
                _item && (
                  <ShortcutMenuItem
                    key={_item.id}
                    item={_item}
                    onToggle={() => toggleInShortcuts(_item.id)}
                  />
                )
            )}
        </Menu>
      </div>
    </>
  );
}

FuseShortcuts.propTypes = {};
FuseShortcuts.defaultProps = {
  variant: "horizontal",
};

export default memo(FuseShortcuts);
