import _, { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import GridComponent from "src/app/components/gridComponent";
import {
  Modal,
  notification,
  Tag
} from "antd";
import dataConstant from "src/app/constants/dataConstant";
import messageConstant from "src/app/constants/messageConstant";
import axiosClient from "../../configs/axiosClientConfig";
import DataConstant from "src/app/constants/dataConstant";
import FusePageSimple from "@fuse/core/FusePageSimple";
import PageHeader from "../../components/pageHeader";
import { IconButton,Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CssTemplates = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [cssTemplateList, setcssTemplateList] = useState({
    count: 0,
    data: [],
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: 'Status',
      dataIndex: 'activated',
      key: 'activated',
      sorter: false,
      align: 'center',
      width: 150,
      render: (isActive, _) => (
        <>
          {isActive === true && <Tag color="#6cc75a">Active</Tag>}
          {isActive === false && <Tag color="#363570">In Active</Tag>}
        </>
      ),     
    },
    {
      title: "Action",
      dataIndex: "",
      key: "code",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render: (render) => (
        <span>
           <Tooltip title={dataConstant.Clone}>
           <IconButton  onClick={() => cloneData(render)} >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>       
          
          <Tooltip title={dataConstant.Edit}>
            <IconButton onClick={() => editCssTemplete(render.id)} >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={dataConstant.Delete}>
            <IconButton onClick={() => confirmDeleteCssTemplate([render.id])}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const cloneData = (data) => {
    const modelToSubmit = {
      Name: data.name,
      Css: data.css,
      Default: data.default,
      Activated: data.activated ? true : false,
    };
    axiosClient
      .post(URLConstant.CssTemplates.CreateCssTemplate, modelToSubmit)
      .then((response) => {
        if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: DataConstant.Clone,
            description:response?.message,
          }); 
            fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest))); 
        } else {
          notification.error({
            message: DataConstant.error,
            description: response?.message,
          });
        }
      });
  };
 
  const fetchData = async (requestObject) => {
    await axiosClient
      .post(URLConstant.CssTemplates.GetCssTemplateList, requestObject)
      .then((response) => {
        setcssTemplateList({
          data: response.data.list,
          count: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Refresh = () => {
    setSearchText("");
  };

  useEffect(() => {
    fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  const redirectToCssTemplete = () => {
    navigate(`/managecsstemplates`);
  };
  const editCssTemplete = (id) => {
    navigate(`/managecsstemplates/${id}`);
  };

  const deleteCssTemplate = (Id) => {
    axiosClient
      .delete(`${URLConstant.CssTemplates.DeleteCssTemplate}/${Id}`, true)
      .then((response) => {
        if (response && response.data.status === DataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: dataConstant.Delete,
            description: response?.message,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
        } else {
          notification.error({
            message: dataConstant.error,
            description:response?.message,
          });
        }
      });
  };
  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    setTableRequestParam(requestObject);
    let modelData = JSON.parse(JSON.stringify(tableRequestParam));
    if (!requestObject.SearchText) {   
    }
    setSearchText(requestObject.SearchText);
    if (requestObject) {
      modelData = requestObject;
      _.map(JSON.parse(JSON.stringify(tableRequestParam)), (item, index) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!modelData.hasOwnProperty(index)) {
          modelData[index] = item;
        }
      });
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(dataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
      if (!requestObject.SearchText) {      
      }
    }
    setTableRequestParam(modelData);
  };

  const confirmDeleteCssTemplate = (Id) => {
    Modal.confirm({
      title: messageConstant.DeleteConfirmation,
      content: messageConstant.DeleteConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        deleteCssTemplate(Id);
      },
    });
  };


  return (
    <FusePageSimple
    header={
      <PageHeader
        title="Css Template"
        showSearch
        description="Landing page QR"
        searchText={setSearchText}
        showAddButton
        onClick={redirectToCssTemplete}
      />
    }
    content={
          <GridComponent
        key={refreshTable}
        rowKey="id"
        columns={columns}
        dataSource={cssTemplateList.data}
        getGridData={getRequestObj}
        tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
        totalCount={cssTemplateList.count}
        scroll={{ x: "max-content" , y: "40vh" }}
        refresh={Refresh}
        SearchText={searchText}
        pageNumber={pageNumber}
        deleteCallbackMethod={confirmDeleteCssTemplate}
      />
    }
  />

  );
};

export default CssTemplates;
