import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { ImageCrop } from "./imageCrop/imageCrop";
import { checkFileExtension } from "./fileValidation";
import DataConstant from "../constants/dataConstant";

export const GetFileComponent = (props) => {
  const {
    id,
    name,
    accept,
    allowCrop,
    uploadFileName,
    moduleName,
    callback,
    showFileLabel,
    buttonNameId,
    className,
    cropSquare,
    customCropDimension,
    disabled,
  } = props;
  
  const url = null;
  const [fileName, setFileName] = useState();
  const [isError] = useState();
  const [typeList, setTypeList] = useState([]);
  const [typeFlagList, setTypeFlagList] = useState({
    isImage: false,
    isPdf: false,
    isCsv: false,
  });

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imageRef, setImageRef] = useState();
  const [croppedImageName, setCroppedImageName] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [srcFile, setSrcFile] = useState(null);
  const [eventOfUpload, setEventOfUpload] = useState(null);
  const submitButtonRef = useRef(null);

  /* Generate file type flags */
  const generateTypeFlag = () => {
    if (accept && accept.length > 0) {
      accept.forEach((type) => {
        switch (type) {
          case "image":
            setTypeList((oldArray) => [...oldArray, "image/*"]);
            setTypeFlagList((prev) => ({ ...prev, isImage: true }));
            break;
          case "pdf":
            setTypeList((oldArray) => [...oldArray, "application/pdf"]);
            setTypeFlagList((prev) => ({ ...prev, isPdf: true }));
            break;
          case "csv":
            setTypeList((oldArray) => [...oldArray, ".csv"]);
            setTypeFlagList((prev) => ({ ...prev, isCsv: true }));
            break;
          default:
            break;
        }
      });
    } else {
      setTypeFlagList((prev) => ({ ...prev, isImage: true }));
    }
  };

  /* Handle file input */
  const getDataFile = (event) => {
    if (event.target.files.length > 0) {
      setEventOfUpload(event.target.files[0]);
      let dataFile = event.target.files[0];
      event.target.value = "";

      setSrcFile(dataFile);
      if (uploadFileName) {
        dataFile = new File(
          [dataFile],
          `${uploadFileName}.${dataFile.name.split(".").pop()}`,
          {
            type: dataFile.type,
          }
        );
      }
      if (checkFileExtension(dataFile, typeFlagList, moduleName)) {
        if (allowCrop) {
          setCroppedImageName(dataFile.name);
          openCropImagePopup(dataFile);
        } else {
          callback({ file: dataFile, url: URL.createObjectURL(dataFile) });
        }
      } else {
        event.target.value = null;
      }
    }
  };

  useEffect(() => {
    generateTypeFlag();
  }, []);

  /* --------------------IMAGE CROPPING-----------------------  */
  const [crop, setCrop] = useState(
    cropSquare
      ? customCropDimension || { unit: "%", width: 30, aspect: 16 / 9 }
      : { unit: "%", aspect: 1, width: 30, height: 50 }
  );

  const openCropImagePopup = (imageFile) => {
    if (imageFile) {
      if (!imageFile.name.toLowerCase().match(DataConstant.imagePattern)) {
        setFile("");
        setOpen(false);
      } else {
        setOpen(true);
        const reader = new FileReader();
        reader.addEventListener("load", () => setFile(reader.result));
        reader.readAsDataURL(imageFile);
      }
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop);
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) return;
        blob.name = croppedImageName;
        setCroppedImage(new File([blob], croppedImageName, { type: blob.type }));
        var fileUrl = "";
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const saveImage = () => {
    submitButtonRef.current.click();
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (imageRef && crop) {
      getCroppedImg(imageRef, crop).then((url) => {
        setCroppedImageUrl(url);
      });
    }
  }, [imageRef]);

  return (
    <>
      <Button
        variant="contained"
        component="label"
        disabled={disabled}
        className={className}
        sx={{ backgroundColor: "#47bdef", "&:hover": { backgroundColor: "#3ba9d4" } }}
      >
        {buttonNameId}
        <input
          hidden
          type="file"
          accept={accept ? typeList.join(",") : "image/*"}
          onChange={(e) => getDataFile(e)}
          id={id}
          name={name}
        />
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{fileName}</DialogTitle>
        <DialogContent>
          {file && (
            <ImageCrop
              eventOfUpload={eventOfUpload}
              callback={callback}
              setShowModal={setOpen}
              submitButtonRef={submitButtonRef}
              cropSquare={cropSquare}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={saveImage} variant="contained" color="primary">
            Submit
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {showFileLabel && fileName && (
        isError ? (
          <label style={{ color: "red" }}>{fileName}</label>
        ) : (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {fileName}
          </a>
        )
      )}
    </>
  );
};
