import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  IconButton,
  Divider,
  TextareaAutosize,
  FormLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SketchPicker } from 'react-color';
import VCardPreview from './VCardPreview';
import { GetFileComponent } from 'src/app/components/GetFileComponent';
import URLConstant from 'src/app/constants/urlConstant';
import { notification } from 'antd';
import DataConstant from 'src/app/constants/dataConstant';
import { HighlightOff } from '@mui/icons-material';
import axiosClient from 'app/configs/axiosClientConfig';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MessageConstant from 'src/app/constants/messageConstant';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import routes from 'app/configs/routesConfig';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {} from 'react-phone-input-2/lib/material.css';

const themes = [
  { primary: '#455a64', button: '#e91e63' },
  { primary: '#0288d1', button: '#64b5f6' },
  { primary: '#d32f2f', button: '#ef9a9a' },
  { primary: '#4caf50', button: '#81c784' },
  { primary: '#795548', button: '#ff8a65' },
  { primary: '#42a48c', button: '#e9b764' },
  { primary: '#f564ac', button: '#36c17d' },
  { primary: '#ff8a65', button: '#4b5d71' },
  { primary: '#7a1ea1', button: '#1de9b6' },
  { primary: '#3f51b5', button: '#ff4081' },
];

const BusinessCard = ({ data }) => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;
  const [urlCodeMessage, setUrlCodeMessage] = useState('');
  const [showPicker, setShowPicker] = useState({
    primary: false,
    button: false,
    gradient: false,
  });
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState([]);
  const [userInfo, setUserInfo] = useState({
    id: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    phone: '',
    fax: '',
    email: '',
    company: '',
    yourJob: '',
    address: '',
    website: '',
    summary: '',
    sharepage: false,
    socialLinks: [],
    buttonColor: '#e91e63',
    primaryColor: '#455a64',
    useGradient: false,
    gradientColor: '#ff0000',
    splashScreenColor:'#f5f5f5',
    code: '',
    profilePic: {
      selectedFile: '',
      selectedFileName: '',
      selectedImageUrl: '',
    },
    backGroundImage: {
      selectedFile: '',
      selectedFileName: '',
      selectedImageUrl: '',
    },
    url: '',
  });

  const defaultValues = {
    name: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    fax: '',
    email: '',
    company: '',
    yourJob: '',
    address: '',
    website: '',
    summary: '',
    email: '',
    phone: '',
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
  });
  const { errors } = formState;

  // Update userInfo with data from props
  useEffect(() => {
    if (data) {
      reset(data);

      const updatedProfilePic = data?.logo
        ? URLConstant.Images.UserBusinessCardRootPath +
          '/' +
          data?.id +
          '/' +
          data?.logo
        : '';

      const updatedBackGroundImage = data?.bgImage
        ? URLConstant.Images.UserBusinessCardRootPath +
          '/' +
          data?.id +
          '/' +
          data?.bgImage
        : '';

      setUserInfo({
        id: data.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        mobileNo: data?.mobileNo,
        phone: data?.phone,
        fax: data?.fax,
        email: data?.email,
        company: data?.company,
        yourJob: data?.yourJob,
        address: data?.address,
        website: data?.website,
        summary: data?.summary,
        sharepage: data?.sharepage,
        socialLinks: data?.socialLinks ? JSON.parse(data?.socialLinks) : {},
        code: data?.code,
        name: data?.name,
        buttonColor: data?.buttoncolor,
        primaryColor: data?.textcolor,
        useGradient: data?.useGradient,
          splashScreenColor: data?.splashScreenColor ?? '#f5f5f5',
        gradientColor: data?.gradientColor,
        profilePic: {
          selectedImageUrl: updatedProfilePic,
          selectedFileName: data?.logo || '',
        },
        backGroundImage: {
          selectedImageUrl: updatedBackGroundImage,
          selectedFileName: data?.bgImage || '',
        },
        url: data?.url,
      });
    } else {
      GetShortHandUrl();
    }
  }, [data]);

  const GetShortHandUrl = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetShortHandUrl}`)
      .then((response) => {
        if (response) {
          setUserInfo({
            ...userInfo,
            code: response?.data?.qrCode,
          });
        }
      });
  };

  const handleUrlCodeChange = (e) => {
    const { code, value } = e.target;
    const validCodeRegex = DataConstant.urlCodeRegex;
    if (validCodeRegex.test(value)) {
      setUrlCodeMessage('');
    } else {
      setUrlCodeMessage(DataConstant.urlCodeMessage);
    }
    setUserInfo({
      ...userInfo,
      code: value,
    });
  };

  const applyTheme = (theme) => {
    setUserInfo({
      ...userInfo,
      buttonColor: theme?.button,
      primaryColor: theme?.primary,
    });
  };

  const togglePicker = (colorType) => {
    setShowPicker({ ...showPicker, [colorType]: !showPicker[colorType] });
  };

  const handleColorChange = (color, colorType) => {
    if (colorType === 'primary') {
      setUserInfo({ ...userInfo, primaryColor: color?.hex });
    } else if (colorType === 'button') {
      setUserInfo({ ...userInfo, buttonColor: color?.hex });
    } else if (colorType === 'gradient') {
      setUserInfo({ ...userInfo, gradientColor: color?.hex });
    }
    else if (colorType === 'splashScreenColor') {
      setUserInfo({ ...userInfo, splashScreenColor: color?.hex });
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneInfoChange = (name, value) => {
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const getProfilePicFile = (res) => {
    setUserInfo({
      ...userInfo,
      profilePic: {
        selectedFile: res?.file,
        selectedFileName: res?.file ? res?.file.name : '',
        selectedImageUrl: res?.file ? URL.createObjectURL(res?.file) : '',
      },
    });
  };

  const getLogoPicFile = (res) => {
    setUserInfo({
      ...userInfo,
      backGroundImage: {
        selectedFile: res?.file,
        selectedFileName: res?.file ? res?.file?.name : '',
        selectedImageUrl: res?.file ? URL.createObjectURL(res?.file) : '',
      },
    });
  };

  const isMatchWithRoute = (value) => {
    if (!value) return false;
    const allPaths = routes.map((route) => route.path);

    const isMatch = allPaths.some((route) => {
      const segments = route.split('/');
      const initial = segments[0] || segments[1];
      if (
        value?.toLowerCase() === initial?.toLowerCase() ||
        value?.toLowerCase() === segments[1]?.toLowerCase()
      ) {
        return true;
      }
    });
    return isMatch;
  };

  const SubmitDetails = (data) => {

    if (isValid.length !== 0) {
      if (isValid.includes('mail_LinkId')) {
        notification.error({
          message: MessageConstant.error,
          description: 'Please enter a valid email.',
        });
      } else if (isValid.includes('Call_LinkId')) {
        notification.error({
          message: MessageConstant.error,
          description: 'Enter a valid phone number.',
        });
      } else {
        notification.error({
          message: DataConstant.error,
          description: DataConstant.invalidUrlMessage,
        });
      }
      return;
    }

    const modelToSubmit = {
      id: userInfo.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNo: data?.mobileNo,
      phone: data?.phone,
      fax: data?.fax,
      email: data?.email,
      company: data?.company,
      yourJob: data?.yourJob,
      address: data?.address,
      website: data?.website,
      sharepage: userInfo?.sharepage,
      buttoncolor: userInfo?.buttonColor,
      textcolor: userInfo?.primaryColor,
      splashScreenColor: userInfo?.splashScreenColor,
      subscriptionCurrentPaymentType: subscriptionPlanType,
      type: DataConstant.qrcodeType.businessCard,
      socialLinks: JSON.stringify(userInfo?.socialLinks),
      useGradient: userInfo?.useGradient,
      gradientColor: userInfo?.gradientColor,
      summary: data?.summary,
      code: userInfo?.code,
      name: data?.name,
      Url: URLConstant.OriginUrl,
    };

    const formData = new FormData();

    if (userInfo?.profilePic && userInfo?.profilePic?.selectedFile) {
      formData.append('Logo', userInfo?.profilePic?.selectedFile);
    } else if (userInfo.id && userInfo?.profilePic?.selectedFileName === '') {
      modelToSubmit.removeLogo = true;
    }

    if (userInfo?.backGroundImage && userInfo?.backGroundImage?.selectedFile) {
      formData.append('BgImage', userInfo?.backGroundImage?.selectedFile);
    } else if (
      userInfo?.id &&
      userInfo?.backGroundImage?.selectedFileName === ''
    ) {
      modelToSubmit.removeBgImage = true;
    }

    formData.append('model', JSON.stringify(modelToSubmit));

    const validCodeRegex = DataConstant.urlCodeRegex;

    if (!validCodeRegex.test(userInfo.code)) {
      notification.error({
        message: MessageConstant.error,
        description: DataConstant.invalidUrlCode,
      });
      return;
    }

    if (isMatchWithRoute(userInfo.code)) {
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.CodeRestricted,
      });
      return;
    }

    axiosClient
      .post(URLConstant.QRCode.AddUpdateBusinessCardQRCode, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        if (
          response &&
          _.toLower(response.status) ==
            _.toLower(DataConstant.API_STATUS.SUCCESS)
        ) {
          notification.success({
            message: userInfo.id ? DataConstant.Update : DataConstant.Add,
            description: response.message,
          });
          navigate(`/qrcodes`);
        } else if (
          response &&
          _.toLower(response.status) ==
            _.toLower(DataConstant.API_STATUS.WARNING)
        ) {
          notification.warning({
            message: DataConstant.Warning,
            description: response?.message,
          });
        } else {
          notification.error({
            message: DataConstant.error,
            description: response.message,
          });
        }
      });
  };

  const CheckLinkValidity = (e) => {
    if (
      _.toLower(e.target.id) === 'mail_linkid' &&
      !DataConstant.EmailRegex.test(e.target.value)
    ) {
      notification.error({
        message: MessageConstant.error,
        description: 'Please enter a valid email.',
      });
      if (!isValid.includes(e.target.id)) {
        setIsValid([...isValid, e.target.id]);
      }
    } else if (_.toLower(e.target.id) === 'call_linkid') {
      const digitsOnly = e.target.value?.replace(/^\+\d+\s*/, ''); // Extract digits only when checking phone number

      if (digitsOnly.length === 0 || digitsOnly.length < 7) {
        notification.error({
          message: MessageConstant.error,
          description: 'Enter a valid phone number.',
        });
        if (!isValid.includes(e.target.id)) {
          setIsValid([...isValid, e.target.id]);
        }
      } else {
        if (isValid.includes(e.target.id)) {
          setIsValid(isValid.filter((item) => item !== e.target.id));
        }
      }
    } else if (
      _.toLower(e.target.id) !== 'mail_linkid' &&
      _.toLower(e.target.id) !== 'call_linkid' &&
      !DataConstant.urlRegex.test(e.target.value)
    ) {
      notification.error({
        message: DataConstant.error,
        description: DataConstant.invalidUrlMessage,
      });
      if (!isValid.includes(e.target.id)) {
        setIsValid([...isValid, e.target.id]);
      }
    } else {
      if (isValid.includes(e.target.id)) {
        setIsValid(isValid.filter((item) => item !== e.target.id));
      }
    }
  };

  const changeLinkValue = (e, socialName) => {
    const updatedLinks = userInfo?.socialLinks?.map((link) => {
      if (link.name === socialName) {
        return { ...link, link: link.name === 'Call' ? e : e?.target?.value };
      }
      return link;
    });
    setUserInfo({ ...userInfo, socialLinks: updatedLinks });
  };

  const removeSocial = (socialName,socialId) => {
    const updatedLinks = userInfo?.socialLinks?.filter(
      (link) => link.name !== socialName
    );
    setUserInfo({ ...userInfo, socialLinks: updatedLinks });

    if (isValid.includes(socialId)) {
      setIsValid(isValid.filter((item) => item !== socialId));
    }
  };

  const AddSocialMediaHandle = (event, socialName, title, link) => {
    const existingLink = userInfo?.socialLinks?.find(
      (x) => x.name === socialName
    );
    if (!existingLink) {
      const newObject = {
        name: socialName,
        imgSrc: event?.target?.currentSrc,
        title: title,
        link: link,
        titlePlaceholder: title,
        linkPlaceholder: link,
      };
      setUserInfo((prev) => ({
        ...prev,
        socialLinks: [...prev.socialLinks, newObject],
      }));
    }
  };

  const swapColors = () => {
    setUserInfo((prev) => ({
      ...prev,
      primaryColor: prev?.buttonColor,
      buttonColor: prev?.primaryColor,
    }));
  };

  return (
    <>
      <form
        noValidate
        name="businessCardForm"
        autoComplete="off"
        onSubmit={handleSubmit(SubmitDetails)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Grid item xs={12} sm={12}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Max 100 characters allowed',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className=""
                    label="Name"
                    autoFocus
                    placeholder="QRcode name"
                    type="Name"
                    error={!!errors?.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Design & Customize your business card</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Predefined Themes Section */}
                <Grid container spacing={2}>
                  {themes.map((theme, index) => (
                    <Grid item xs={4} md={2} lg="auto" key={index}>
                      <Paper
                        onClick={() => applyTheme(theme)}
                        sx={{
                          background: '#fff',
                          padding: 0.5,
                          height: '60px',
                          borderRadius: 2,
                          cursor: 'pointer',
                          position: 'relative',
                          aspectRatio: '1',
                          border:
                            theme?.primary === userInfo?.primaryColor &&
                            theme?.button === userInfo?.buttonColor
                              ? '3px solid #184f90'
                              : '1px solid #ddd',
                        }}
                      >
                        <Box
                          sx={{
                            height: '100%',
                            width: '100%',
                            background: theme?.primary,
                            borderRadius: '8px',
                          }}
                        />
                        <Box
                          sx={{
                            height: '15px',
                            width: '15px',
                            background: theme?.button,
                            borderRadius: '8px',
                            position: 'absolute',
                            bottom: '10px',
                            right: '10px',
                            borderRadius: '4px',
                          }}
                        />
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
                {/* Color Pickers in One Row */}
                <Grid container spacing={2} className="mt-2">
                  {/* Primary Color Picker */}
                  <Grid item xs={12} md>
                    <Typography sx={{ marginBottom: 1 }}>Primary</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: userInfo?.primaryColor,
                          width: '20px',
                          height: '20px',
                          borderRadius: '4px',
                          aspectRatio: '1',
                        }}
                      />
                      <TextField
                        value={userInfo?.primaryColor}
                        onFocus={() => togglePicker('primary')}
                        sx={{ flexGrow: 1, marginLeft: 1.5 }}
                      />
                    </Box>
                    {showPicker.primary && (
                      <Box sx={{ position: 'relative' }}>
                        <SketchPicker
                          color={userInfo?.primaryColor}
                          onChangeComplete={(color) =>
                            handleColorChange(color, 'primary')
                          }
                        />
                        <Button
                          sx={{
                            marginTop: 1,
                            backgroundColor: '#47bdef',
                          }}
                          variant="contained"
                          onClick={() => togglePicker('primary')}
                        >
                          Apply
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{ display: 'flex', alignItems: 'flex-start' }}
                    xs={'auto'}
                  >
                    <IconButton
                      sx={{ marginLeft: 0.5, marginTop: '25px' }}
                      size="large"
                      onClick={swapColors}
                    >
                      <SwapHorizIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md>
                    <Typography sx={{ marginBottom: 1 }}>Button</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: userInfo?.buttonColor,
                          width: '20px',
                          height: '20px',
                          borderRadius: '4px',
                        }}
                      />
                      <TextField
                        value={userInfo?.buttonColor}
                        onFocus={() => togglePicker('button')}
                        sx={{ flexGrow: 1, marginLeft: 1.5 }}
                      />
                    </Box>
                    {showPicker.button && (
                      <Box sx={{ position: 'relative' }}>
                        <SketchPicker
                          color={userInfo?.buttonColor}
                          onChangeComplete={(color) =>
                            handleColorChange(color, 'button')
                          }
                        />
                        <Button
                          sx={{
                            marginTop: 1,
                            backgroundColor: '#47bdef',
                          }}
                          variant="contained"
                          onClick={() => togglePicker('button')}
                        >
                          Apply
                        </Button>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ mt: 0 }}>
                      <Checkbox
                        className="default-checkbox-leftmargin"
                        checked={userInfo?.useGradient}
                        onChange={(e) =>
                          setUserInfo({
                            ...userInfo,
                            useGradient: e?.target?.checked,
                          })
                        }
                        inputProps={{ 'aria-label': 'use-gradient' }}
                      />
                      Use color gradient
                      {userInfo.useGradient && (
                        <>
                          <Typography sx={{ mt: 2 }}>Gradient Color</Typography>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              marginBottom: 0,
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: userInfo?.gradientColor,
                                width: '20px',
                                height: '20px',
                                borderRadius: '4px',
                                marginRight: '10px',
                              }}
                            />
                            <TextField
                              value={userInfo?.gradientColor}
                              onFocus={() => togglePicker('gradient')}
                              sx={{ flexGrow: 1 }}
                            />
                          </Box>
                          {showPicker.gradient && (
                            <Box sx={{ position: 'relative' }}>
                              <SketchPicker
                                color={userInfo?.gradientColor}
                                onChangeComplete={(color) =>
                                  handleColorChange(color, 'gradient')
                                }
                              />
                              <Button
                                sx={{
                                  marginTop: 1,
                                  backgroundColor: '#47bdef',
                                }}
                                variant="contained"
                                onClick={() => togglePicker('gradient')}
                              >
                                Apply
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Accordion for Your Information */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Your information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        marginBottom: 1,
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 3,
                      }}
                    >
                      <Typography variant="body1" sx={{ minWidth: '100px' }}>
                        Image:
                      </Typography>
                      {/* Profile Picture */}
                      <div style={{ position: 'relative' }}>
                        {userInfo?.profilePic &&
                        userInfo?.profilePic?.selectedImageUrl ? (
                          <img
                            src={userInfo?.profilePic?.selectedImageUrl}
                            alt="Profile"
                            style={{
                              borderRadius: '50%',
                              width: '80px',
                              height: '80px',
                              objectFit: 'cover',
                            }}
                          />
                        ) : (
                          <img
                            src={require('../../../app/assets/img/no-img.png')}
                            alt="Profile"
                            style={{
                              borderRadius: '50%',
                              width: '80px',
                              height: '80px',
                              objectFit: 'cover',
                            }}
                          />
                        )}

                        {/* Delete Button */}
                        {userInfo?.profilePic?.selectedImageUrl && (
                          <span
                            onClick={() =>
                              setUserInfo({
                                ...userInfo,
                                profilePic: {
                                  selectedImageUrl: '',
                                  selectedFileName: '',
                                },
                              })
                            }
                            style={{
                              position: 'absolute',
                              top: '-5px',
                              right: '-5px',
                              cursor: 'pointer',
                              backgroundColor: '#f44336',
                              borderRadius: '50%',
                              padding: '5px',
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'center',
                              width: '30px',
                            }}
                          >
                            &#10006;
                          </span>
                        )}
                      </div>

                      {/* File Upload Component */}
                      <div>
                        <GetFileComponent
                          allowCrop={true}
                          name={'profilePic'}
                          callback={getProfilePicFile}
                          buttonNameId="Upload"
                          moduleName="QR Code"
                        />
                      </div>
                      
                    </Box>
                    <Typography variant="body2" sx={{ minWidth: '100px' ,marginBottom:"10px" ,color:'red' }}>
                         Note : Supported formats JPG,JPEG,PNG
                      </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 ">First name</FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `First name is required`,
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Enter first name"
                          autoFocus
                          type="firstName"
                          error={!!errors?.firstName}
                          helperText={errors?.firstName?.message}
                          variant="outlined"
                          required
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 ">Last name</FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Last name is required`,
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="lastName"
                          error={!!errors?.lastName}
                          helperText={errors?.lastName?.message}
                          variant="outlined"
                          required
                          fullWidth
                          placeholder="Enter last name"
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 ">Mobile no</FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <Controller
                      name="mobileNo"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Mobile number is required`,
                        },
                        minLength: {
                          value: 7,
                          message: 'Enter valid mobile number',
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          specialLabel=""
                          inputProps={{
                            name: 'mobileNo',
                          }}
                          className={`mobile-full ${
                            errors.mobileNo ? 'cm-error' : ''
                          }`}
                          country={'us'}
                          countryCodeEditable={false}
                          autoFormat={true}
                          onChange={(value, data, event, formattedValue) => {
                            field.onChange(formattedValue);
                            handlePhoneInfoChange('mobileNo', formattedValue);
                          }}
                          enableSearch={true}
                          enableLongNumbers={true}
                        />
                      )}
                    />
                    {errors.mobileNo && (
                      <span
                        style={{
                          color: '#f44336',
                          marginLeft: '12px',
                          fontSize: '12px',
                        }}
                      >
                        {errors.mobileNo.message}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 " sx={{ zIndex: 0 }}>
                      Phone
                    </FormLabel>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        validate: (value) => {
                          // Check for digits only after the country code
                          const digitsOnly = value?.replace(/^\+\d+\s*/, '');
                          return (
                            digitsOnly.length === 0 ||
                            digitsOnly.length >= 7 ||
                            'Enter valid phone'
                          );
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          specialLabel=""
                          inputProps={{
                            name: 'phone',
                          }}
                          className={`mobile-full ${
                            errors.phone ? 'cm-error' : ''
                          }`}
                          country={'us'}
                          countryCodeEditable={false}
                          autoFormat={true}
                          onChange={(value, data, event, formattedValue) => {
                            field.onChange(formattedValue);
                            handlePhoneInfoChange('phone', formattedValue);
                          }}
                          enableSearch={true}
                          enableLongNumbers={true}
                        />
                      )}
                    />
                    {errors.phone && (
                      <span
                        style={{
                          color: '#f44336',
                          marginLeft: '12px',
                          fontSize: '12px',
                        }}
                      >
                        {errors.phone.message}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16" sx={{ zIndex: 0 }}>
                      Fax
                    </FormLabel>
                    <Controller
                      name="fax"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="fax"
                          error={!!errors?.fax}
                          helperText={errors?.fax?.message}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter fax"
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16" sx={{ zIndex: 0 }}>
                      Email
                    </FormLabel>
                    <span className="text-[#ff0000] text-lg-start"> *</span>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Email is required`,
                        },
                        pattern: {
                          value: DataConstant.EmailRegex,
                          message: `Enter valid email`,
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="email"
                          placeholder="Enter email"
                          error={!!errors.email}
                          helperText={errors?.email?.message}
                          variant="outlined"
                          required
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16" sx={{ zIndex: 0 }}>
                      Company
                    </FormLabel>
                    <Controller
                      name="company"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          placeholder="Enter company"
                          error={!!errors.company}
                          helperText={errors?.company?.message}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 " sx={{ zIndex: 0 }}>
                      Your Job
                    </FormLabel>
                    <Controller
                      name="yourJob"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          placeholder="Enter your job"
                          error={!!errors.yourJob}
                          helperText={errors?.yourJob?.message}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ zIndex: 0 }}>
                    <FormLabel className="mt-16 " sx={{ zIndex: 0 }}>
                      Address
                    </FormLabel>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 250,
                          message: 'Max 250 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="address"
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: { resize: 'auto' },
                              minRows: 4,
                              maxRows: 4,
                            },
                          }}
                          error={!!errors.address}
                          helperText={errors?.address?.message}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter address"
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 " sx={{ zIndex: 0 }}>
                      Summary
                    </FormLabel>
                    <Controller
                      name="summary"
                      control={control}
                      rules={{
                        maxLength: {
                          value: 250,
                          message: 'Max 250 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="summary"
                          placeholder="Enter summary"
                          error={!!errors.summary}
                          helperText={errors?.summary?.message}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          InputLabelProps={{
                            shrink: true, // This makes the label "strictly" visible
                          }}
                          InputProps={{
                            inputComponent: TextareaAutosize,
                            inputProps: {
                              style: { resize: 'auto' },
                              minRows: 4,
                              maxRows: 4,
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormLabel className="mt-16 ">Website</FormLabel>
                    <Controller
                      name="website"
                      control={control}
                      rules={{
                        pattern: {
                          value: DataConstant.urlRegex,
                          message: `${MessageConstant.EnterValid} website url`,
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max 100 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="website"
                          placeholder="Enter website"
                          error={!!errors?.website}
                          helperText={errors?.website?.message}
                          variant="outlined"
                          onChange={(e) => {
                            field.onChange(e);
                            handleUserInfoChange(e);
                          }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className="social-icons-inner">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Social links</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-25">
                  {userInfo?.socialLinks?.map((linkData, i) => (
                    <div
                      className="flex items-start gap-8 mb-8 relative mr-10"
                      key={i}
                    >
                      <img
                        className="max-w-30 mt-4"
                        src={linkData?.imgSrc}
                        alt={linkData?.name}
                      />
                      <Grid item xs>
                        {linkData?.name === 'Call' ? (
                          <PhoneInput
                            specialLabel=""
                            placeholder={linkData?.linkPlaceholder}
                            inputProps={{
                              id: `${linkData?.name}_LinkId`,
                              name: `${linkData?.name}_LinkName`,
                            }}
                            className="mobile-full"
                            country={'us'}
                            countryCodeEditable={false}
                            autoFormat={true}
                            value={linkData?.link}
                            onChange={(value, data, event, formattedValue) => {
                              changeLinkValue(formattedValue, linkData?.name);
                            }}
                            onBlur={(e) => CheckLinkValidity(e)}
                            enableSearch={true}
                            enableLongNumbers={true}
                          />
                        ) : (
                          <TextField
                            id={`${linkData?.name}_LinkId`}
                            name={`${linkData?.name}_LinkName`}
                            onChange={(e) => changeLinkValue(e, linkData?.name)}
                            onBlur={(e) => CheckLinkValidity(e)}
                            inputProps={{ maxLength: 50 }}
                            value={linkData?.link}
                            placeholder={linkData?.linkPlaceholder}
                            className="mb-8"
                          />
                        )}
                      </Grid>
                      <HighlightOff
                        className="mt-4 remove-social"
                        onClick={() => removeSocial(linkData?.name,`${linkData?.name}_LinkId`)}
                      />
                    </div>
                  ))}

                  {/* {userInfo?.socialLinks?.map((data, i) => (
                    <div className="flex items-start gap-8 mb-8 relative mr-10">
                      <img
                        className="max-w-30 mt-4"
                        src={data?.imgSrc}
                        alt={data?.name}
                      />
                      <Grid item xs>
                        <TextField
                          id={`${data?.name}_LinkId`}
                          name={`${data?.name}_LinkName`}
                          onChange={(e) => changeLinkValue(e, data?.name)}
                          onBlur={(e) => CheckLinkValidity(e)}
                          inputProps={{ maxLength: 50 }}
                          value={data?.link}
                          placeholder={data?.linkPlaceholder}
                              className="mb-8"
                        />
                      </Grid>
                      <HighlightOff
                        className="mt-4 remove-social"
                        onClick={() => removeSocial(data?.name)}
                      />
                    </div>
                  ))} */}       
          
                </div>
                <div className="social-links-wrapper">
                  {/* Add your social media icons here with respective links */}
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Facebook',
                        'Become a Fan',
                        'https://www.facebook.com/yourpage'
                      )
                    }
                    src={require('../../assets/img/facebook.png')}
                    alt="facebook"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Instagram',
                        'Follow us',
                        'https://www.instagram.com/yourpage'
                      )
                    }
                    src={require('../../assets/img/instagram.png')}
                    alt="instagram"
                    name="instagram"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'LinkedIn',
                        'Connect with us',
                        'https://www.linkedin.com/company/name'
                      )
                    }
                    src={require('../../assets/img/linkedin.png')}
                    alt="linkedin"
                    name="linkedin"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Pinterest',
                        'Follow us',
                        'https://www.pinterest.com/company'
                      )
                    }
                    src={require('../../assets/img/pinterest.png')}
                    alt="pinterest"
                    name="pinterest"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Spotify',
                        'Listen to us',
                        'https://www.open.spotify.com/artist/yourid'
                      )
                    }
                    src={require('../../assets/img/spotify.png')}
                    alt="spotify"
                    name="spotify"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Twitter',
                        'Follow us',
                        'https://www.twitter.com/Username'
                      )
                    }
                    src={require('../../assets/img/twitter.jpg')}
                    alt="twitter"
                    name="twitter"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Snapchat',
                        'Snap me',
                        'https://www.snapchat.com/add/Username'
                      )
                    }
                    src={require('../../assets/img/snapchat.png')}
                    alt="snapchat"
                    name="snapchat"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Flickr',
                        'Follow us',
                        'https://www.flickr.com/photos/company'
                      )
                    }
                    src={require('../../assets/img/flickr.png')}
                    alt="flickr"
                    name="flickr"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Skype',
                        'Talk to us',
                        'https://skype.com/'
                      )
                    }
                    src={require('../../assets/img/skype.png')}
                    alt="skype"
                    name="skype"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Sound Cloud',
                        'Listen to us',
                        'https://www.soundcloud.com/Username'
                      )
                    }
                    src={require('../../assets/img/soundcloud.png')}
                    alt="soundcloud"
                    name="soundcloud"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Tiktok',
                        'Our tiktok',
                        'https://tiktok.com/'
                      )
                    }
                    src={require('../../assets/img/tiktok.png')}
                    alt="tiktok"
                    name="tiktok"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'vimeo',
                        'Subscribe to our channel',
                        'https://www.vimeo.com/Username'
                      )
                    }
                    src={require('../../assets/img/vimeo.png')}
                    alt="vimeo"
                    name="vimeo"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'xing',
                        'Connect with us',
                        'https://www.xing.com/companies/name'
                      )
                    }
                    src={require('../../assets/img/xing.png')}
                    alt="xing"
                    name="xing"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'youtube',
                        'Subscribe to our channel',
                        'https://www.youtube.com/user/channel'
                      )
                    }
                    src={require('../../assets/img/youtube.png')}
                    alt="youtube"
                    name="youtube"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'mail',
                        'Send us a mail',
                        'yourname@mail.com'
                      )
                    }
                    src={require('../../assets/img/mail.png')}
                    alt="mail"
                    name="mail"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'Call',
                        'Call us',
                        '+1 555 555 5555'
                      )
                    }
                    src={require('../../assets/img/mobile.png')}
                    alt="mobile"
                    name="mobile"
                  />
                  <img
                    onClick={(e) =>
                      AddSocialMediaHandle(
                        e,
                        'web',
                        'Visit us',
                        'https://website.com/'
                      )
                    }
                    src={require('../../assets/img/web.png')}
                    alt="web"
                    name="web"
                  />
                  {/* Repeat for other social media platforms */}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Welcome screen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: 1,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  {/* Image Label */}
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Image:
                  </Typography>

                  {/* Logo Upload Section */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    {/* Profile Picture */}
                    <div style={{ position: 'relative' }}>
                      {userInfo.backGroundImage &&
                      userInfo.backGroundImage.selectedImageUrl ? (
                        <img
                          src={userInfo.backGroundImage.selectedImageUrl}
                          alt="Logo"
                          style={{
                            borderRadius: '8px',
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <img
                          src={require('../../assets/img/defaultscreenlogo.png')}
                          alt="Logo"
                          style={{
                            borderRadius: '8px',
                            width: '80px',
                            height: '80px',
                            objectFit: 'cover',
                          }}
                        />
                      )}

                      {/* Delete Button */}
                      {userInfo.backGroundImage.selectedImageUrl && (
                        <span
                          onClick={() =>
                            setUserInfo({
                              ...userInfo,
                              backGroundImage: {
                                selectedImageUrl: '',
                                selectedFileName: '',
                              },
                            })
                          }
                          style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-5px',
                            cursor: 'pointer',
                            backgroundColor: '#f44336',
                            borderRadius: '50%',
                            padding: '5px',
                            color: 'white',
                            width: '30px',
                            height: '30px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          &#10006;
                        </span>
                      )}
                    </div>

                    {/* File Upload Component */}
                    <GetFileComponent
                      allowCrop={true}
                      name={'backGroundImage'}
                      buttonNameId="Logo"
                      moduleName="QR Code"
                      callback={getLogoPicFile}
                    />

                    <Button variant="text" onClick={() => setLoading(true)}>
                      Preview
                    </Button>
                  </Box>
                  <Typography variant="body2" sx={{ minWidth: '100px',color:'red' }}>
                         Note : Supported formats JPG,JPEG,PNG
                      </Typography>
                      <Grid item xs={12}>
                    <Box sx={{ mt: 0 }}>
                        <>
                          <Typography sx={{ mt: 2 }}>Background Color</Typography>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              marginBottom: 0,
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: userInfo?.splashScreenColor,
                                width: '20px',
                                height: '20px',
                                borderRadius: '4px',
                                marginRight: '10px',
                              }}
                            />
                            <TextField
                              value={userInfo?.splashScreenColor}
                              onFocus={() => togglePicker('splashScreenColor')}
                              sx={{ flexGrow: 1 }}
                            />
                          </Box>
                          {showPicker.splashScreenColor && (
                            <Box sx={{ position: 'relative' }}>
                              <SketchPicker
                                color={userInfo?.splashScreenColor}
                                onChangeComplete={(color) =>
                                  handleColorChange(color, 'splashScreenColor')
                                }
                              />
                              <Button
                                sx={{
                                  marginTop: 1,
                                  backgroundColor: '#47bdef',
                                }}
                                variant="contained"
                                onClick={() => togglePicker('splashScreenColor')}
                              >
                                Apply
                              </Button>
                            </Box>
                          )}
                        </>
                   
                    </Box>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Advanced options</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography gutterBottom>Sharing:</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={userInfo?.sharepage || false}
                          onChange={handleUserInfoChange}
                          name="sharepage"
                          color="primary"
                        />
                      }
                      label="Add a share button to the page"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Vanity URL</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Vanity URL
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                </Typography>

                <div className="flex items-center">
                  <TextField
                    value={URLConstant.OriginUrl}
                    maxLength="50"
                    className="w-1/4 my-8"
                    disabled
                  />
                  <TextField
                    id="shorthandUrl"
                    name="shorthandUrl"
                    maxLength="50"
                    className="w-3/4 my-8"
                    placeholder="URL code"
                    value={userInfo?.code}
                    disabled={data !== ''}
                    onChange={handleUrlCodeChange}
                  />
                </div>
                <div>
                  <span className="text-[#ff0000] text-lg-start">
                    {urlCodeMessage}
                  </span>
                </div>
                <div>
                  <label className="url-preview">
                    <span>Preview:</span>
                    <br /> {URLConstant.OriginUrl}/{userInfo?.code}
                  </label>
                </div>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="sticky-preview" item xs={12} sm="auto">
            <VCardPreview
              userInfo={userInfo}
              isPreview={true}
              setLoading={setLoading}
              loading={loading}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          className="mt-16"
          variant="contained"
          color="primary"
        >
          {userInfo?.id === '' ? 'Submit' : 'Update'}
        </Button>
      </form>
    </>
  );
};

export default BusinessCard;
