import FuseUtils from "@fuse/utils";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import Error404Page from "../main/404/Error404Page";
import DashboardConfig from "../main/dashboard/DashboardConfig";
import UserConfig from "../main/user/UserConfig";
import LoginConfig from "../main/Login/LoginConfig";
import LogInResponseConfig from "../main/LoginResponse/LoginResponseConfig";
import UserProfileConfig from "../main/UserProfile/UserProfileConfig";
import QrCodeConfig from "../main/qrCode/QrCodeConfig";
import companyProfileConfig from "../main/Settings/companyProfileConfig";
import CssTemplatesConfig from "../main/CssTemplates/CssTemplatesConfig";
import ShortUrlConfig from "../main/UrlReader/ShortUrlConfig";
import PaymentSubscriptionConfig from "../main/PaymentSubscription/PaymentSubscriptionConfig";

const routeConfigs = [
  DashboardConfig,
  LoginConfig,
  LogInResponseConfig,
  UserConfig,
  UserProfileConfig,
  QrCodeConfig,
  CssTemplatesConfig,
  companyProfileConfig,
  ShortUrlConfig,
  PaymentSubscriptionConfig, 
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/auth/login" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
