import { useEffect, useState } from "react";
import {
  Button,
  FormLabel,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import axiosClient from "app/configs/axiosClientConfig";
import { useForm } from "react-hook-form";
import URLConstant from "src/app/constants/urlConstant";
import { notification } from "antd";
import DataConstant from "src/app/constants/dataConstant";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import routes from "app/configs/routesConfig";
import MessageConstant from "src/app/constants/messageConstant";
import "../../../styles/app-custom.css";

const WifiPage = (props) => {
  const user = useSelector(selectUser);
  var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = props;
  const [urlState, setURLState] = useState("Static");
  const [shorthandUrl, setShorthandUrl] = useState("");

  const [hiddenPasswordInput, setHiddenPasswordInput] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (data) {
      setURLState(data.subTypes ?? "Static");
      setShorthandUrl(data.code);
      setValue("name", data.name);
      setValue("ssid", data.ssid);
      setValue("password", data.password);
      setValue("code", data.code);
      setValue("networkType", data.networkType);
      data.networkType == "nopass" ? setHiddenPasswordInput(false) : setHiddenPasswordInput(true);
    } else {
      GetShortHandUrl();
      setHiddenPasswordInput(false)
    }
  }, [data]);

  const redirectToQRCode = () => {
    navigate(`/qrcodes`);
  };
  const GetShortHandUrl = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetShortHandUrl}`)
      .then((response) => {
        if (response) {
          setShorthandUrl(response.data.qrCode);
          setValue("code", response.data.qrCode);
        }
      });
  };
  const isMatchWithRoute = (value) => {
    if (!value) return false;
    const allPaths = routes.map((route) => route.path);

    const isMatch = allPaths.some((route) => {
      const segments = route.split("/");
      const initial = segments[0] || segments[1];
      if (
        value?.toLowerCase() === initial?.toLowerCase() ||
        value?.toLowerCase() === segments[1]?.toLowerCase()
      ) {
        return true;
      }
    });
    return isMatch;
  };

  const onFinish = (qrCodeDetail) => {
    const code = qrCodeDetail?.code;

    if (isMatchWithRoute(code)) {
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.CodeRestricted,
      });
      return;
    }
    const modelToSubmit = {
      id: id ? id : "",
      Type: DataConstant.qrcodeType.wifi,
      SubTypes: urlState,
      Name: qrCodeDetail?.name.trim(),
      SSID: qrCodeDetail?.ssid,
      Url: "",
      code: urlState === "Dynamic" ? qrCodeDetail?.code : null,
      NetworkType: qrCodeDetail?.networkType,
      Password: hiddenPasswordInput ? qrCodeDetail?.password:null,
      SubscriptionCurrentPaymentType: subscriptionPlanType,
    };

    const requestURL = id
      ? URLConstant.QRCode.UpdateUrlQRCode
      : URLConstant.QRCode.AddQRCodes;
    axiosClient.post(requestURL, modelToSubmit).then((response) => {
      if (
        response &&
        _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)
      ) {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        redirectToQRCode();
      } else if (
        response &&
        _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)
      ) {
        notification.warning({
          message: DataConstant.Warning,
          description: response?.message,
        });
      } else {
        notification.error({
          message: DataConstant.error,
          description: response?.message,
        });
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setURLState(value);
  };
  const handlePasswordInputChange = (event) => {
    const { name, value } = event.target;
    value == "nopass" ? setHiddenPasswordInput(false) : setHiddenPasswordInput(true);
  };
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onFinish)}>
        <div className="px-16">
          <FormLabel className="mt-16 ">Name</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="name"
            name="name"
            className="w-full my-8"
            placeholder="QRcode name"
            {...register("name", {
              required: "Please enter name",
              maxLength: {
                value: 60,
                message: MessageConstant.EnterMaximum60Char,
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />

          <FormLabel className="mt-16 ">SSID</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="ssid"
            name="ssid"
            className="w-full my-8"
            placeholder="SSID"
            {...register("ssid", {
              required: "Please enter SSID",
              maxLength: {
                value: 60,
                message: MessageConstant.EnterMaximum60Char,
              },
            })}
            error={!!errors.ssid}
            helperText={errors.ssid?.message}
          />
          {hiddenPasswordInput && (
            <>
              <FormLabel className="mt-16 ">Password</FormLabel>
              <span className="text-[#ff0000] text-lg-start"> *</span>
              <TextField
                id="password"
                name="password"
                type="password"
                className="w-full my-8"
                placeholder="Password"
                {...register("password", {
                  required: "Please enter password",
                  maxLength: {
                    value: 60,
                    message: MessageConstant.EnterMaximum60Char,
                  },
                })}
              
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </>
          )}

          <FormLabel className="mt-16 ">Network type</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="networkType"
            name="networkType"
            className="w-full my-8  "
            inputProps={{ className: "custom-input-field" }}
            select
            defaultValue={
              id ? data?.networkType : DataConstant?.NetworkTypes[0]?.value
            }
            {...register("networkType", {
              required: "Please select network type",
            })}
            onChange={handlePasswordInputChange}
            error={!!errors.networkType}
            helperText={errors.networkType?.message}
          >
            {DataConstant?.NetworkTypes?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </TextField>

          <Typography
            variant="body2"
            component="p"
            className="inline-block align-bottom py-10"
          >
            Note : Static QR code will not track your QR code scan.
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="my-8 flex"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
export default WifiPage;
