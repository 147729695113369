import { TextField } from "@mui/material";
import React, { useState } from "react";
import { SketchPicker } from 'react-color';


const ColorPickerExample = (props) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(props.buttonDefaultColor);
  const handleChange = (value) => {
    props.ButtonColorChange(value.hex);
    setValue(value.hex);
  };

  return (<>
    <div className="flex items-center relative">
      <TextField
        id="Title"
        name="Title"
        maxLength="50"
        value={value}
        className="my-8"
        disabled
      />
      <div onClick={() => setShow(!show)} style={{ backgroundColor: props.buttonDefaultColor, height: "40px", width: "40px" }} />
      {show && <SketchPicker
        className="cm-color-picker"
        color={props.buttonDefaultColor}
        onChangeComplete={handleChange}
      />}
    </div>
  </>
  );
}

export default ColorPickerExample;
