import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { notification } from 'antd';
import axiosClient from 'app/configs/axiosClientConfig';
import React, { useEffect } from 'react'
import { HostedForm } from 'react-acceptjs'
import { useNavigate } from 'react-router-dom';
import DataConstant from 'src/app/constants/dataConstant';
import messageConstant from 'src/app/constants/messageConstant';
import URLConstant from 'src/app/constants/urlConstant';

const PaymentPage = () => {
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  useEffect(() => {
    setTimeout(() => {
      const payNowBtn = document.querySelector('.AcceptUI');
      if (payNowBtn) {
        payNowBtn.click();
        showCardDialog();
      }
    }, 900);
  });

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type !== undefined && event.data.type === 'CLOSE_IFRAME') {
        //setIsLoadingServiceCategory(false);
        //removejsfile("AcceptUI.js", "js");
        if(currentUrl == window.location.href) redirectToUserPaymentProfile();
      }
      //   if (event.data.type !== undefined && event.data.type === 'RESPONSE') {
      //     history.push(returnToken());
      //   }
    });
  }, []);

  const showCardDialog = () => {
    const dialogBg = document.querySelector('#AcceptUIBackground');
    const dialogCn = document.querySelector('#AcceptUIContainer');
    if(dialogBg) dialogBg.classList.add('show');
    if(dialogCn) dialogCn.classList.add('show');
  }

  const redirectToUserPaymentProfile = () => {
    navigate(`/Subscription/UserPaymentProfile`);
  };

  const authData = {
    apiLoginID: process.env.REACT_APP_PAYMENT_APILOGINID,
    clientKey: process.env.REACT_APP_PAYMENT_CLIENTKEY,
  };


  const createUserPaymentProfile = (data) => {
    const HostedCardDetails = {
      cardNumber: data.encryptedCardData.cardNumber,
      expDate: data.encryptedCardData.expDate,
      token: data.opaqueData.dataValue,
    };
    const PaymentDetails = {
      Token: HostedCardDetails.token,
      CardNumber: HostedCardDetails.cardNumber.replace(/[^\d.]/g, ""),
      FirstName: loginDetail?.profile?.name,
      LastName: "---",
      IsDefault: false,
    };
    const paymentRequest = {
      UserProfileId: "",
      PaymentDetails: PaymentDetails,
      //IsProduction: process.env.NODE_ENV === 'production',
      UserId: loginDetail?.profile?.userid,
    };

    axiosClient
      .post(
        URLConstant.UserPaymentProfile.CreateUserPaymentProfile,
        paymentRequest
      )
      .then((response) => {
        if (response && response.data.status === DataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: messageConstant.Save,
            description: messageConstant.CardAdded,
          });
          redirectToUserPaymentProfile();
        }
        else if (response.data.data.message == "server.validation.exists") {
          notification.error({
            message: messageConstant.error,
            description: messageConstant.AlreadyExists,
          });
        }
        else {
          notification.error({
            message: response.data.data.message,
            description: messageConstant.TryAgain,
          });
          redirectToUserPaymentProfile();
        }
        redirectToUserPaymentProfile();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const billingAddress = {
    show: false,
    required: false,
  }

  return (
    <HostedForm
      environment={process.env.REACT_APP_PAYMENT_TESTMODE}
      authData={authData}
      buttonText={null}
      onSubmit={createUserPaymentProfile}
      billingAddressOptions={billingAddress}
      formButtonText="Submit"
      // buttonText={<><FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon> Add</>}
      formHeaderText="Card Information"
      buttonClassName="cm-btn-hidden btn-sm btn-primary" />
  )
}

export default PaymentPage