import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";
import ManageUserProfile from "./ManageUserProfile";

import React from "react";
import { element } from "prop-types";
import SwitchRole from "./SwitchRole";
import SwitchResponse from "./SwitchResponse";
import CreateAccount from "./CreateAccount";

i18next.addResourceBundle("en", "userprofilePage", en);
i18next.addResourceBundle("tr", "userprofilePage", tr);
i18next.addResourceBundle("ar", "userprofilePage", ar);

const UserProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "manageprofile/:id",
      element: <ManageUserProfile />,
    },
    {
      path: "switchresponse/:userrole/:uid/:subid/:whiteLabel",
      element: <SwitchResponse />,
    },
    {
      path: "createaccount/:userid",
      element: <CreateAccount />,
    },
  ],
};

export default UserProfileConfig;
