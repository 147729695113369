import React from "react";
import ManageCssTemplates from "./ManageCssTemplates";
import CssTemplates from "./CssTemplates";

const CssTemplatesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "csstemplates",
      element: <CssTemplates />,
    },
    {
      path: "managecsstemplates",
      element: <ManageCssTemplates />,
    },
    {
      path: "managecsstemplates/:id",
      element: <ManageCssTemplates />,
    },
  ],
};

export default CssTemplatesConfig;
