import React, { useEffect, useState } from 'react';
import FusePageSimple from '@fuse/core/FusePageSimple';
import PageHeader from '../../components/pageHeader';
import { Tooltip, notification } from 'antd';
import {
  Button,
  FormLabel,
  TextField,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import DataConstant from 'src/app/constants/dataConstant';
import URLConstant from 'src/app/constants/urlConstant';
import axiosClient from 'app/configs/axiosClientConfig';
import MessageConstant from 'src/app/constants/messageConstant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CreateAccount = (props) => {
  const { userid } = useParams();
  const [phoneNum, setPhoneNum] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode:'all'
  });
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const onFinish = (details) => {
    const request = {
      id: userid,
      firstName: details?.FirstName,
      lastName: details?.LastName,
      phoneNumber: phoneNum ? phoneNum : '',
      whiteLabel: details?.Whitelabel?.toLowerCase(),
    };
    if (!userid) {
      notification.error({
        message: DataConstant.error,
        description: DataConstant.SWW,
      });
      return;
    }
    if (request.firstName?.trim() == '') {
      notification.error({
        message: DataConstant.error,
        description: MessageConstant.FirstNameRequired,
      });
      return;
    }
    if (request.lastName?.trim() == '') {
      notification.error({
        message: DataConstant.error,
        description: MessageConstant.LastNameRequired,
      });
      return;
    }
    axiosClient
      .post(URLConstant.Employee.CreateUserAccount, request, true)
      .then((response) => {
        if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
          const responseDataJson = JSON.parse(response.data);
          if (
            responseDataJson &&
            responseDataJson.status === DataConstant.API_STATUS.SUCCESS
          ) {
            notification.success({
              message: DataConstant.Save,
              description: `Registration completed successfully. You can now switch your role to Admin in ${request.whiteLabel}`,
            });
            navigate(`/dashboard`);
            window.location.reload();
          } else {
            notification.error({
              message: DataConstant.error,
              description: responseDataJson?.message,
            });
          }
        } else {
          notification.error({
            message: DataConstant.error,
            description: response?.message,
          });
        }
      });
  };

  return (
    <>
      <FusePageSimple
        header={
          <PageHeader
            title="Create business account"
            showSearch={false}
            description="Create business account"
            showAddButton={false}
          />
        }
        content={
          <Paper className="w-full">
            <form
              name="userForm"
              autoComplete="off"
              onSubmit={handleSubmit(onFinish)}
            >
              <input type="hidden" name="id" value={userid} />

              <Grid container spacing={2} className="px-16 mt-0">
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">First name</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <TextField
                    {...register('FirstName', {
                      required: MessageConstant.FirstNameRequired,
                      maxLength: {
                        value: 30,
                        message: MessageConstant.EnterMaximum30Char,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter first name"
                    error={!!errors.FirstName}
                    helperText={
                      errors.FirstName ? errors.FirstName.message : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">Last name</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <TextField
                    {...register('LastName', {
                      required: MessageConstant.LastNameRequired,
                      maxLength: {
                        value: 30,
                        message: MessageConstant.EnterMaximum30Char,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter last name"
                    error={!!errors.LastName}
                    helperText={errors.LastName ? errors.LastName.message : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16 ">Phone number</FormLabel>
                  <span className="text-[#ff0000] text-lg-start"> *</span>
                  <Controller
                      name="PhoneNumber"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `Phone number is required`,
                        },
                        minLength: {
                          value: 7,
                          message: 'Enter valid Phone number',
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          {...field}
                          inputProps={{
                            name: 'PhoneNumber',
                          }}
                          specialLabel=''
                          fullWidth
                          className={`mobile-full ${errors.PhoneNumber ? 'cm-error' : ''}`}
                          country={'us'}
                          countryCodeEditable={false}
                          autoFormat={true}
                          onChange={(value, data, event, formattedValue) => {
                            field.onChange(formattedValue);
                            setPhoneNum(formattedValue)
                          }}
                          enableSearch={true}
                          enableLongNumbers={true}
                        />
                      )}
                    />
                     {errors.PhoneNumber && (
                      <span style={{ color: '#f44336',marginLeft:'12px', fontSize:'12px'}}>
                        {errors.PhoneNumber.message}
                      </span>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel className="mt-16" sx={{zIndex: 0}}>Preferred subdomain</FormLabel>
                  <label>
                    <span className="text-[#ff0000] text-lg-start"> * </span>
                    <Tooltip title={DataConstant.whitelabelInfo} className="">
                      <span>
                        {' '}
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ mt: -0.8 }}
                          color="action"
                        />{' '}
                      </span>
                    </Tooltip>
                  </label>
                  <TextField
                    {...register('Whitelabel', {
                      required: MessageConstant.SubdomainRequired,
                      pattern: {
                        value: DataConstant.whitelabelRegex,
                        message: MessageConstant.EnterValidSubdomain,
                      },
                    })}
                    fullWidth
                    autoComplete="off"
                    placeholder="Enter preferred subdomain"
                    error={!!errors.Whitelabel}
                    helperText={
                      errors.Whitelabel ? errors.Whitelabel.message : ''
                    }
                    inputProps={{
                      onInput: (e) => {
                        const lowercasedValue = (
                          '' + e.target.value
                        ).toLowerCase();
                        setValue('Whitelabel', lowercasedValue);
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    className="page-ttl px-0"
                    sx={{
                      color: '#808080',
                      mt: 1,
                    }}
                  >
                    example : yourcompany.qrrelays.com
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className="flex items-center justify-between">
                    <label>
                      <b>*Start your account with a 14-day free trial</b>
                    </label>

                    <div className="flex items-center gap-10 justify-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="my-8 flex"
                        onClick={() => {
                          navigate(`/dashboard`);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="my-8 flex"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
        }
      />
    </>
  );
};

export default CreateAccount;
