import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { notification } from "antd";
import axiosClient from "app/configs/axiosClientConfig";
import React from "react";
import { useForm } from "react-hook-form";
import DataConstant from "src/app/constants/dataConstant";
import URLConstant from "src/app/constants/urlConstant";

const MailPopup = (props) => {
  const {
    qrCodeId,
    isMail,
    handleMailPopupCancel,
    fetchData,
    tableRequestParam,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleMailChange = (data) => {
    if (data) {
      const model = {
        Id: qrCodeId,
        IsMail: isMail,
        Email: data.Email,
      };

      axiosClient
        .post(URLConstant.QRCode.UpdateMailFlag, model)
        .then((response) => {
          fetchData(tableRequestParam);
          notification.success({
            message: DataConstant.Save,
            description: response.data,
          });
          handleMailPopupCancel();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Dialog
            open={props.isShow}
            onClose={handleMailPopupCancel}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle className="flex items-center justify-between p-24 mb-24 border-solid border-b-2">
              <Typography variant="h6"> Email </Typography>
              <IconButton aria-label="close" onClick={handleMailPopupCancel}>
                <CancelOutlinedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="p-24">
              <form
                //form={form}
                autoComplete="off"
                onSubmit={handleSubmit(handleMailChange)}
              >
                <Grid container spacing={12}>
                  <Grid item xs={12}>
                    <FormLabel>Email</FormLabel>
                    <TextField
                      fullWidth
                      id="Email"
                      name="Email"
                      className="w-full"
                      {...register("Email", {
                        required: "Email address is required. ",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Enter valid email address.",
                        },
                        maxLength: 50,
                      })}
                      error={!!errors.Email}
                      helperText={
                        errors.Email
                          ? errors.Email.message || "Please enter valid email address."
                          : ""
                      }
                       />
                  </Grid>
                </Grid>
                <DialogActions className="p-24 border-solid border-t-2">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => handleMailChange()}
                  >
                    Save
                  </Button>
                  <Button variant="contained" onClick={handleMailPopupCancel}>
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

export default MailPopup;
