import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Paper,
  Stack,
  Divider,
  Button,
} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { SketchPicker } from 'react-color';
import { GetFileComponent } from 'src/app/components/GetFileComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from 'app/configs/axiosClientConfig';
import URLConstant from 'src/app/constants/urlConstant';
import DataConstant from 'src/app/constants/dataConstant';
import { useForm } from 'react-hook-form';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import DownloadModel from './DownloadModel';

const base64ToFile = (base64Data, filename) => {
  const arr = base64Data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};

const CustomQRCard = () => {
  const { handleSubmit } = useForm({ mode: 'onChange' });
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add loading state for API submission
  const [openModal, setOpenModal] = useState(false);
  const [selectedName, setSelectedName] = useState(null);

  const [designInfo, setDesignInfo] = useState({
    id,
    bgColor: '#ffffff',
    fgColor: '#000000',
    logoSize: 50,
    logo: {
      selectedFile: '',
      selectedFileName: '',
      selectedImageUrl: '',
    },
    url: '',
  });

  const predefinedLogos = [
    { id: 1, src: require('../../assets/img/instagram.png'), alt: 'instagram' },
    { id: 2, src: require('../../assets/img/pinterest.png'), alt: 'pinterest' },
    { id: 3, src: require('../../assets/img/spotify.png'), alt: 'spotify' },
    { id: 4, src: require('../../assets/img/twitter.jpg'), alt: 'twitter' },
    { id: 5, src: require('../../assets/img/snapchat.png'), alt: 'snapchat' },
    { id: 6, src: require('../../assets/img/flickr.png'), alt: 'flickr' },
    { id: 7, src: require('../../assets/img/skype.png'), alt: 'skype' },
    {
      id: 8,
      src: require('../../assets/img/soundcloud.png'),
      alt: 'soundcloud',
    },
    { id: 9, src: require('../../assets/img/tiktok.png'), alt: 'tiktok' },
    { id: 10, src: require('../../assets/img/vimeo.png'), alt: 'vimeo' },
    { id: 11, src: require('../../assets/img/xing.png'), alt: 'xing' },
    { id: 12, src: require('../../assets/img/youtube.png'), alt: 'youtube' },
    { id: 13, src: require('../../assets/img/mobile.png'), alt: 'mobile' },
    { id: 14, src: require('../../assets/img/web.png'), alt: 'web' },
  ];

  useEffect(() => {
    if (id) {
      axiosClient
        .get(`${URLConstant.QRCode.GetQRCodeById}?QRCodeId=${id}`)
        .then((response) => {
          if (response.data) {
            const { qrCodeLogo, qrCodeBackground, qrCodeForeground, url } =
              response.data;
              setSelectedName(response?.data?.name)
            setDesignInfo({
              ...designInfo,
              id: response.data.id,
              bgColor: qrCodeBackground || '#ffffff',
              fgColor: qrCodeForeground || '#000000',
              logo: {
                selectedImageUrl: qrCodeLogo
                  ? `${URLConstant.Images.UpdateQRCodesImageLogoPath}/${id}/${qrCodeLogo}`
                  : '',
                selectedFileName: qrCodeLogo || '',
              },
              url: `${URLConstant.OriginUrl}/${response.data?.code || ''}`,
            });
          }
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const updateDesignInfo = (updates) => {
    const newDesignInfo = { ...designInfo, ...updates };
    setDesignInfo(newDesignInfo);
    submitDetails(newDesignInfo);
  };

  const closeModel = () => {
    setOpenModal(false);
  };

  // const submitDetails = (updatedDesignInfo) => {
  //   setIsSubmitting(true); // Set submitting to true when starting the API call
  //   const { logo } = updatedDesignInfo;
  //   const formData = new FormData();

  //   const modelToSubmit = {
  //     id,
  //     qrCodeForeground: updatedDesignInfo.fgColor,
  //     qrCodeBackground: updatedDesignInfo.bgColor,
  //     removeLogo: false,
  //   };

    // if (logo.selectedImageUrl.startsWith('data:image/')) {
    //   const file = base64ToFile(logo.selectedImageUrl, 'logo.png');
    //   formData.append('Logo', file);
    // } else if (logo.selectedFile) {
    //   formData.append('Logo', logo.selectedFile);
    // } else {
    //   modelToSubmit.removeLogo = true;
    // }

  //   formData.append('model', JSON.stringify(modelToSubmit));

  //   axiosClient.post(URLConstant.QRCode.UpdateQRCodesImage, formData, {
  //     headers: { 'Content-Type': 'multipart/form-data' },
  //   }).then((response) => {
  //     const message = response.message || '';
  //     const notificationType = response.status === DataConstant.API_STATUS.SUCCESS ? 'success' :
  //                               response.status === DataConstant.API_STATUS.WARNING ? 'warning' : 'error';
  //     notification[notificationType]({ message: DataConstant.Update, description: message });
  //     setIsSubmitting(false);
  //   }).catch((error) => {
  //     console.error(error);
  //     notification.error({ message: 'Error', description: 'There was an error updating the QR code.' });
  //     setIsSubmitting(false); // Reset submitting state even on error
  //   });
  // };

  const submitDetails = async (updatedDesignInfo) => {
    setIsSubmitting(true); // Set submitting to true when starting the API call
    const { logo } = updatedDesignInfo;
    const formData = new FormData();

    const modelToSubmit = {
      id,
      qrCodeForeground: updatedDesignInfo.fgColor,
      qrCodeBackground: updatedDesignInfo.bgColor,
      removeLogo: false,
    };

    // Helper function to convert a URL to a File object
    const urlToFile = async (url, filename) => {
      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], filename, { type: blob.type });
    };

    if (logo?.selectedImageUrl?.startsWith('data:image/')) {
      const file = base64ToFile(logo.selectedImageUrl, 'logo.png');
      formData.append('Logo', file);
    } else if (logo.selectedFile) {
      formData.append('Logo', logo.selectedFile);
    }
    else if (
      logo?.selectedImageUrl &&
      logo?.selectedImageUrl?.includes('/static/media/')
    ) {
      // Convert static media URL to File object
      const file = await urlToFile(logo?.selectedImageUrl, 'logo.png'); // Use a relevant filename
      formData.append('Logo', file);
    } 
    else if(logo?.selectedImageUrl === "" ) {
      modelToSubmit.removeLogo = true;
    }


    // if (logo?.selectedImageUrl?.startsWith('data:image/')) {
    //   const file = base64ToFile(logo.selectedImageUrl, 'logo.png');
    //   formData.append('Logo', file);
    // } else if (logo?.selectedFile) {
    //   formData.append('Logo', logo?.selectedFile);
    // } else if (
    //   logo?.selectedImageUrl &&
    //   logo?.selectedImageUrl?.includes('/static/media/')
    // ) {
    //   // Convert static media URL to File object
    //   const file = await urlToFile(logo?.selectedImageUrl, 'logo.png'); // Use a relevant filename
    //   formData.append('Logo', file);
    // } else {
    //   modelToSubmit.removeLogo = true;
    // }

    formData.append('model', JSON.stringify(modelToSubmit));

    try {
      const response = await axiosClient.post(
        URLConstant.QRCode.UpdateQRCodesImage,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      const message = response.message || '';
      const notificationType =
        response.status === DataConstant.API_STATUS.SUCCESS
          ? 'success'
          : response.status === DataConstant.API_STATUS.WARNING
          ? 'warning'
          : 'error';
      notification[notificationType]({
        message: DataConstant.Update,
        description: message,
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: 'There was an error updating the QR code.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDownloadClick = () => {
    setOpenModal(true);
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(submitDetails)}
      >
        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 w-full items-center justify-between py-16 md:py-32 sm:px-12">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-24 md:text-32 font-extrabold tracking-tight"
          >
            Design QR Code
          </Typography>
        </div>
        <Paper className="py-12">
          <Grid container spacing={2} sx={{ marginLeft: '10px' }}>
            <Grid item xs={8}>
              <div className="design_QRcode">
                <Typography variant="h6">Logo</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    marginTop: '2px',
                  }}
                >
                  {predefinedLogos.map((logoItem) => (
                    <img
                      key={logoItem.id}
                      src={logoItem.src}
                      alt={logoItem.alt}
                      style={{ width: '50px', cursor: 'pointer' }}
                      onClick={() =>
                        updateDesignInfo({
                          logo: {
                            selectedImageUrl: logoItem.src,
                            selectedFileName: '',
                          },
                        })
                      }
                    />
                  ))}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    marginBottom: 3,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    gap: 3,
                    marginTop: '20px',
                  }}
                >
                  <Typography variant="body1" sx={{ minWidth: '100px' }}>
                    Upload your own logo here:
                  </Typography>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={
                        designInfo?.logo?.selectedImageUrl ||
                        require('../../../app/assets/img/no-img.png')
                      }
                      alt="Profile"
                      style={{
                        borderRadius: '50%',
                        width: '80px',
                        height: '80px',
                        objectFit: 'cover',
                      }}
                    />
                    {designInfo?.logo?.selectedImageUrl && (
                      <span
                        onClick={() =>
                          updateDesignInfo({
                            logo: {
                              selectedFile: '',
                              selectedImageUrl: '',
                              selectedFileName: '',
                            },
                          })
                        }
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          cursor: 'pointer',
                          backgroundColor: '#f44336',
                          borderRadius: '50%',
                          padding: '5px',
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '30px',
                        }}
                      >
                        &#10006;
                      </span>
                    )}
                  </div>

                  <Divider sx={{ marginBottom: '20px' }} />
                  <GetFileComponent
                    allowCrop={true}
                    name={'profilePic'}
                    callback={(res) =>
                      updateDesignInfo({
                        logo: {
                          selectedFile: res.file,
                          selectedFileName: res.file?.name || '',
                          selectedImageUrl: res.file
                            ? URL.createObjectURL(res.file)
                            : '',
                        },
                      })
                    }
                    buttonNameId="Upload"
                    moduleName="QR Code"
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ minWidth: '100px', marginBottom: '10px', color: 'red' }}
                >
                  Note : Supported formats JPG,JPEG,PNG
                </Typography>
                <Divider />
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      marginBottom: '20px',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography>Background</Typography>
                    <SketchPicker
                      color={designInfo.bgColor}
                      onChangeComplete={(color) =>
                        updateDesignInfo({ bgColor: color.hex })
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      marginBottom: '20px',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography>Foreground</Typography>
                    <SketchPicker
                      color={designInfo.fgColor}
                      onChangeComplete={(color) =>
                        updateDesignInfo({ fgColor: color.hex })
                      }
                    />
                  </Box>
                </Stack>
              </div>
            </Grid>
            <Grid item xs={4}>
              <>
                {isSubmitting ? (
                  <CircularProgress
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '30%',
                      marginLeft: '100px',
                    }}
                  />
                ) : (
                  <>
                    <QRCodeCanvas
                      ref={qrRef}
                      id="qrCode"
                      value={designInfo.url}
                      size={250}
                      bgColor={designInfo.bgColor}
                      fgColor={designInfo.fgColor}
                      level="H"
                      includeMargin={true}
                      marginSize={3}
                      imageSettings={{
                        src: designInfo.logo.selectedImageUrl,
                        excavate: true,
                        height: 40,
                        width: 40,
                      }}
                    />

                    <Button
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '50px',
                        marginTop: '25px',
                      }}
                      variant="contained"
                      color="primary"
                      startIcon={
                        <FuseSvgIcon size={24} color="action">
                          heroicons-outline:download
                        </FuseSvgIcon>
                      }
                      onClick={() => {
                        onDownloadClick();
                      }}
                    >
                      Download
                    </Button>
                  </>
                )}
              </>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <DownloadModel
        id={id}
        open={openModal}
        closeModel={closeModel}
        name={selectedName}
      />
    </>
  );
};

export default CustomQRCard;
