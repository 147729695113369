import axios from 'axios';
import store from '../store';
import { login } from '../auth/oidcConfig';

const axiosClient = axios.create({
  timeout: 240000,
  headers: { 'Content-Type': 'application/json' },
});

axiosClient.interceptors.request.use(
  async (config) => {
    const userData = store.getState()?.user;


    const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;
    
    config.headers.Authorization = `Bearer ${loginDetail?.access_token}`;
    config.headers.LoginSubscription = `${loginDetail?.profile?.subscription}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response?.data ? response?.data : response;
  },
  (error) => {
    if (error.response.status === 401) {
      login();
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
