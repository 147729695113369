import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { darken } from "@mui/material/styles";
import Box from "@mui/material/Box";
import URLConstant from "src/app/constants/urlConstant";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import clsx from "clsx";
import { motion } from "framer-motion";
import ModernPricingCard from "./ModernPricingCard";
import axiosClient from "../../configs/axiosClientConfig";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import _ from "lodash";
import moment from "moment";
import DataConstant from "src/app/constants/dataConstant";
import FuseLoading from "@fuse/core/FuseLoading";

const UpgradePackage = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [getYearlyData, setYearlyData] = useState({
    id: "",
    name: "",
    unit: "",
    price: "",
    description: "",
  });
  const [getQuaterlyData, setQuaterlyData] = useState({
    name: "",
    unit: "",
    price: "",
    description: "",
  });
  const [period, setPeriod] = useState(DataConstant.PlanDetail.Qauter);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 100 },
    show: { opacity: 1, y: 0 },
  };

  const userData = useSelector(selectUser);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  useEffect(() => {
    if (userData && period) {
      if (
        (_.lowerCase(userData?.InvoiceInfo?.InvoiceType) === _.toLower(DataConstant.PlanDetail.Year) ||
        _.lowerCase(userData?.InvoiceInfo?.InvoiceType) === _.lowerCase(period)) &&
        _.lowerCase(userData?.InvoiceInfo?.PackageStatus) === "active" ) 
      {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }
  }, [userData, period]);

  useEffect(() => {
    getSubscriptionList();
  }, []);

  const getSubscriptionList = () => {
    axiosClient
      .get(`${URLConstant.Subscription.GetSubscriptionItemList}`)
      .then((response) => {
        const subscriptionList = response.data;
        const yearlyData = subscriptionList.find(
          (user) => user.length === DataConstant.PlanDetail.MonthLengthInYearly
        );
        if (yearlyData)
          setYearlyData({
            id: yearlyData.id,
            name: yearlyData.name,
            unit: yearlyData.unit,
            price: yearlyData.price.toFixed(2),
            description: yearlyData.desciption,
          });
        const quaterlyData = subscriptionList.find(
          (user) =>
            user.length === DataConstant.PlanDetail.MonthLengthInQuarterly
        );
        if (quaterlyData)
          setQuaterlyData({
            id: quaterlyData.id,
            name: quaterlyData.name,
            unit: quaterlyData.unit,
            price: quaterlyData.price.toFixed(2),
            description: quaterlyData.desciption,
          });
        setIsLoading(false);
      });
  };

  if (IsLoading) {
    return <FuseLoading />;
  }

  return (
    <>
      <div className="relative flex flex-col flex-auto min-w-0 overflow-hidden">
        <div className="relative pb-48 sm:pb-96 sm:px-64 overflow-hidden">
          <svg
            className="-z-1 absolute inset-0 pointer-events-none"
            viewBox="0 0 960 540"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMax slice"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Box
              component="g"
              sx={{ color: "divider" }}
              className="opacity-20"
              fill="none"
              stroke="currentColor"
              strokeWidth="100"
            >
              <circle r="234" cx="196" cy="23" />
              <circle r="234" cx="790" cy="491" />
            </Box>
          </svg>
          <div className="flex flex-col items-center">
            {/* <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.05 } }}
            >
              <h2 className="text-xl font-semibold">PRICING</h2>
            </motion.div> */}

            {/* <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.1 } }}
            >
              <div className="mt-4 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
                Take control of your productivity
              </div>
            </motion.div> */}

            {/* <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.15 } }}
            >
              <Typography
                className="mt-12 sm:text-2xl text-center tracking-tight"
                color="text.secondary"
              >
                Start small and free, upgrade as you go.
                <br />
                Take control of everything.
              </Typography>
            </motion.div> */}

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <Box
                className="flex items-center mt-32 sm:mt-64 p-2 rounded-full overflow-hidden"
                sx={{
                  backgroundColor: (theme) =>
                    darken(theme.palette.background.default, 0.05),
                }}
              >
                <Box
                  component="button"
                  className={clsx(
                    "h-40 items-center px-16 cursor-pointer rounded-full font-medium",
                    _.toLower(period) ===
                      _.toLower(DataConstant.PlanDetail.Year) && "shadow"
                  )}
                  onClick={() => setPeriod(DataConstant.PlanDetail.Year)}
                  sx={{
                    backgroundColor:
                      _.toLower(period) ===
                      _.toLower(DataConstant.PlanDetail.Year)
                        ? "background.paper"
                        : "",
                  }}
                  type="button"
                >
                  {DataConstant.PlanDetail.YearlyBilling}
                </Box>

                <Box
                  component="button"
                  className={clsx(
                    "h-40 items-center px-16 cursor-pointer rounded-full font-medium",
                    _.toLower(period) ===
                      _.toLower(DataConstant.PlanDetail.Qauter) && "shadow"
                  )}
                  onClick={() => setPeriod(DataConstant.PlanDetail.Qauter)}
                  sx={{
                    backgroundColor:
                      _.toLower(period) ===
                      _.toLower(DataConstant.PlanDetail.Qauter)
                        ? "background.paper"
                        : "",
                  }}
                  type="button"
                >
                  {DataConstant.PlanDetail.QuaterlyBilling}
                </Box>
              </Box>
            </motion.div>
          </div>

          <div className="flex justify-center mt-40 sm:mt-80">
            <motion.div variants={container} initial="hidden" animate="show">
              <motion.div variants={item}>
                <ModernPricingCard
                  yearlyId={getYearlyData.id}
                  quaterId={getQuaterlyData.id}
                  period={period}
                  yearlyTitle={getYearlyData.name}
                  quaterlyTitle={getQuaterlyData.name}
                  subtitle={
                    _.toLower(period) ===
                    _.toLower(DataConstant.PlanDetail.Qauter)
                      ? `${getQuaterlyData.description}`
                      : `${getYearlyData.description}`
                  }
                  yearlyPrice={`$${getYearlyData.price}`}
                  quaterlyPrice={`$${getQuaterlyData.price}`}
                  buttonTitle={
                    _.toLower(period) ===
                    _.toLower(DataConstant.PlanDetail.Qauter)
                      ? DataConstant.PlanDetail.QauterlyPlanName
                      : DataConstant.PlanDetail.YearlyPlanName
                  }
                  isBtnDisable={isBtnDisable}
                  details={
                    <div className="flex flex-col mt-48">
                      <Typography className="font-semibold">
                        Personal features, plus:
                      </Typography>
                      <div className="mt-16 space-y-8">
                        <div className="flex">
                          <FuseSvgIcon className="text-green-600" size={20}>
                            heroicons-solid:check
                          </FuseSvgIcon>
                          <Typography className="ml-2 leading-5">
                            <b>Unlimited</b> projects
                          </Typography>
                        </div>
                        <div className="flex">
                          <FuseSvgIcon className="text-green-600" size={20}>
                            heroicons-solid:check
                          </FuseSvgIcon>
                          <Typography className="ml-2 leading-5">
                            <b>Unlimited</b> storage
                          </Typography>
                        </div>
                        <div className="flex">
                          <FuseSvgIcon className="text-green-600" size={20}>
                            heroicons-solid:check
                          </FuseSvgIcon>
                          <Typography className="ml-2 leading-5">
                            Custom domains
                          </Typography>
                        </div>
                        <div className="flex">
                          <FuseSvgIcon className="text-green-600" size={20}>
                            heroicons-solid:check
                          </FuseSvgIcon>
                          <Typography className="ml-2 leading-5">
                            Bulk editing
                          </Typography>
                        </div>
                        <div className="flex">
                          <FuseSvgIcon className="text-green-600" size={20}>
                            heroicons-solid:check
                          </FuseSvgIcon>
                          <Typography className="ml-2 leading-5">
                            12 / 5 support
                          </Typography>
                        </div>
                      </div>
                    </div>
                  }
                  isPopular={false}
                  isActive={_.lowerCase(userData?.InvoiceInfo?.InvoiceType) === _.lowerCase(period)}
                />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpgradePackage;
