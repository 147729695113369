import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosClient from '../../configs/axiosClientConfig';
import URLConstant from 'src/app/constants/urlConstant';
import DataConstant from 'src/app/constants/dataConstant';
import VCardPreview from '../qrCode/VCardPreview';

const BusinessCardPreviewUrl = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const [userInfo, setUserInfo] = useState({
    id: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    phone: '',
    fax: '',
    email: '',
    company: '',
    yourJob: '',
    address: '',
    website: '',
    summary: '',
    sharepage: false,
    socialLinks: [],
    buttonColor: '#64b5f6',
    primaryColor: '#0288d1',
    useGradient: false,
    gradientColor: '#ff0000',
    splashScreenColor:'#f5f5f5',
    code: '',
    profilePic: {
      selectedFile: '',
      selectedFileName: '',
      selectedImageUrl: '',
    },
    backGroundImage: {
      selectedFile: '',
      selectedFileName: '',
      selectedImageUrl: '',
    },
    url: '',
  });

  const fetchData = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetQRCodeById}?QRCodeId=${id}`)
      .then((response) => {
        if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
          const data = response.data;

          const updatedProfilePic = data.logo
            ? URLConstant.Images.UserBusinessCardRootPath +
              '/' +
              data.id +
              '/' +
              data.logo
            : ''; // Default to an empty string if logo is not available

          const updatedBackGroundImage = data.bgImage
            ? URLConstant.Images.UserBusinessCardRootPath +
              '/' +
              data.id +
              '/' +
              data.bgImage
            : ''; // Default placeholder image if bgImage is not available
          setUserInfo({
            id: data.id,
            firstName: data?.firstName,
            lastName: data?.lastName,
            mobileNo: data?.mobileNo,
            phone: data?.phone,
            fax: data.fax,
            email: data.email,
            company: data.company,
            yourJob: data.yourJob,
            address: data.address,
            website: data.website,
            summary: data.summary,
            sharepage: data.sharepage,
            socialLinks: data.socialLinks ? JSON.parse(data.socialLinks) : {},
            code: data.code,
            name: data.name,
            buttonColor: data.buttoncolor,
            primaryColor: data.textcolor,
            useGradient: data.useGradient,
            gradientColor: data.gradientColor,
            splashScreenColor: data.splashScreenColor,
            url: data.url,
            profilePic: {
              selectedImageUrl: updatedProfilePic, // Use updatedProfilePic
              selectedFileName: data.logo || '', // Set filename or null
            },
            backGroundImage: {
              selectedImageUrl: updatedBackGroundImage, // Use updatedBackGroundImage
              selectedFileName: data.bgImage || '', // Set filename or null
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {userInfo && userInfo?.id !=='' && (
        <div className="cm-web-preview-wrapper">
          <VCardPreview
            userInfo={userInfo}
            isPreview={false}
            setLoading={setLoading}
            loading={loading}
          />
        </div>
      )}
    </>
  );
};

export default BusinessCardPreviewUrl;
