import SocialPreviewUrl from './SocialPreviewUrl';
import ShortUrlReader from './ShortUrlReader';
import BusinessCardPreviewUrl from './BusinessCardPreviewUrl';

const SignInConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: '/:shortUrl',
            element: <ShortUrlReader />,
        },
        {
            path: '/social/:id/:shortUrl',
            element: <SocialPreviewUrl />,
        },
        {
            path: '/businessCard/:id/:shortUrl',
            element: <BusinessCardPreviewUrl />,
        },
    ],
};

export default SignInConfig;
