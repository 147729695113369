import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
  } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import URLConstant from "src/app/constants/urlConstant";
import { Empty } from "antd";
import DataConstant from "src/app/constants/dataConstant";
import axiosClient from "app/configs/axiosClientConfig";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";

  
  const SwitchRole = (props) => {
    const { show, onHide } = props;
    const radioGroupRef = useRef(null);
    const [userRoles, setUserRoles] = useState([]);
    const [empty, setEmpty] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(selectUser);
    const [roleUrl, setRoleUrl] = useState("");
    
    const [tableRequestParam, setTableRequestParam] = useState(
      JSON.parse(JSON.stringify(DataConstant.tableRequest))
    );
    const [selectedRole, setSelectedRole] = useState("");
    
    const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

    useEffect(() => {
      fetchData(tableRequestParam);
    }, [JSON.stringify(tableRequestParam)]);

    const fetchData = (requestObject) => {
      axiosClient
        .post(URLConstant.Employee.GetUserRoles, requestObject)
        .then((response) => {
          if(response.data && response.data.userList.list && response.data.userList.list.length > 0){
            setUserRoles(response.data.userList.list);
            setRoleUrl(response.data.baseUrl);
            if(loginDetail?.profile?.subscription){
              setSelectedRole(loginDetail?.profile?.subscription)
            }
            setEmpty(false);
          }
          else{
            setEmpty(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };

    const handleChange = (val) => {
      const selectedValue = val.target.value;
      setSelectedRole(selectedValue);
    };

    const switchUserRole = () => {
      const role = userRoles.find(item => item.subscriptionId === selectedRole);
      const userLoginRole = {
        id : loginDetail?.profile?.userid,
        userRole : role ? role.userRole : loginDetail?.profile?.userRole,
        subscriptionId : selectedRole
      }
      //localStorage.setItem("userLoginRole", JSON.stringify(userLoginRole));
      axiosClient
        .post(URLConstant.Employee.AddUpdateUserLastLogin, userLoginRole)
        .then((response) => {
          if(response){
            const url = roleUrl.replace("{0}",role.whiteLabel)
            window.location.href = `${url}switchresponse/${userLoginRole.userRole}/${userLoginRole.id}/${userLoginRole.subscriptionId}/${role.whiteLabel}`
            window.location.reload;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return (
      <>
        <Dialog open={show} onClose={onHide} maxWidth="xs" fullWidth>
          <DialogTitle className="flex items-center justify-between border-solid border-b-2">
            <Grid item xs={12}>
              <div className="flex">
                <Typography variant="h6" component="h6">
                  Switch role
                </Typography>
              </div>
            </Grid>
            <IconButton aria-label="close" onClick={onHide}>
              <CancelOutlined color="primary" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            { isLoading ? (
              <div className="flex items-center justify-center p-24">
                <CircularProgress/>
              </div>
            ):(
              empty ? (
                  <Empty className="cm-web-preview-wrapper"/>
                  )
                :(
                  <>
                  <Grid container direction="row" alignItems="center" style={{width:"322px",paddingBottom:"14px"}}>
                    <Grid item xs={6} style={{paddingLeft:"40px"}}>
                      <b>Role name</b>
                    </Grid>
                    <Grid item xs={6}>
                      <b>Company name</b>
                    </Grid> 
                  </Grid>
                  <RadioGroup
                    ref={radioGroupRef}
                    className="flex-col"
                    aria-label="userroles"
                    name="userroles"
                    defaultValue={selectedRole}
                    //key={selectedRole}
                    onChange={handleChange}
                  >
                    {userRoles.map((option) => (
                      <FormControlLabel
                        value={option.subscriptionId}
                        key={option.subscriptionId}
                        control={<Radio/>}
                        label={
                          (
                          <Grid container direction="row" alignItems="center" style={{width:"266px",padding:"10px"}}>
                            <Grid item xs={6}>
                              <Chip label={option.userRole} 
                                    color={option.userRole =="Admin" ? "success": "primary"}/>  
                            </Grid>
                            <Grid item xs={6} className="capitalize">
                              {option.whiteLabel}
                            </Grid> 
                          </Grid>
                          )
                        }
                      />
                    ))}
                  </RadioGroup>
                  </>
                ))
            }
          </DialogContent>
          <DialogActions className="border-solid border-t-2 px-24 py-12">
            <Button variant="outlined" color="primary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={switchUserRole}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  export default SwitchRole;