import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import URLConstant from "src/app/constants/urlConstant";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import PageHeader from "../../components/pageHeader";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import ChartComponent from "../ChartComponent/ChartComponent ";
import DataConstant from "src/app/constants/dataConstant";
import BoxComponent from "../ChartComponent/BoxComponent";
import { motion } from "framer-motion";
import { DatePicker } from "@mui/x-date-pickers";
import FuseLoading from "@fuse/core/FuseLoading";
import axiosClient from "app/configs/axiosClientConfig";
import moment from 'moment';

function DashboardPage() {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const user = useSelector(selectUser);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [chartData, setChartData] = useState([]);

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  useEffect(() => {
    if (
      loginDetail === null ||
      loginDetail === "" ||
      loginDetail === undefined
    ) {
      navigate("/auth/login");
    }
  }, [loginDetail]);

  useEffect(() => {
    if(loginDetail){
      const cDate = new Date();
      const pDate = new Date();
      pDate.setDate(cDate.getDate() - 30);
      setFromDate(pDate);
      setToDate(cDate);
      const fdate = moment(pDate).format(DataConstant.dateFormats.YYYYMMDD);
      const tdate = moment(cDate).format(DataConstant.dateFormats.YYYYMMDD);
      GetDashboardChartData(fdate, tdate);
    }
  }, []);

  const GetDashboardChartData = (from, to) => {
    setIsLoading(true);
    const chartRequest = {
      SubscriptionId : loginDetail.profile.subscription,
      FromDate : from,
      ToDate : to
    }

    axiosClient
      .post(URLConstant.Dashboard.GetDashboardChartData, chartRequest)
      .then((response) => {
        if(response != null && response.status == "Success"){
          setChartData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  const ApplyDateFilter = () => {
    const fdate = moment(fromDate).format(DataConstant.dateFormats.YYYYMMDD);
    const tdate = moment(toDate).format(DataConstant.dateFormats.YYYYMMDD);
    GetDashboardChartData(fdate, tdate);
  }

  if (IsLoading) {
    return <FuseLoading />;
  }
  return (
    <>
      <FusePageSimple
        header={
          <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 w-full items-center justify-between py-16 md:py-32 sm:px-12">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-24 md:text-32 font-extrabold tracking-tight"
            >
              Dashboard
            </Typography>
            <div className="flex flex-col w-full sm:w-auto sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center justify-end space-x-8">
              <div>
                <DatePicker
                  onChange={(e)=> {setFromDate(e)}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="From"
                    />
                  )}
                  value={fromDate}
                />
              </div>
              <div>
                <DatePicker
                  onChange={(e)=> {setToDate(e)}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="To"
                    />
                  )}
                  value={toDate}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className=""
                  onClick={ApplyDateFilter}
                > Apply
                </Button>
              </div>
            </div>
          </div>
        }
        content={
          <Grid container className="sm:px-12 pb-12" spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_TOP10QRCODE} 
                  ChartType={DataConstant.ChartDetail.Type.Donut}
                  Data={chartData}
                  Height={DataConstant.ChartDetail.Height}
                  ShowDetail={false}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_DATEWISE} 
                  ChartType={DataConstant.ChartDetail.Type.Area}
                  Data={chartData}
                  Height={DataConstant.ChartDetail.Height}
                  ShowDetail={false}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_TYPEWISETOP5} 
                  ChartType={DataConstant.ChartDetail.Type.Donut}
                  Data={chartData}
                  Height={220}
                  ShowDetail={true}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_DEVICEWISE} 
                  ChartType={DataConstant.ChartDetail.Type.Bar}
                  Data={chartData} 
                  Height={DataConstant.ChartDetail.Height}
                  ShowDetail={false}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_TAGWISE} 
                  ChartType={DataConstant.ChartDetail.Type.Donut}
                  Data={chartData} 
                  Height={DataConstant.ChartDetail.Height}
                  ShowDetail={false}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="h-full chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<ChartComponent 
                  Category={DataConstant.ChartDetail.Category.CHART_CITYWISE} 
                  ChartType={DataConstant.ChartDetail.Type.Donut}
                  Data={chartData} 
                  Height={DataConstant.ChartDetail.Height}
                  ShowDetail={false}
                />}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className="chart-box">
                {/* <p className="py-8 px-12"></p> */}
                {<BoxComponent 
                  data={chartData}
                  title={DataConstant.ChartDetail.Title.TypeWiseTotalScanCount}
                />}
              </Paper>
            </Grid>
          </Grid>
        }
      />
    </>
  );
}

export default DashboardPage;
