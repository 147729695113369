import { useEffect, useState } from "react";
import {
  Button,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import axiosClient from "app/configs/axiosClientConfig";
import { useForm } from "react-hook-form";
import URLConstant from "src/app/constants/urlConstant";
import { notification } from "antd";
import DataConstant from "src/app/constants/dataConstant";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import routes from "app/configs/routesConfig";
import MessageConstant from "src/app/constants/messageConstant";

const Url = (props) => {
  const user = useSelector(selectUser);
  var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = props;
  const [urlState, setURLState] = useState("Static");
  const [shorthandUrl, setShorthandUrl] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm();

  useEffect(() => {
    if (data) {
      setURLState(data.subTypes ?? "Static");
      setShorthandUrl(data.code);
      setValue("name", data.name);
      setValue("url", data.url);
      setValue("code", data.code);
    }
    else {
      GetShortHandUrl();
    } 
  }, [data]);

  const redirectToQRCode = () => {
    navigate(`/qrcodes`);
  };

  const GetShortHandUrl = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetShortHandUrl}`)
      .then((response) => {
        if (response) {
          setShorthandUrl(response.data.qrCode);
          setValue("code", response.data.qrCode);
        }
      });
  };

  const isMatchWithRoute = (value) => {
    if(!value) return false;
    const allPaths = routes.map(route => route.path);
    
    const isMatch = allPaths.some(route => {
      const segments = route.split("/");
      const initial = segments[0] || segments[1];
      if(value?.toLowerCase() === initial?.toLowerCase() || value?.toLowerCase() === segments[1]?.toLowerCase()){
        return true;
      }
    });
    return isMatch;
  }

  const onFinish = (qrCodeDetail) => {
    
    const code = qrCodeDetail?.code;

    if (!qrCodeDetail?.name || qrCodeDetail?.name.trim() == '') {
      notification.error({
          message: DataConstant.error,
          description: MessageConstant.NameRequired,
      });
      return;
    }
    if (!qrCodeDetail?.url || qrCodeDetail?.url.trim() == '') {
      notification.error({
          message: DataConstant.error,
          description: MessageConstant.UrlRequired,
      });
      return;
    }

    if(isMatchWithRoute(code)){
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.CodeRestricted,
      });
      return;
    }
    
    const modelToSubmit = {
      id: id ? id : "",
      Name: qrCodeDetail?.name.trim(),
      Type: DataConstant.qrcodeType.url,
      SubTypes: urlState,
      Url: qrCodeDetail?.url.trim(),
      code: urlState === "Dynamic" ? qrCodeDetail?.code : null,
      SubscriptionCurrentPaymentType : subscriptionPlanType
    };

    if(!modelToSubmit?.Url.includes('https://') && !modelToSubmit?.Url.includes('http://')){
      modelToSubmit.Url = 'https://' + qrCodeDetail?.url.trim()
    }

    const requestURL = id
      ? URLConstant.QRCode.UpdateUrlQRCode
      : URLConstant.QRCode.AddQRCodes;
    axiosClient.post(requestURL, modelToSubmit).then((response) => {
      if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)) {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        redirectToQRCode();
      }else if(response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)){
        notification.warning({
          message: DataConstant.Warning,
          description: response?.message,
        });
      } else {
        notification.error({
          message: DataConstant.error,
          description: response?.message,
        });
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setURLState(value);
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onFinish)}
      >
        <div className="px-16">
          <FormLabel className="mt-16 ">Name</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="name"
            name="name"
            className="w-full my-8"
            placeholder="QRcode name"
            {...register("name", {
              required: "Please enter name",
              maxLength: {
                value: 60,
                message: MessageConstant.EnterMaximum60Char,
              },
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <FormLabel className="mt-16 ">URL</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <TextField
            id="url"
            name="url"
            maxLength="50"
            className="w-full my-8"
            placeholder="https://www.google.com"
            {...register("url", {
              required: "Please enter url",
            })}
            error={!!errors.url}
            helperText={errors.url?.message}
          />

          {urlState === "Dynamic" && (
            <div>
              <FormLabel className="mt-16 ">Shorthand URL</FormLabel>
              <span className="text-[#ff0000] text-lg-start"> *</span>
              <div className="flex">
                <TextField
                  value={URLConstant.OriginUrl}
                  maxLength="50"
                  className="w-1/4 my-8"
                  disabled
                />
                <TextField
                  id="shorthandUrl"
                  name="shorthandUrl"
                  maxLength="50"
                  className="w-3/4 my-8"
                  placeholder="URL code"
                  {...register("code",{
                    required: MessageConstant.EnterCode,
                    pattern: {
                      value: DataConstant.urlCodeRegex,
                      message: DataConstant.urlCodeMessage,
                    }
                  })}
                  error={!!errors.code}
                  helperText={
                    <span className="absolute">{errors.code?.message}</span>
                  }
                  disabled={data != null && data != ""}
                />
              </div>
              <div>
                <label className="url-preview"><span>Preview:</span><br/> {URLConstant.OriginUrl}/{watch("code")}</label>
              </div>
            </div>
          )}
          <FormLabel id="demo-radio-buttons-group-label">QR code</FormLabel>
          <span className="text-[#ff0000] text-lg-start"> *</span>
          <RadioGroup
            key={urlState}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={urlState ?? "Static"}
            name="subType"
            onChange={handleInputChange}
          >
            <FormControlLabel
              name="subType"
              value="Dynamic"
              control={<Radio />}
              label="Dynamic"
              disabled={data != null && data != ""}
            />
            <FormControlLabel
              name="subType"
              value="Static"
              control={<Radio />}
              label="Static"
              disabled={data != null && data != ""}
            />
          </RadioGroup>

          <Typography
            variant="body2"
            component="p"
            className="inline-block align-bottom py-10"
          >
            Note : Static QR code will not track your QR code scan.
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="my-8 flex"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
export default Url;
