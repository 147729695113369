import { useState, useEffect } from "react";
import { Button, FormLabel, TextField } from "@mui/material";
import axiosClient from "app/configs/axiosClientConfig";
import { useForm } from "react-hook-form";
import URLConstant from "src/app/constants/urlConstant";
import { notification } from "antd";
import DataConstant from "src/app/constants/dataConstant";
import { useNavigate, useParams } from "react-router-dom";
import { MuiFileInput } from "mui-file-input";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import MessageConstant from "src/app/constants/messageConstant";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import routes from "app/configs/routesConfig";

const PDFPage = (props) => {
  const user = useSelector(selectUser);
  var subscriptionPlanType = user?.InvoiceInfo?.InvoiceType;

  const { id } = useParams();
  const { data } = props;
  const [image, setImage] = useState(null);
  const [shorthandUrl, setShorthandUrl] = useState("");
  const [pdfName, setPdfName] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch
  } = useForm();

  useEffect(() => {
    if (id) {
      setShorthandUrl(data.code);
      setValue("name", data.name);
      if (data.pdfName) {
        setPdfName(data.pdfName);
      } else {
        setValue("url", data.url);
      }
      setValue("code", data.code);
    }
    else {
      GetShortHandUrl();
    }
  }, []);

  const redirectToQRCode = () => {
    navigate(`/qrcodes`);
  };

  const GetShortHandUrl = () => {
    axiosClient
      .get(`${URLConstant.QRCode.GetShortHandUrl}`)
      .then((response) => {
        if (response) {
          setShorthandUrl(response.data.qrCode);
          setValue("code", response.data.qrCode);
        }
      });
  };

  const isMatchWithRoute = (value) => {
    if(!value) return false;
    const allPaths = routes.map(route => route.path);
    
    const isMatch = allPaths.some(route => {
      const segments = route.split("/");
      const initial = segments[0] || segments[1];
      if(value?.toLowerCase() === initial?.toLowerCase() || value?.toLowerCase() === segments[1]?.toLowerCase()){
        return true;
      }
    });
    return isMatch;
  }

  /*
  * Set Image on change of File control
  * Also set name in pdfName state
  */
  const handleChange = (newFile) => {
    const fileExtension = `.${newFile.name.split(".").pop()}`;
    if (fileExtension === ".pdf") {
      if (newFile.size > DataConstant.pdfFileSize) {
        notification.warning({
          message: DataConstant.Warning,
          description: MessageConstant.PdfValidationSize,
        });
        return;
      }
      else {
        setImage(newFile);
        newFile ? setPdfName(newFile.name) : setPdfName(null);
      }
    }
    else {
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.PdfValidationType,
      });
    }
  };

  const onFinish = (qrCodeDetail) => {
    const modelToSubmit = {
      id: id ? id : "",
      Name: qrCodeDetail?.name?.trim(),
      Type: DataConstant.qrcodeType.pdf,
      code: qrCodeDetail?.code,
      PDFName: "",
      Url: "",
      SubscriptionCurrentPaymentType: subscriptionPlanType
    };

    if (!modelToSubmit.Name || modelToSubmit.Name.trim() == '') {
      notification.error({
          message: DataConstant.error,
          description: MessageConstant.NameRequired,
      });
      return;
    }

    const formdata = new FormData();
    if (pdfName) {
      modelToSubmit.PDFName = pdfName;
      formdata.append("file", image);
    } else {
      modelToSubmit.Url = qrCodeDetail.url;
    }

    if (modelToSubmit.PDFName == "" && modelToSubmit.Url == "") {
      notification.error({
        message: "Required",
        description: MessageConstant.UploadPdfOrPasteUrl,
      });

      return;
    }

    if(isMatchWithRoute(modelToSubmit.code)){
      notification.warning({
        message: DataConstant.Warning,
        description: MessageConstant.CodeRestricted,
      });
      return;
    }

    const requestURL = id
      ? URLConstant.QRCode.UpdatePdfFileQRCode
      : URLConstant.QRCode.AddPdfFileQRCode;
    formdata.append("model", JSON.stringify(modelToSubmit));
    axiosClient
      .post(requestURL, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.SUCCESS)) {
          notification.success({
            message: DataConstant.Save,
            description: response.message,
          });
          redirectToQRCode();
        } else  if (response && _.toLower(response.status) == _.toLower(DataConstant.API_STATUS.WARNING)) {
          notification.warning({
            message: DataConstant.Warning,
            description: response.message,
          });
        } else {
          notification.error({
            message: DataConstant.error,
            description: response.message,
          });
        }
      });
  };

  const removeFile = () => {
    setImage(null);
    setPdfName(null);
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onFinish)}>
      <div className="px-16">
        <FormLabel className="mt-16 ">Name</FormLabel>
        <span className="text-[#ff0000] text-lg-start"> *</span>
        <TextField
          id="name"
          name="name"
          className="w-full my-8"
          {...register("name", {
            required: "Please enter name",
            maxLength: {
              value: 60,
              message: MessageConstant.EnterMaximum60Char,
            },
          })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        <FormLabel className="mt-16 ">Upload PDF</FormLabel>
        <div>
          <MuiFileInput
            className="w-full my-8"
            value={image}
            onChange={handleChange}
            inputProps={{ accept: "application/pdf" }}
          />
        </div>
        {pdfName ? (
          <div>
            {" "}
            <Button
              endIcon={
                <FuseSvgIcon size={24} color="action">
                  material-outline:delete
                </FuseSvgIcon>
              }
              onClick={() => removeFile()}
            >
              {pdfName}
            </Button>
          </div>
        ) : (
          <>
            <FormLabel className="mt-16 ">Or paste link below</FormLabel>
            <TextField
              id="url"
              name="url"
              maxLength="50"
              className="w-full my-8"
              {...register("url")}
            />
          </>
        )}

        <FormLabel className="mt-16 ">Shorthand URL</FormLabel>
        <span className="text-[#ff0000] text-lg-start"> *</span>
        <div className="flex">
          <TextField
            value={URLConstant.OriginUrl}
            maxLength="50"
            className="w-1/4 my-8"
            disabled
          />
          <TextField
            id="shorthandUrl"
            name="shorthandUrl"
            maxLength="50"
            className="w-3/4 my-8"
            placeholder="URL code"
            {...register("code",{
              required: MessageConstant.EnterCode,
              pattern: {
                value: DataConstant.urlCodeRegex,
                message: DataConstant.urlCodeMessage,
              }
            })}
            error={!!errors.code}
            helperText={
              <span className="absolute"> {errors.code?.message} </span>
            }
            disabled={data != null && data != ""}
          />
        </div>
        <div>
            <label className="url-preview"><span>Preview:</span><br/> {URLConstant.OriginUrl}/{watch("code")}</label>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="my-8 flex"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default PDFPage;
