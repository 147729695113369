import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const ImageCrop = ({
  eventOfUpload,
  callback,
  setShowModal,
  submitButtonRef,
  cropSquare,
}) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();

  const scale = 1;
  const rotate = 0;
  const aspect = !cropSquare ? 1 : 16 / 9;

  function onSelectFile(e) {
    if (e) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }

      let newImageFile = new File([blob], eventOfUpload.name, {
        type: eventOfUpload.type,
      });

      if (blobUrlRef.current) {
        URL.revokeObjectURL("");
      }

      callback({ file: newImageFile, url: URL.createObjectURL(newImageFile) });
    });

    setShowModal(false);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    10,
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    onSelectFile(eventOfUpload);
  }, []);

  return (
    <div className="App">
      <div className="popup-inner">
        <div className="">
          <label>{"Original Image"}:</label>
          {!!imgSrc && (
            <ReactCrop
              className="w-100"
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={!cropSquare}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
        <div className="">
          <label>{"Cropped Image"}:</label>
          {!!completedCrop && (
            <>
              <div>
                <canvas
                  className={`w-100 ${!cropSquare && "rounded-circle"}`}
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <y hidden ref={submitButtonRef} onClick={onDownloadCropClick}>
                  Download Crop
                </y>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
