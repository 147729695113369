import Oidc, { WebStorageStateStore } from "oidc-client";

export function log() {}
const { hostname, port } = window.location;
const clientUrl = `https://${hostname}${port && port > 0 ? `:${port}` : ""}`;
const { origin } = window.location;
const config = {
  authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${origin}/implicit/callback`,
  response_type: "code",
  scope: "openid profile QRCodeApi offline_access",
  post_logout_redirect_uri: origin,
  // silent_redirect_uri: `${clientUrl}/auth/LoginResponse`,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: true,
  audience: `QRCodeApi`,
  // userStore: new WebStorageStateStore({ store:  }),
  // userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
};

const mgr = new Oidc.UserManager(config);

export function login() {
  mgr.signinRedirect();
}

export function* getLoggedInUser() {
  return yield mgr
    .getUser()
    .then(
      (user) => user,
      (err) => err
    )
    .catch((err) => err);
}

export function api() {
  mgr
    .getUser()
    .then((user) =>
      fetch("https://localhost:5001/identity", {
        method: "Get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `bearer ${user.access_token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((err) => {
          console.log(err);
        })
    )
    .catch((error) => console.log(error));
}

export function completeLogin() {
  return new Promise((resolve, reject) => {
    mgr
      .signinRedirectCallback()
      .then(
        (user) => {
          resolve(user);
        },
        (err) => {
          if (
            err &&
            err.toString().includes("No matching state found in storage")
          ) {
            mgr.signinRedirect();
          }
          reject(err);
        }
      )
      .catch((err) => {
        if (
          err &&
          err.toString().includes("No matching state found in storage")
        ) {
          mgr.signinRedirect();
        }
        reject(err);
      });
  });
}

export function logout() {
  mgr.signoutRedirect();
  completeLogout();
}

export function completeLogout() {
  mgr
    .signoutRedirectCallback()
    .then(() => {
      mgr.removeUser();
      localStorage.clear();
    })
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
}

mgr.events.addAccessTokenExpiring(() => {
  mgr
    .signinSilent()
    .then((user) => {
      const userresponse = JSON.parse(localStorage.getItem("webappuser"));
      userresponse.accesstoken = user.access_token;
      localStorage.setItem("webappuser", JSON.stringify(userresponse));
    })
    .catch((err) => {
      console.log("silent renew error addAccessTokenExpiring", err);
    });
});

mgr.events.addAccessTokenExpired(() => {
  mgr
    .signinSilent()
    .then((user) => {
      const userresponse = JSON.parse(localStorage.getItem("webappuser"));
      userresponse.accesstoken = user.access_token;
      localStorage.setItem("webappuser", JSON.stringify(userresponse));
      console.log("silent renew Success addAccessTokenExpired");
    })
    .catch((err) => {
      console.log("silent renew error addAccessTokenExpired", err);
    });
});

mgr.signinSilentCallback().then(() => {});

mgr.events.addUserSignedOut(() => {
  mgr.signoutRedirect();
  completeLogout();
});
