import React, { useEffect } from "react";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { useNavigate, useParams } from "react-router-dom";

  
  const SwitchResponse = () => {
    const { userrole, uid, subid, whiteLabel } = useParams();
    const navigate = useNavigate();

    const userLoginRole = {
    userId : uid,
    userRole : userrole,
    userSubscription : subid,
    company: whiteLabel,
    }

    useEffect(()=>{
        localStorage.setItem("userLoginRole", JSON.stringify(userLoginRole));
        navigate("/");
    },[])

    return (
        <FuseSplashScreen />
    );
  };
  
  export default SwitchResponse;