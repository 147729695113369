import { Alert, Grid, Item } from '@mui/material';
import { Empty } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import DataConstant from 'src/app/constants/dataConstant';

const BoxComponent = (props) => {

  const data = props.data?.filter(x => x.chartName == DataConstant.ChartDetail.Category.CHART_TYPEWISE);
  const title = props.title;

  const styles = [
    {backgroundColor:"#f0f7ff",color:"#305db9", border: "2px solid #b3d2ff", height:"96px"},
    {backgroundColor:"#efffec",color:"#2e7d32", border: "2px solid #bedfc1", height:"96px"},
    {backgroundColor:"#fff1f1",color:"#ab3030", border: "2px solid #eec3c3", height:"96px"},
  ]

  useEffect(() => {

  }, [])

  return (
    <div className='p-10'>
        <div className='mb-16'>
            <p className='font-semibold mb-6'>{title}</p>
        </div>
        {data != null && data.length > 0 ? (
          <Grid container justifyContent="space-around" alignItems="center" className='my-16'>
              {data?.map((item, index) => {
                  const styleIndex = index % styles.length;
                  const style = styles[styleIndex];
                  return (
                      <Grid key={index} item xs={3} className='py-16 m-10 text-center rounded-xl' style={style}>
                          <p className='font-semibold text-3xl'>{item.y1}</p>
                          <p className='text-sm font-semibold p-4'>{item.x1}</p>
                      </Grid>
                  )
              })}
          </Grid>
        ) : (
          <Empty className="cm-web-preview-wrapper"/>
        )
        }
    </div>
  )
};

export default BoxComponent;
