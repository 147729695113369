import PaymentProfile from "./PaymentProfile";
import PaymentSubscription from "./PaymentSubscription";
import UpgradePackage from "./UpgradePackage";
import Checkout from "./CheckOut";
import UserPaymentProfile from "./UserPaymentProfile";
import Invoices from "./Invoices";
import PaymentPage from "./PaymentPage";
const PaymentSubscriptionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "Subscription/paymentSubscription",
      element: <PaymentSubscription />,
    },
    {
      path: "Subscription/userPaymentProfile",
      element: <UserPaymentProfile />,
    },
    {
      path: "paymentProfile",
      element: <PaymentProfile />,
    },
    {
      path: "upgradePackage",
      element: <UpgradePackage />,
    },
    {
      path: "Subscription/Checkout/:id",
      element: <Checkout />,
    },
    {
      path: "Subscription/Invoices",
      element: <Invoices />,
    },
    {
      path: "paymentpage",
      element: <PaymentPage />,
    },
  ],
};

export default PaymentSubscriptionConfig;
