import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";
import User from "./User";
import ManageUser from "./ManageUser";
import React from "react";
import { element } from "prop-types";

i18next.addResourceBundle("en", "companyPage", en);
i18next.addResourceBundle("tr", "companyPage", tr);
i18next.addResourceBundle("ar", "companyPage", ar);

const UserConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "user",
      element: <User />,
    },
    {
      path: "manage/:id/:subid",
      element: <ManageUser />,
    },
    {
      path: "manage",
      element: <ManageUser />,
    },
  ],
};

export default UserConfig;
