import axiosClient from "app/configs/axiosClientConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GridComponent from "src/app/components/gridComponent";
import DataConstant from "src/app/constants/dataConstant";
import URLConstant from "src/app/constants/urlConstant";
import PageHeader from "../../components/pageHeader";
import { Col, Row, Empty } from "antd";
import GoogleMapView from "./GoogleMapView";
import FuseLoading from "@fuse/core/FuseLoading";

const QrCodeScanLog = () => {
  const { id } = useParams();
  const [QrCodeScanLog, setQrCodeScanLog] = useState({
    count: 0,
    data: [],
  });
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(DataConstant.tableRequest))
  );
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const [pageNumber, setPageNumber] = useState(1);
  const [IsLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "Latitude",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "Longitude",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
  ];

  useEffect(() => {
    if (id) {    
      tableRequestParam.QRCodeId = id;
      fetchData(tableRequestParam);
    }
  }, []);

  const fetchData = async (requestObject) => {
    await axiosClient
      .post(`${URLConstant.QRCodeScanLog.GetQRCodeScanLog}`, requestObject)
      .then((response) => {
        setIsLoading(false);
        setQrCodeScanLog({
          data: response.data.list,
          count: response.data.totalCount,
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  if (IsLoading) {
    return <FuseLoading />;
  }
  return (
    <>
      <PageHeader
          title="QR codes locations"
          description="QrPage"
      />
      <Row bordered>
        <Col span={24}>
          {QrCodeScanLog.data.length > 0 ? (
            <GoogleMapView markers={QrCodeScanLog.data}/>
            ):(
              <Empty className="cm-web-preview-wrapper"/>
            )
          }
        </Col>
      </Row>
      {/* <GridComponent
        //key={refreshTable}
        rowKey="id"
        //getGridData={getRequestObj}
          scroll={{ x: "max-content", y: "55vh" }}
        columns={columns}
        //pageNumber={pageNumber}
        tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
        dataSource={QrCodeScanLog.data}
        totalCount={QrCodeScanLog.count}
      /> */}
    </>
  );
};

export default QrCodeScanLog;
