import messageConstant from "src/app/constants/messageConstant";
import GridComponent from "src/app/components/gridComponent";
import { Tooltip, Button, Modal, notification, Tag } from "antd";
import PageHeader from "../../components/pageHeader";
import useDebounce from "src/@fuse/hooks/useDebounce";
import dataConstant from "src/app/constants/dataConstant";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import axiosClient from "../../configs/axiosClientConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import { isEqual } from "lodash";
import { CancelOutlined } from "@mui/icons-material";
import { InvoicePackageInfo } from "app/store/userSlice";
import store from "app/store/index";
import moment from "moment";

const PaymentSubscription = () => {
  const [searchText, setSearchText] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [orderList, setOrderList] = useState({
    count: 0,
    data: [],
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const debouncedSearch = useDebounce(searchText, 1000);

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Order date",
      dataIndex: "orderedDate",
      key: "orderedDate",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render : (data)=>(
        <span>{data ? moment(data).format(dataConstant.dateFormats.YYYYMMDD) : ""}</span>
      ),
    },
    {
      title: "Amount($)",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      align: "right"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render: (status, _) => (
        <>
          <Tag style={{ color: status === 'Cancelled' ? 'red' : 'green', backgroundColor: "white" }}>
          {status}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "code",
      sorter: false,
      width: 200,
      dataType: "StringContains",
      render: (record) => (
        <span>
          <Tooltip placement="bottom" title={dataConstant.Cancel}>
            <Button
              icon={<CancelOutlined />}
              size="small"
              onClick={() => confirmCancelUserSubscription(record?.orderId)}
              disabled ={record.status=='Cancelled'}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const confirmCancelUserSubscription = (Id) => {
    Modal.confirm({
      title: messageConstant.CancelConfirmation,
      content: messageConstant.CancelConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        CancelUserSubscription(Id);
      },
    });
  };

  const CancelUserSubscription = (Id) => {
    axiosClient
      .post(
        `${URLConstant.Subscription.CancelUserSubscription}?Id=${Id}`
      )
      .then((response) => {
        if (response && response.status === dataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: messageConstant.Done,
            description: response.message,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
          store.dispatch(
            InvoicePackageInfo({
              ExpiredDate: "",
              Status: "InActive",
              InvoiceType: "",
              CreatedDate: "",
              PackageStatus: "Expired",
              RemainingDays: -1
            })
          );
        } else {
          notification.error({
            message: messageConstant.error,
            description: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  useEffect(() => {
    if (debouncedSearch != undefined) {
      setTableRequestParam({
        ...tableRequestParam,
        pageNumber: 1,
        SearchText: debouncedSearch,
      });
      setPageNumber(1);
    }
  }, [debouncedSearch]);

  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    if (requestObject && !isEqual(requestObject, tableRequestParam)) {
      setTableRequestParam(requestObject);
      if (
        isEqual(
          requestObject,
          JSON.parse(JSON.stringify(dataConstant.tableRequest))
        )
      ) {
        setRefreshTable(Math.random());
      }
      if (!requestObject.SearchText) {
      }
      setSearchText(requestObject.SearchText);
    } else if (
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(dataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
      if (!requestObject.SearchText) {
      }
    } else {
      setRefreshTable(Math.random());
      fetchData(tableRequestParam);
    }
  };

  const fetchData = (requestObject) => {
    axiosClient
      .post(URLConstant.Orders.GetOrdersList, requestObject)
      .then((response) => {
        setOrderList({
          data: response.data.data.list,
          count: response.data.data.totalCount,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (IsLoading) {
    return <FuseLoading />;
  }
  return (
    <div className="px-16">
      <PageHeader title="Order history" showSearch description="OrderHistoryReport" searchText={setSearchText} />
      <GridComponent
        key={refreshTable}
        rowKey="orderId"
        columns={columns}
        dataSource={orderList.data}
        getGridData={getRequestObj}
        tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
        totalCount={orderList.count}
        scroll={{ x: "max-content", y: "55vh" }}
        SearchText={searchText}
        pageNumber={pageNumber}
        deleteCallbackMethod={confirmCancelUserSubscription}
      />
    </div>
  );
};

export default PaymentSubscription;
