import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import {
  Grid,
  MenuItem,
  Paper,
  TextField,
  Button,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { notification } from "antd";
import URLConstant from "src/app/constants/urlConstant";
import axiosClient from "app/configs/axiosClientConfig";
import DataConstant from "src/app/constants/dataConstant";

const ManageCssTemplates = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [isDefault, setDefault] = useState("");
  const [isActivate, setActivate] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const handleDefault = (event) => {
    setDefault(event.target.value);
  };

  const handleActivate = (event) => {
    setActivate(event.target.value);
  };

  const onSubmit = (data) => {
    const modelToSubmit = {
      Name: data.name,
      Css: data.css,
      Default: data.IsDefault,
      Activated: data.IsActivated,
    };
    if (id) {
      modelToSubmit.Id = id;
    }
    const requestURL = modelToSubmit.Id
      ? URLConstant.CssTemplates.UpdateCssTemplate
      : URLConstant.CssTemplates.CreateCssTemplate;
    axiosClient.post(requestURL, modelToSubmit).then((response) => {
      if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        setTimeout(() => {
          navigate(`/csstemplates`);
        }, 1000);
      } else {
        notification.error({
          message: DataConstant.error,
          description:response?.message,
        });
      }
    });
  };

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
  }, [id]);

  const getUserById = (id) => {
    axiosClient
      .get(`${URLConstant.CssTemplates.GetCssById}/${id}`, true)
      .then((response) => {
        const result = {
          id: response.data.id,
          name: response.data.name,
          css: response.data.css,
          IsActivated: response.data.activated,
          IsDefault: response.data.defaultCss,
        };
        if (result) {
          setDefault(result.IsDefault);
          setActivate(result.IsActivated);
          for (const key in result) {
            setValue(key, result[key]);
          }
        }
      });
  };

  return (
    <FusePageSimple
      content={
        <Grid container className="px-12" spacing={2}>
          <Grid item xs={12}>
            <h1>New Css Template</h1>
            <h2>Social QR Code</h2>
            <Paper>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="px-16">
                  <TextField
                    size="small"
                    type="text"
                    label="Id"
                    name="id"
                    variant="outlined"
                    className="w-1/2 mr-1 mb-24 hidden"
                  />
                  <FormLabel id="name">Name</FormLabel>
                  <TextField
                    id="name"
                    name="name"
                    className="w-full my-8"
                    {...register("name", {
                      required: "Name is Required",
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />

                  <FormLabel id="css">Css</FormLabel>
                  <TextField
                    id="css"
                    className="w-full my-8"
                    {...register("css", {
                      required: "Css is Required",
                    })}
                    error={!!errors.css}
                    helperText={errors.css?.message}
                    name="css"
                  />

                  <FormLabel id="IsDefault">
                    Always select as default template
                  </FormLabel>
                  <TextField
                    size="small"
                    select
                    name="IsDefault"
                    id="IsDefault"
                    className="w-full my-8"
                    {...register("IsDefault")}
                    value={isDefault}
                    onChange={handleDefault}
                    InputLabelProps={id && { shrink: true }}
                  >
                    <MenuItem key={true} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                      No
                    </MenuItem>
                  </TextField>

                  <FormLabel id="IsActivated">Activated</FormLabel>
                  <TextField
                    style={{ marginTop: 10 }}
                    size="small"
                    select
                    name="IsActivated"
                    className="w-full my-8"
                    {...register("IsActivated")}
                    value={isActivate}
                    onChange={handleActivate}
                    InputLabelProps={id && { shrink: true }}
                  >
                    <MenuItem key={true} value={true}>
                      Yes
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                      No
                    </MenuItem>
                  </TextField>
                  <Grid item xs={12}>
                    <div className="flex items-center gap-10 justify-end">
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className="my-8 flex"
                        onClick={() => navigate("/csstemplates")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="my-8 flex"
                      >
                        Submit
                      </Button>
                    </div>
                  </Grid>
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
      }
    />
  );
};

export default ManageCssTemplates;
