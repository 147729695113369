import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Stack } from "immutable";
import CloseIcon from "@mui/icons-material/Close";

const PopupDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  icon: Icon,
  bgColor,
  iconColor,
  hideGotIt = false
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
           
          </Box>
          {title && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              bgcolor={bgColor}
              p={1}
            >
              {Icon && <Icon style={{ color: iconColor, marginRight: 8 }} />}
             
                <Typography
                  variant="h6"
                  align="center"
                  style={{ color: iconColor }}
                >
                  {title}
                </Typography>
           
            </Box>
          </Box>)}
        </DialogTitle>
     
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>

      <DialogActions>
        {onConfirm && (
          <Button onClick={onConfirm} color="primary">
            Confirm
          </Button>
        )}
        {!hideGotIt && (
          <Button onClick={onClose} color="primary">
            Got it
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;
