import {
    Grid, 
    TextField,
    Paper,
    FormLabel,
    Button
  } from "@mui/material";
  import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
  import DeleteIcon from '@mui/icons-material/Delete';
  
  
  const PaymentProfile= () => {
  
  
    
    return (
      <>
        <FusePageSimple
          header={
            <Grid container className="px-12" spacing={2}>
              <Grid
                item
                xs={12}
                className="flex items-center border-b py-10 mb-32"
              >
                <h1 className="mr-16">USER PAYMENT PROFILE</h1>
              </Grid>
            </Grid>
          }
          content={
            <Grid container className="px-12" spacing={2}>
              <Grid item lg={8} xs={12}>
                <form name="userForm" autoComplete="off">
                  <Paper className="py-12">
                    <div className="px-16">
                      <FormLabel>Card Last Four Digits</FormLabel>
                      <TextField disabled id="cardDigit" name="cardDigit" value={1111}/>
                      <FormLabel className="mt-16 ">Customer Name</FormLabel>
                      <TextField
                        disabled
                        id="customerName"
                        name="customerName"
                        type="tel"
                        maxLength="50"
                        className="w-full my-8"
                        value={"ABC"}
                      />                 
                      <p component="pre">    
                      <Button endIcon={<DeleteIcon />}></Button>                   
                        <Button
                          type="submit"
                          size="small"
                          variant="outlined"
                          color="primary"                          
                        >
                          Update Card
                        </Button>
                      </p>
                    </div>
                  </Paper>
                </form>
              </Grid>
            </Grid>
          }
        />
      </>
    );
  }
  
  export default PaymentProfile;
  