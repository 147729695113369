import LoginResponse from './LoginResponse';
import authRoles from '../../auth/authRoles';

const LogInResponseConfig = {
  settings: {
     layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/implicit/callback',
      element: <LoginResponse />,
    },
  ],
};

export default LogInResponseConfig;
