import _ from "@lodash";
import {
  Paper,
  Button,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { notification } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axiosClient from "app/configs/axiosClientConfig";
import URLConstant from "src/app/constants/urlConstant";
import messageConstant from "src/app/constants/messageConstant";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon/FuseSvgIcon";
import DataConstant from "src/app/constants/dataConstant";
import { InvoicePackageInfo } from "app/store/userSlice";
import moment from "moment";
import store from "app/store/index";
import mastercard from "../../assets/img/mastercard.svg";
import visacard from "../../assets/img/visacard.svg";
import amexcard from "../../assets/img/amexcard.svg";
import { HostedForm } from "react-acceptjs";

function ModernSignInPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const loginDetail =
    localStorage.getItem("loginDetails") != null
      ? JSON.parse(localStorage.getItem("loginDetails"))
      : null;

  const [cardList, setCardList] = useState({
    count: 0,
    data: [],
  });
  const [cardSelected, setCardSelected] = useState(true);
  const [getSelectedCardData, setSelectedCardData] = useState({
    UserPaymentProfileId: "",
    UserProfileId: "",
  });

  const authData = {
    apiLoginID: process.env.REACT_APP_PAYMENT_APILOGINID,
    clientKey: process.env.REACT_APP_PAYMENT_CLIENTKEY,
  };

  const billingAddress = {
    show: false,
    required: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosClient
      .post(
        `${URLConstant.UserPaymentProfile.GetUserActivePaymentProfileList}?UserId=${loginDetail?.profile?.userid}`
      )
      .then((response) => {
        setCardList({
          data: response?.data,
          count: response?.data?.length,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CreateUserSubscription = () => {
    const paymentDetails = {
      UserPaymentProfileId: getSelectedCardData?.UserPaymentProfileId,
      FirstName: loginDetail?.profile?.name,
      LastName: "",
    };
    const subscription = {
      SubscriptionItemId: id,
      //IsProduction: false,
      UserProfileId: getSelectedCardData?.UserProfileId,
      UserId: loginDetail?.profile?.userid,
      PaymentDetails: paymentDetails,
    };

    if (cardList?.data?.length > 0 && paymentDetails.UserPaymentProfileId) {
      axiosClient
        .post(URLConstant.Subscription.CreateUserSubscription, subscription)
        .then((response) => {
          if (response.status === DataConstant.API_STATUS.SUCCESS) {
            notification.success({
              message: messageConstant.Done,
              description: messageConstant.PaymentDone,
            });
            getInvoiceActiveList(loginDetail?.profile?.userid);
            //redirectToOrders();
            redirectToInvoices();
          } else {
            notification.error({
              message: DataConstant.error,
              description: response?.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const payNowBtn = document.querySelector(".AcceptUI.btn-card-popup");
      if (payNowBtn) {
        payNowBtn.click();
        showCardDialog();
      }
    }
  };

  const createUserPackage = (data) => {
    const HostedCardDetails = {
      cardNumber: data.encryptedCardData.cardNumber,
      expDate: data.encryptedCardData.expDate,
      token: data.opaqueData.dataValue,
    };
    const PaymentDetails = {
      Token: HostedCardDetails.token,
      CardNumber: HostedCardDetails.cardNumber.replace(/[^\d.]/g, ""),
      FirstName: loginDetail?.profile?.name,
      LastName: "---",
      IsDefault: false,
    };
    const paymentRequest = {
      UserProfileId: "",
      PaymentDetails: PaymentDetails,
      //IsProduction: process.env.NODE_ENV === 'production',
      UserId: loginDetail?.profile?.userid,
      SubscriptionItemId: id,
    };

    axiosClient
      .post(URLConstant.Subscription.CreateUserPackage, paymentRequest)
      .then((response) => {
        if (response && response.status === DataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: messageConstant.Done,
            description: messageConstant.PaymentDone,
          });
          getInvoiceActiveList(loginDetail?.profile?.userid);
          redirectToInvoices();
        } else if (response.data.message == "server.validation.exists") {
          notification.error({
            message: messageConstant.error,
            description: messageConstant.AlreadyExists,
          });
        } else {
          notification.error({
            message: DataConstant.error,
            description: response?.data?.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInvoiceActiveList = async (id) => {
    axiosClient
      .get(`${URLConstant.Subscription.GetInvoiceActiveList}/${id}`)
      .then((response) => {
        if (response && response?.data) {
          store.dispatch(
            InvoicePackageInfo({
              ExpiredDate: moment(response?.data?.expiredDate).format(
                DataConstant.dateFormats.YYYYMMDD
              ),
              Status: response?.data?.status,
              InvoiceType: response?.data?.invoiceType,
              CreatedDate: moment(response?.data?.invoiceDate).format(
                DataConstant.dateFormats.YYYYMMDD
              ),
              PackageStatus: response?.data?.packageStatus,
              RemainingDays: response?.data?.remainingDays,
            })
          );
        } else {
          store.dispatch(
            InvoicePackageInfo({
              ExpiredDate: "",
              Status: "InActive",
              InvoiceType: "",
              CreatedDate: "",
              PackageStatus: "Expired",
              RemainingDays: -1
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectCard = (card) => {
    const tempCardList = cardList?.data;
    const selectedCardList = tempCardList?.filter(
      (x) => x.selectedCard == true
    );
    selectedCardList.forEach((element) => {
      const indexOf = tempCardList?.findIndex((x) => x.id == element?.id);
      tempCardList[indexOf].selectedCard = false;
    });
    const index = tempCardList?.findIndex((x) => x.id == card?.id);
    tempCardList[index].selectedCard = true;
    setCardList({ data: tempCardList });
    setCardSelected(false);
    setSelectedCardData({
      UserPaymentProfileId: card.userPaymentProfileID,
      UserProfileId: card.userProfileID,
    });
  };

  const redirectToUpgradePackage = () => {
    navigate(`/UpgradePackage`);
  };

  const redirectToOrders = () => {
    navigate(`/Subscription/PaymentSubscription`);
  };

  const redirectToInvoices = () => {
    navigate(`/Subscription/Invoices`);
  };

  const redirectToPaymentProfile = () => {
    navigate(`/Subscription/UserPaymentProfile`);
  };

  const showCardDialog = () => {
    const dialogBg = document.querySelector("#AcceptUIBackground");
    const dialogCn = document.querySelector("#AcceptUIContainer");
    if (dialogBg) dialogBg.classList.add("show");
    if (dialogCn) dialogCn.classList.add("show");
  };

  useEffect(() => {
    if (cardList?.data) {
      const filteredItems = cardList?.data.find(
        (item) => item.isDefault == true
      );
      if (filteredItems) {
        selectCard(filteredItems);
      }
    }
  }, [cardList?.data]);

  return (
    <>
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32">
        <Paper className="flex flex-col sm:flex-row w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden mt-12 sm:mt-0">
          <div className="w-full md:w-1/2 py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
            <div>
              <img
                className="max-w-160"
                src={require("../../assets/img/logo.png")}
                alt="logo"
              />
              <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                {state?.packageName}
              </Typography>
              <div className="flex items-baseline mt-2 font-medium">
                <Typography>Description</Typography>
              </div>
              <div className="flex items-baseline whitespace-nowrap my-12">
                <Typography className="mr-8 text-2xl">USD</Typography>
                <Typography className="text-6xl font-semibold leading-tight tracking-tight">
                  {state?.packagePrice}
                </Typography>
              </div>
            </div>
            <div className="flex align-center flex-col md:flex-row gap-16">
              <Button
                variant="contained"
                color="primary"
                className=" w-full md:w-1/2"
                aria-label="Sign in"
                type="submit"
                size="large"
                //disabled={cardSelected}
                onClick={() => CreateUserSubscription()}
              >
                Confirm Payment
              </Button>
              <Button
                variant="contained"
                color="inherit"
                className=" w-full md:w-1/2"
                aria-label="Sign in"
                type="submit"
                size="large"
                onClick={() => redirectToUpgradePackage()}
              >
                Cancel
              </Button>
            </div>
            <div className="flex mt-20">
              <img className="mr-5" src={mastercard} />
              <img className="mr-5" src={visacard} />
              <img className="mr-5" src={amexcard} />
            </div>
          </div>
          <div className="md:w-1/2 px-16 py-32 text-right w-full">
            <Button
              variant="contained"
              color="primary"
              className="mb-16"
              aria-label="Sign in"
              type="submit"
              size="large"
              onClick={() => redirectToPaymentProfile()}
            >
              Pay with new card
            </Button>
            <List dense className="cm-card-wrapper mvh-38">
              {cardList.data.map((value, i) => {
                const labelId = `checkbox-list-secondary-label-${i}`;
                return (
                  <ListItem
                    onClick={() => selectCard(value)}
                    key={i}
                    secondaryAction={
                      <>
                        {value.selectedCard ? (
                          <FuseSvgIcon size={32} color="primary">
                            heroicons-solid:badge-check
                          </FuseSvgIcon>
                        ) : (
                          <FuseSvgIcon size={32} color="action">
                            material-twotone:brightness_1
                          </FuseSvgIcon>
                        )}
                      </>
                    }
                    className={value.selectedCard ? "active" : ""}
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FuseSvgIcon size={32} color="action">
                          heroicons-solid:credit-card
                        </FuseSvgIcon>
                      </ListItemIcon>
                      <ListItemText
                        primary={`**** **** **** ${value.cardLastFourDigits}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Paper>
      </div>

      <HostedForm
        environment={process.env.REACT_APP_PAYMENT_TESTMODE}
        authData={authData}
        buttonText={null}
        onSubmit={createUserPackage}
        billingAddressOptions={billingAddress}
        formButtonText="Submit"
        // buttonText={<><FuseSvgIcon>heroicons-outline:plus</FuseSvgIcon> Add</>}
        formHeaderText="Card Information"
        buttonClassName="cm-btn-hidden btn-sm btn-primary btn-card-popup"
      />
    </>
  );
}

export default ModernSignInPage;
