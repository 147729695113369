import DataConstant from "../constants/dataConstant";
import MessageConstant from "../constants/messageConstant";
import {
  notification} from "antd";

/* To check extension of file.  */
const checkFileExtension = (file, typeFlagList, moduleName) => {
  if (
    typeFlagList.isImage &&
    file.name.toLowerCase().match(DataConstant.imagePattern)
  ) {
    return checkImageValidation(file, moduleName);
  } else if (
    typeFlagList.isPdf &&
    file.name.toLowerCase().match(DataConstant.pdfPattern)
  ) {
    return checkPdfValidation(file, moduleName);
  } else if (
    typeFlagList.isCsv &&
    file.name.toLowerCase().match(DataConstant.csvPattern)
  ) {
    return true;
  } else {
    if (typeFlagList.isImage && typeFlagList.isPdf) {
      notification.error({
        message: moduleName,
        description: MessageConstant.ImagePDFvalidation,
        duration:3
      });
    } else if (typeFlagList.isImage) {
      notification.error({
        message: moduleName,
        description: MessageConstant.ImageValidation,
        duration:3
      });
    } else if (typeFlagList.isPdf) {
      notification.error({
        message: moduleName,
        description: MessageConstant.PdfValidation,
        duration:3
      });
    } else if (typeFlagList.isCsv) {
      notification.error({
        message: moduleName,
        description: MessageConstant.selectCsvValidation.toString(),
        duration:3
      });
    } else {
      notification.error({
        message: moduleName,
        description:  MessageConstant.ImageValidation.toString(),
        duration:3
      });
    }
  }
};

/* To check validation for image file */
const checkImageValidation = (file, moduleName) => {
  if (file.size < parseInt(DataConstant.imageFileSize)) return true;
  else {
    notification.error({
      message: moduleName,
      description:  MessageConstant.ImageValidationSize,
      duration:3
    });
    return false;
  }
};

/* To check validation for pdf file */
const checkPdfValidation = (file, moduleName) => {
  if (file.size < parseInt(DataConstant.pdfFileSize)) return true;
  else {
    notification.error({
      message: moduleName,
      description: MessageConstant.PdfValidationSize,
      duration:3
    });
    return false;
  }
};

export { checkFileExtension };
