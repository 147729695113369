import { useEffect } from 'react';
import { login } from '../../auth/oidcConfig';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';

function Login() {
  useEffect(() => {
    login();
  }, []);

  return(
    <FuseSplashScreen />
  )
}


export default Login;
