import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Snackbar,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import URLConstant from 'src/app/constants/urlConstant';

const ShareDialogContent = ({ userInfo }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const shortUrl = `${URLConstant.OriginUrl}/${userInfo?.code}`;

  const handleCopy = () => {
    navigator?.clipboard?.writeText(shortUrl);
    setCopySuccess(true);
  };

  const handleSnackbarClose = () => {
    setCopySuccess(false);
  };

  return (
    <>
      <Typography fontWeight="bold" variant="h5" gutterBottom>
        Share
      </Typography>
      <ul className="flex gap-10 items-center space-x-4">
        <li>
          <a
            href={
              userInfo?.url && userInfo?.url !== ''
                ? `https://www.facebook.com/sharer/sharer.php?u=${userInfo?.url}`
                : '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="max-w-30"
              src={require('../../assets/img/facebook.png')}
              alt="facebook"
              name="facebook"
            />
          </a>
        </li>
        <li>
          <a
            href={
              userInfo?.url && userInfo?.url !== ''
                ? `https://www.twitter.com/share?url=${userInfo?.url}`
                : '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="max-w-30"
              src={require('../../assets/img/twitter.jpg')}
              alt="twitter"
              name="twitter"
            />
          </a>
        </li>
        <li>
          <a
            href={
              userInfo?.url && userInfo?.url !== ''
                ? `https://api.whatsapp.com/send?text=${userInfo?.url}`
                : '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="max-w-30"
              src={require('../../assets/img/whatsapp.png')}
              alt="whatsapp"
              name="whatsapp"
            />
          </a>
        </li>
        <li>
          <a
            href={
              userInfo?.url && userInfo?.url !== ''
                ? `mailto:?subject=Check%20this%20out&body=${userInfo?.url}`
                : '#'
            }
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img
              className="max-w-30"
              src={require('../../assets/img/mail.png')}
              alt="email"
              name="email"
            />
          </a>
        </li>
      </ul>
      <Box sx={{ width: '100%', maxWidth: 500, margin: 'auto', mt: 5 }}>
        <TextField
          className="min-w-256-input"
          label="Short URL"
          value={shortUrl}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton onClick={handleCopy} color="primary">
                <ContentCopyIcon />
              </IconButton>
            ),
          }}
          fullWidth
        />
        <Snackbar
          className="snackbar-shorturl"
          message="Text copied to the clipboard!"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          open={copySuccess}
        />
      </Box>
    </>
  );
};

export default ShareDialogContent;
