import { React, useEffect, useState, useRef } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import { useForm } from "react-hook-form";
import axiosClient from "app/configs/axiosClientConfig";
import URLConstant from "src/app/constants/urlConstant";
import moment from "moment-timezone";
import store from "app/store/index";
import { companyProfileData } from "src/app/store/userSlice";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import PageHeader from "../../components/pageHeader";
import { notification } from "antd";
import DataConstant from "src/app/constants/dataConstant";
import MessageConstant from "src/app/constants/messageConstant";

const companyProfile = () => {
  const [companyDetails, setCompanyDetails] = useState({
    domain: "",
    plan: "",
    timeZoneInfo: "America/Phoenix",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState({
    IsImageChange: true,
    ImagePath: URLConstant.Images.UserDefaultImagePath,
    file: "",
  });
  const timezoneNames = moment.tz.names();
  const hiddenFileInput = useRef(null);
  const { register } = useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (requestObject) => {
    axiosClient
      .get(URLConstant.CompanyProfile.getplandetail, requestObject)
      .then((response) => {
        var data = JSON.parse(response.data.jsonString);
        setCompanyDetails({
          domain: data.WhiteLabel || "",
          plan: data.ProductName || "",
          timeZoneInfo: response.data?.user?.timeZoneInfo,
        });
        if (
          response.data?.companyLogo &&
          response.data?.companyLogo != ""
        ) {
          setImage({
            IsImageChange: true,
            ImagePath:
              URLConstant.Images.UserCompanyLogoPath +
              response.data?.user?.subscriptionId +
              "/" +
              response.data?.companyLogo,
            file: "",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];

      if (selectedFile?.size > DataConstant.imageFileSize) {
        // Display an error message or prevent further processing
        notification.warning({
          message: DataConstant.Warning,
          description: MessageConstant.ImageValidationSize,
        });
        return;
      }
      if (selectedFile?.name.toLowerCase().match(DataConstant.imagePattern)) {
        setImage({
          IsImageChange: false,
          ImagePath: URL.createObjectURL(selectedFile),
          file: selectedFile,
        });
      } else {
        notification.warning({
          message: DataConstant.Warning,
          description: MessageConstant.ImageValidationType,
        });
      }
    }
  };

  const handleUploadButtonClick = () => {
    const formdata = new FormData();
    formdata.append("file", image?.file);
    axiosClient
      .post(URLConstant.CompanyProfile.ChangeLogo, formdata, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        notification.success({
          message: DataConstant.Save,
          description: response?.message,
        });
        store.dispatch(
          companyProfileData({
            CompanyProfile: response?.data,
          })
        );
        setImage({ ...image, IsImageChange: true });
      })
      .catch((error) => console.log("error", error));
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const changeTimeZoneInfo = (e) => {
    axiosClient
      .post(
        `${URLConstant.CompanyProfile.ChangeCompanyTineZone
        }/${encodeURIComponent(e.target.value)}`
      )
      .then((result) => {
        setCompanyDetails((Pre) => {
          return {
            ...Pre,
            timeZoneInfo: e.target.value,
          };
        });
      })
      .catch((error) => console.log("error", error));
  };
  if (isLoading) {
    return <FuseLoading />;
  }
  return (
    <>
      <FusePageSimple
        header={
          <PageHeader
            title="Company profile"
            showSearch={false}
            description="Setting"
          />
        }
        content={
          <Grid container className="px-12" alignItems="stretch" spacing={2}>
            <Grid item sm={3} xs={12}>
              <form name="userForm" autoComplete="off">
                <Paper className="p-24">
                  <div
                    onClick={handleClick}
                    className="cm-upload-preview-wrapper shadow-md"
                  >
                    {image?.ImagePath ? (
                      <img
                        name="picture"
                        src={image.ImagePath}
                        alt="upload image"
                        className="cm-upload-preview"
                        {...register("image")}
                      />
                    ) : (
                      <img
                        src={URLConstant.Images.UserDefaultImagePath}
                        alt="upload image"
                        className="cm-upload-preview"
                      />
                    )}
                    <input
                      type="file"
                      id="upload-button"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                      ref={hiddenFileInput}
                    />
                    <Typography className="cm-choose-file-wrapper">
                      Choose File
                    </Typography>
                  </div>
                  <div className="cm-u-p-overlay">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={image.IsImageChange}
                      onClick={handleUploadButtonClick}
                      className="image-upload-button"
                    >
                      Update image
                    </Button>
                  </div>
                </Paper>
              </form>
            </Grid>
            <Grid item sm={9} xs={12} order={{ xs: 1, sm: 0 }}>
              <Paper className="p-24">
                <form name="userForm" autoComplete="off">
                  <FormLabel className="mt-16 ">Domain</FormLabel>
                  <TextField
                    disabled
                    id="domain"
                    name="domain"
                    className="w-full my-8"
                    value={companyDetails.domain}
                    {...register("domain")}
                  />
                  <FormLabel className="mt-16 ">Plan</FormLabel>
                  <TextField
                    disabled
                    id="plan"
                    name="plan"
                    type="text"
                    maxLength="50"
                    className="w-full my-8"
                    value={companyDetails.plan}
                    {...register("plan")}
                  />
                  {/* <FormLabel className="mt-16 ">
                    What is your timezone?
                  </FormLabel>
                  <Select
                    maxLength="50"
                    className="w-full my-8"
                    labelId="timezone"
                    id="timezone"
                    defaultValue={companyDetails.timeZoneInfo}
                    value={companyDetails.timeZoneInfo}
                    onChange={changeTimeZoneInfo}
                  >
                    {timezoneNames && timezoneNames?.map((timezoneName) => (
                      <MenuItem key={timezoneName} value={timezoneName}>
                        {timezoneName}
                      </MenuItem>
                    ))}
                  </Select> */}
                </form>
              </Paper>
            </Grid>
            
          </Grid>
        }
      />
    </>
  );
};

export default companyProfile;
