import { useState } from "react";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Switch,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import axiosClient from "app/configs/axiosClientConfig";
import URLConstant from "src/app/constants/urlConstant";

const DownloadModel = (props) => {
  const { open, id, closeModel, name } = props;
  const [download, setDownload] = useState({
    ECCLevel: "L",
    QRCodePadding: "5",
    QRCodeSize: "10",
    IsUnderNameQRCode: false,
    Type: "",
  });

  const SelectDownLoadType = (downloadData) => {
    if (id) {
      axiosClient
        .get(
          `${URLConstant.QRCode.DownloadURLQRCode}/${id}/${downloadData.ECCLevel}/${downloadData.Type}/${downloadData.QRCodeSize}/${downloadData.IsUnderNameQRCode}/${downloadData.QRCodePadding}`,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res) {
            let filename = "";
            let qrname = "";
            if(name){
              qrname = name.replace(/[^a-zA-Z0-9]/g, '');
            }

            if(qrname){
              if (downloadData.Type === "WebPNG") filename = qrname +".png";
              if (downloadData.Type === "EPSVector") filename = qrname +".eps";
              if (downloadData.Type === "PDFVector") filename = qrname +".pdf";
              if (downloadData.Type === "SVGVector") filename = qrname+".svg";
            }
            else{
              if (downloadData.Type === "WebPNG") filename = "file.png";
              if (downloadData.Type === "EPSVector") filename = "file.eps";
              if (downloadData.Type === "PDFVector") filename = "file.pdf";
              if (downloadData.Type === "SVGVector") filename = "file.svg";
            }
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleDownload = (type) => {
    // Handle download logic based on type
    download.Type = type;
    SelectDownLoadType(download);
  };

  const handleCloseModal = () => {
    if (download.IsUnderNameQRCode) {
      setDownload({
        ...download,
        IsUnderNameQRCode: false
      })
    }
    closeModel();
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Dialog open={open} onClose={handleCloseModal} maxWidth="md">
            <DialogTitle className="flex items-center justify-between p-24 mb-24 border-solid border-b-2">
              <Typography variant="h6">Download QR code</Typography>
              <IconButton aria-label="close" onClick={handleCloseModal}>
                <CancelOutlinedIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="p-24">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <FormLabel>Error correction level</FormLabel>
                  <FormControl fullWidth className="mt-8">
                    <Select
                      variant="outlined"
                      labelId="ecc-level-label"
                      id="ecc-level"
                      value={download.ECCLevel}
                      onChange={(e) =>
                        setDownload({ ...download, ECCLevel: e.target.value })
                      }
                    >
                      <MenuItem value="L">L - 7%</MenuItem>
                      <MenuItem value="M">M - 15%</MenuItem>
                      <MenuItem value="Q">Q - 25%</MenuItem>
                      <MenuItem value="H">H - 30%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormLabel>QR code padding</FormLabel>
                  <TextField
                    className="mt-8"
                    value={download.QRCodePadding}
                    onChange={(e) =>
                      setDownload({
                        ...download,
                        QRCodePadding: e.target.value,
                      })
                    }
                    type="number"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormLabel>QR code size</FormLabel>
                  <TextField
                    className="mt-8"
                    value={download.QRCodeSize}
                    onChange={(e) =>
                      setDownload({ ...download, QRCodeSize: e.target.value })
                    }
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormLabel>Add name under QR code</FormLabel>
                  <FormControl fullWidth className="mt-8">
                    <Switch
                      checked={download.IsUnderNameQRCode}
                      onChange={(e) =>
                        setDownload({
                          ...download,
                          IsUnderNameQRCode: e.target.checked,
                        })
                      }
                      color="primary"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: 2 }}>
                <Grid item xs={3}>
                  <div className="text-center cursor-pointer">
                    <img
                      onClick={() => handleDownload("WebPNG")}
                      className="max-w-52 mx-auto"
                      src={require("../../assets/img/png.png")}
                      alt="png"
                    />
                    <FormLabel>PNG</FormLabel>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className="text-center cursor-pointer"
                    onClick={() => handleDownload("EPSVector")}
                  >
                    <img
                      className="max-w-52 mx-auto"
                      src={require("../../assets/img/eps.png")}
                      alt="eps"
                    />
                    <FormLabel>EPS</FormLabel>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className="text-center cursor-pointer"
                    onClick={() => handleDownload("PDFVector")}
                  >
                    <img
                      className="max-w-52 mx-auto"
                      src={require("../../assets/img/pdf.png")}
                      alt="pdf"
                    />
                    <FormLabel>PDF</FormLabel>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className="text-center cursor-pointer"
                    onClick={() => handleDownload("SVGVector")}
                  >
                    <img
                      className="max-w-52 mx-auto"
                      src={require("../../assets/img/svg.png")}
                      alt="svg"
                    />
                    <FormLabel>SVG</FormLabel>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="p-24 border-solid border-t-2">
              <Button variant="outlined" color="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};
export default DownloadModel;
