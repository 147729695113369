import GridComponent from "src/app/components/gridComponent";
import { Tag, Tooltip, Button, Modal, notification } from "antd";
import PageHeader from "../../components/pageHeader";
import useDebounce from "src/@fuse/hooks/useDebounce";
import dataConstant from "src/app/constants/dataConstant";
import { useState, useEffect } from "react";
import URLConstant from "src/app/constants/urlConstant";
import axiosClient from "../../configs/axiosClientConfig";
import FuseLoading from "@fuse/core/FuseLoading";
import { isEqual } from "lodash";
import moment from "moment";
import store from "app/store/index";
import { InvoicePackageInfo } from "app/store/userSlice";
import { CancelOutlined } from "@mui/icons-material";
import messageConstant from "src/app/constants/messageConstant";
import DataConstant from "src/app/constants/dataConstant";

const Invoices = () => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tableRequestParam, setTableRequestParam] = useState(
    JSON.parse(JSON.stringify(dataConstant.tableRequest))
  );
  const [orderList, setOrderList] = useState({
    count: 0,
    data: [],
  });
  const [activeOrder, setActiveOrder] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [refreshTable, setRefreshTable] = useState(Math.random());
  const debouncedSearch = useDebounce(searchText, 1000);

  const columns = [
    {
      title: "Invoice#",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render : (data)=>(
        <span>{data == DataConstant.packageType.Year ? "Yearly" : data}</span>
      ),
    },
    // {
    //   title: "Username",
    //   dataIndex: "userName",
    //   key: "userName",
    //   sorter: true,
    //   width: 200,
    //   dataType: "StringContains",
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Invoice date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render : (data)=>(
        <span>{data ? moment(data).format(dataConstant.dateFormats.YYYYMMDD) : ""}</span>
      ),
    },
    {
      title: "Expired date",
      dataIndex: "expiredDate",
      key: "expiredDate",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render : (data)=>(
        <span>{data ? moment(data).format(dataConstant.dateFormats.YYYYMMDD) : ""}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      width: 200,
      dataType: "StringContains",
      render: (status, _) => (
        <>
          <Tag style={{ color: status === 'Inactive' ? 'red' : 'green', backgroundColor: "white" }}>
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "Amount($)",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: 200,
      dataType: "StringContains",
    },
    {
        title: "Total($)",
        dataIndex: "total",
        key: "total",
        sorter: true,
        width: 200,
        dataType: "StringContains",
    },
    {
      title: "Card Last Four Number",
      dataIndex: "cardLastFourDigits",
      key: "cardLastFourDigits",
      sorter: false,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Payment Transaction#",
      dataIndex: "authNetTransactionId",
      key: "authNetTransactionId",
      sorter: false,
      width: 200,
      dataType: "StringContains",
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "action",
      sorter: false,
      width: 200,
      dataType: "StringContains",
      render: (status) => (
        <span>
          <Tooltip placement="bottom" title={dataConstant.Cancel}>
            <Button
              icon={<CancelOutlined />}
              size="small"
              onClick={() => confirmCancelUserSubscription(activeOrder)}
              disabled ={status == 'Inactive' || !activeOrder}
            />
          </Tooltip>
        </span>
      ),
    },
  ];

  const confirmCancelUserSubscription = (Id) => {
    Modal.confirm({
      title: messageConstant.CancelConfirmation,
      content: messageConstant.CancelConfirm,
      okText: messageConstant.okText,
      cancelText: messageConstant.cancleText,
      onOk: () => {
        CancelUserSubscription(Id);
      },
    });
  };

  const CancelUserSubscription = (Id) => {
    axiosClient
      .post(
        `${URLConstant.Subscription.CancelUserSubscription}?Id=${Id}`
      )
      .then((response) => {
        if (response && response.status === dataConstant.API_STATUS.SUCCESS) {
          notification.success({
            message: messageConstant.Done,
            description: response.message,
          });
          fetchData(JSON.parse(JSON.stringify(dataConstant.tableRequest)));
          // store.dispatch(
          //   InvoicePackageInfo({
          //     ExpiredDate: "",
          //     Status: "InActive",
          //     InvoiceType: "",
          //     CreatedDate: ""
          //   })
          // );
        } else {
          notification.error({
            message: messageConstant.error,
            description: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData(tableRequestParam);
  }, [JSON.stringify(tableRequestParam)]);

  useEffect(() => {
    if (debouncedSearch != undefined) {
      setTableRequestParam({
        ...tableRequestParam,
        pageNumber: 1,
        SearchText: debouncedSearch,
      });
      setPageNumber(1);
    }
  }, [debouncedSearch]);

  const Refresh = () => {
    setSearchText("");
  };

  const getRequestObj = (requestObject, pagenumber = 1) => {
    setPageNumber(pagenumber);
    if (requestObject && !isEqual(requestObject, tableRequestParam)) {
      setTableRequestParam(requestObject);
      if (
        isEqual(
          requestObject,
          JSON.parse(JSON.stringify(dataConstant.tableRequest))
        )
      ) {
        setRefreshTable(Math.random());
      }
      if (!requestObject.SearchText) {
      }
      setSearchText(requestObject.SearchText);
    } else if (
      requestObject &&
      isEqual(
        requestObject,
        JSON.parse(JSON.stringify(dataConstant.tableRequest))
      )
    ) {
      setRefreshTable(Math.random());
      fetchData(requestObject);
      if (!requestObject.SearchText) {
      }
    } else {
      setRefreshTable(Math.random());
      fetchData(tableRequestParam);
    }
  };

  const fetchData = (requestObject) => {
    axiosClient
      .post(URLConstant.Subscription.GetInvoiceList, requestObject)
      .then((response) => {
        setOrderList({
          data: response.data.list,
          count: response.data.totalCount,
        });
        setActiveOrder(response.data.orderId);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return <FuseLoading />;
  }
  return (
    <div className="px-16">
      <PageHeader title="Invoice report" showSearch description="InvoiceReport" searchText={setSearchText} />

      <GridComponent
        key={refreshTable}
        rowKey="invoiceId"
        columns={columns}
        dataSource={orderList.data}
        getGridData={getRequestObj}
        tableRequest={JSON.parse(JSON.stringify(tableRequestParam))}
        totalCount={orderList.count}
        scroll={{ x: "max-content", y: "40vh" }}
        refresh={Refresh}
        SearchText={searchText}
        pageNumber={pageNumber}
      />
    </div>
  );
};

export default Invoices;
